import { SxProps, Theme, Typography } from "@mui/material";

interface ISignupTextLableTypography {
    text: string;
    required?: boolean;
    sx?: SxProps<Theme>;
}

export const SignupTextLableTypography = (props: ISignupTextLableTypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        minHeight:"22px",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "22px",
        letterSpacing:"0.1px",
      }}
    >
      {props.text} {!!props.required && (<span style={{color: "#F2460D"}}>*</span>)}
    </Typography>
  );
}