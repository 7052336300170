import { Button } from "@mui/material";

interface ISignUpContainedButton {
  text: string;
  handleClick: () => void;
  disabled?: boolean;
}

export const SignUpContainedButton = (props: ISignUpContainedButton) => {
  return (
    <Button
      disabled={props.disabled}
      size="large"
      variant="contained"
      sx={{
        background: "#1F5DA5",
        borderRadius: "8px",
        height: "40px",
        width: "100%",
        textTransform: "none",
        color: "#FFFFFF",
        boxShadow:"none !important",
        "&:hover": {
          background: "#1F5DA5",
          color: "#FFFFFF",
        },
      }}
      onClick={props.handleClick}
    >
      {props.text}
    </Button>
  );
};
