import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Decryption,
  emptyDepartmentDetail,
  EmptyGuid,
  EmptyLocationDetail,
  Encryption,
} from "../../../libs/common-lib";
import {
  DepartmentContactDetail,
  DepartmentDetail,
  LocationDetail,
} from "../../../libs/model-lib";
import { DetailSettingBox } from "../../organism";
import { DepartmentAddressForm } from "../../organism/location/departmentAddressForm";
import { DepartmentContactInfoForm } from "../../organism/location/departmentContactInfoForm";
import { DepartmentStaffingForm } from "../../organism/location/departmentStaffingForm";
import {
  createDepartment,
  getAllDepartmentsByOrgId,
  getDepartmentById,
  getLocationById,
  getSubDepartmentByOrgId,
  updateDepartment,
} from "../../../libs/services-lib";
import {
  currentActiveScreenState,
  departmentClearedState,
  departmentDetailState,
  loadingIconState,
  organizationDetailState,
  updateAlertState,
} from "../../../libs/state-management-lib";
import Joi from "joi";
import { DepartmentDetailContext } from "../../../libs/context-lib/departmentDetailContext";

const DepartmentDetailTemplate = () => {
  const [errorMessage, setErrorMessage] = useState<any[]>([]);
  const { id } = useParams();
  const [departmentDetail, setDepartmentDetail] = useState<DepartmentDetail>(
    emptyDepartmentDetail
  );
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      if (!!id) {
        if (Decryption(id) == EmptyGuid || !!!Decryption(id)) {
          setDepartmentDetail(emptyDepartmentDetail);
        } else {
          loadingIconState.setState(true);
          let departDetail = await getDepartmentById(Decryption(id));
          setDepartmentDetail(departDetail);
          loadingIconState.setState(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (!!errorMessage && errorMessage.length > 0) {
      let selectedPara = document.querySelectorAll("p.red-text");
      if (!!selectedPara && selectedPara.length > 0 && !!selectedPara[0]) {
        selectedPara[0].closest("div")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [errorMessage]);

  const departmentValidationSchema = Joi.object<DepartmentDetail>({
    id: Joi.string().allow(""),
    name: Joi.string().required().max(255).messages({
      "string.empty": `Department name cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    address_1: Joi.string().required().max(255).messages({
      "string.empty": `Address 1 cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    address_2: Joi.string().allow("").max(255).messages({
      "string.max": `The maximum character limit should be 255`,
    }),
    city: Joi.string().required().max(255).messages({
      "string.empty": `City cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    state: Joi.string().required().max(255).messages({
      "string.empty": `State cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    zip_code: Joi.string().min(5).max(5).required().messages({
      "string.empty": `Postal code cannot be an empty field`,
      "string.min": `Postal code is invalid`,
      "string.max": `Postal code is invalid`,
    }),
    number_of_beds: Joi.number().allow(0, "").optional(),
    department_location: Joi.string().allow("").max(255).messages({
      "string.max": `The maximum character limit should be 255`,
    }),
    location_Id: Joi.string().required().messages({
      "string.empty": `Location cannot be an empty field`,
    }),
    description: Joi.string().allow("").max(255).messages({
      "string.max": `The maximum character limit should be 255`,
    }),
    department_type: Joi.number().positive().greater(0).required().messages({
      "number.positive": `Department type cannot be an empty field`,
      "any.required": `Department type cannot be an empty field`,
    }),
    org_role_ids: Joi.array().empty(Joi.array().length(0)),
    is_active: Joi.boolean(),
    staff_type_ids: Joi.array().empty(Joi.array().length(0)),
    departmentContactDetails: Joi.object<DepartmentContactDetail>({
      id: Joi.string().allow(""),
      name: Joi.string().allow("").max(255).messages({
        "string.max": `The maximum character limit should be 255`,
      }),
      title: Joi.string().allow("").max(255).messages({
        "string.max": `The maximum character limit should be 255`,
      }),
      phone_number: Joi.string()
        .allow("")
        .pattern(/^\+1 \(\d{3}\) \d{3}-\d{4}$/)
        .max(17)
        .messages({
          "string.pattern.base": "Enter valid phone number",
        }),
      department_id: Joi.string().allow(""),
      email: Joi.string().allow("").max(255).email({ tlds: false }).messages({
        "string.email": `Email is not valid`,
        "string.max": `The maximum character limit should be 255`,
      }),
    }),
  }).options({ abortEarly: false });

  const handleSaveDepartmentClick = async () => {
    let departDetailValidate = { ...departmentDetail };

    if (
      departDetailValidate.departmentContactDetails.phone_number == "+" ||
      departDetailValidate.departmentContactDetails.phone_number == "+1"
    ) {
      departDetailValidate.departmentContactDetails.phone_number = "";
    }

    const result = departmentValidationSchema.validate(departmentDetail);

    let errorMessage: any[] = [];
    if (!!result.error && !!result.error.details) {
      result.error.details.forEach((cc) => {
        let field = cc.path[0];
        if (cc.path.length > 1) {
          field = cc.path.join("$");
        }
        errorMessage.push({ field: field, errorMessage: cc.message });
      });

      setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage([]);
    loadingIconState.setState(true);
    let departDetail = { ...departmentDetail };

    departDetail.number_of_beds = !!departDetail.number_of_beds
      ? departDetail.number_of_beds
      : 0;

    if (!!departDetail.id && departDetail.id != EmptyGuid) {
      try {
        await updateDepartment(departDetail);
      } catch (error) {}
    } else {
      departDetail.id = EmptyGuid;
      departDetail.departmentContactDetails.id = EmptyGuid;
      departDetail.departmentContactDetails.department_id = EmptyGuid;
      try {
        await createDepartment(departDetail);
        setDepartmentDetail(emptyDepartmentDetail);
        departmentClearedState.setState(!departmentClearedState.getState());
      } catch (error) {}
    }

    getAllDepartmentsByOrgId(organizationDetailState.getState().id);
    getSubDepartmentByOrgId(organizationDetailState.getState().id);
    loadingIconState.setState(false);
  };

  const handleDepartmentCancelClick = () => {
    navigate(`/settings/locations-departments/${Encryption("1")}`);
  };

  return (
    <DetailSettingBox
      navigationSelectedMenu="Location"
      playBoxTitle="Setting up locations and departments"
      playBoxDuration="1 min."
      pageTitle={
        !!id && (Decryption(id) == EmptyGuid || !!!Decryption(id))
          ? "Add department"
          : "Update department"
      }
      handleSaveClick={handleSaveDepartmentClick}
      handleCancelClick={handleDepartmentCancelClick}
    >
      <DepartmentDetailContext.Provider
        value={{ departmentDetail, setDepartmentDetail }}
      >
        <DepartmentAddressForm errorMessage={errorMessage} />
        <DepartmentStaffingForm errorMessage={errorMessage} />
        <DepartmentContactInfoForm errorMessage={errorMessage} />
      </DepartmentDetailContext.Provider>
    </DetailSettingBox>
  );
};

export default DepartmentDetailTemplate;
