import axios from "axios";
import { accessTokenState } from "../state-management-lib";
import { resetAllState } from "../state-management-lib/resetState";

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const token = accessTokenState.getState().token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // If the response is successful, simply return it
    return response;
  },
  (error) => {
    // Check if the error response status is 401
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized! Redirecting to login.');
      localStorage.clear();
       window.location.href = '/';
    }
    // Return a rejected promise to allow further error handling
    return Promise.reject(error);
  }
);