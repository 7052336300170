import { Box } from "@mui/material";

export const LogoBox = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: "4px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src="/images/common/logo.png"
        style={{ width: "77px", height: "20px" }}
      />
    </Box>
  );
};
