import { useEffect } from "react";
import { currentActiveScreenState } from "../../libs/state-management-lib";
import { LayoutTemplate } from "../template";
import { MarketPlaceTemplate } from "../template/marketPlaceTemplate";
import { SettingsTemplate } from "../template/settingsTemplate";

export const MarketPlace = () => {
  useEffect(() => {
    currentActiveScreenState.setState("/marketplace");
  }, []);
  
  return (
    <>
      <MarketPlaceTemplate />
    </>
  );
};
