import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useLayoutEffect, useState } from "react";
import { FacilitySignUpForms } from "../organism/signUp/facilitySignUpForms";
import { OverFlowBox } from "../atom";
import { loadingIconState } from "../../libs/state-management-lib";

export const FacilitySignUpTemplate = () => {
  const [openScreen, setOpenScreen] = useState("signUp");
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);

  useLayoutEffect(() => {
    const loadIconStateSub = loadingIconState.subscribe(async (state) => {
      setShowLoadingIcon(state);
    });

    return () => {
      loadIconStateSub();
    };
  }, []);

  return (
    <>
      {showLoadingIcon && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingIconState.getState()}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box className="signup-main-container-col-holder">
        <Box className="signup-main-container-col-1">
          <OverFlowBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
              height: "calc(100% - 10px)",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <Box className="signup-main-container">
              {/* header container logo */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="/images/common/logo.png"
                  style={{ width: "160px", height: "40px" }}
                />
              </Box>
              {/* Body container */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FacilitySignUpForms />
              </Box>
              {/* FooterContainer */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.17px",
                    color: "#00000099",
                  }}
                >
                  By using Shiftnex you agree to the{" "}
                  <span
                    style={{
                      fontWeight: "500",
                      color: "#1F5DA5",
                      lineHeight: "22px",
                      letterSpacing: "0.1px",
                      cursor: "pointer",
                    }}
                  >
                    Terms of service
                  </span>{" "}
                  and{" "}
                  <span
                    style={{
                      fontWeight: "500",
                      color: "#1F5DA5",
                      lineHeight: "22px",
                      letterSpacing: "0.1px",
                      cursor: "pointer",
                    }}
                  >
                    Privacy policy
                  </span>
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.17px",
                    color: "#00000099",
                  }}
                >
                  Version 1.0.0
                </Typography>
              </Box>
            </Box>
          </OverFlowBox>
        </Box>
        <Box className="signup-main-container-col-2">
          <Box className="signup-main-container-col-2-bg-img"></Box>
        </Box>
      </Box>
    </>
  );
};
