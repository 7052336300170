import { useEffect } from "react";
import StaffDetailTemplate from "../../template/settings/staffDetailTemplate";
import { currentActiveScreenState } from "../../../libs/state-management-lib";

const StaffDetail = () => {
  useEffect(() => {
    if (window.location.pathname.includes("/settings")) {
      currentActiveScreenState.setState("/settings/organization");
    } else {
      currentActiveScreenState.setState("/talents");
    }
  }, []);

  return <StaffDetailTemplate />;
};

export default StaffDetail;
