import {
  Box,
  Chip,
  duration,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import {
  CalendarTodayOutlinedIcon,
  LableTextFieldBox,
  OutlinedButton,
  PlatformTextField,
} from "../../atom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { TextFieldLableTypography } from "../../atom/typography/textFieldLableTypography";
import { CalendarDropDown } from "../../atom/dropdown/calendarDropdown";
import { grey } from "@mui/material/colors";
import {
  DesktopTimePicker,
  TimeValidationError,
} from "@mui/x-date-pickers-pro";
import { useShiftDetailContext } from "../../../libs/context-lib/shiftDetailContext";
import { differenceInMinutes, format } from "date-fns";
import { convertMinutestoHour, getErrorDetail } from "../../../libs/common-lib";

interface ICalendarSingleShiftDateForm {
  shiftDate: Dayjs | null;
  shiftStartTime: Dayjs | null;
  shiftEndTime: Dayjs | null;
  errorMessage: any[];
}

export const CalendarSingleShiftDateForm = (
  props: ICalendarSingleShiftDateForm
) => {
  const [shiftDate, setShiftDate] = useState<Dayjs | null>(props.shiftDate);
  const [value, setValue] = useState(0);
  const [shiftStartTime, setShiftStartTime] = useState<Dayjs | null>(
    !!props.shiftStartTime ? props.shiftStartTime : null
  );
  const [shiftEndTime, setShiftEndTime] = useState<Dayjs | null>(
    !!props.shiftEndTime ? props.shiftEndTime : null
  );
  const [shiftStartTimeError, setShiftStartTimeError] =
    useState<TimeValidationError | null>(null);
  const [shiftEndTimeError, setShiftEndTimeError] =
    useState<TimeValidationError | null>(null);

  const { shiftDetail, setShiftDetail } = useShiftDetailContext();
  const [shiftDuration, setShiftDuration] = useState<string>("");

  const handleClickTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    shiftDetail.start_date_string = "";
    shiftDetail.end_date_string = "";
    if (
      !!shiftDate &&
      !!shiftStartTime &&
      !!shiftEndTime &&
      !!!shiftStartTimeError &&
      !!!shiftEndTimeError
    ) {
      if (
        !!shiftStartTime &&
        !!shiftEndTime &&
        shiftStartTime.hour() > shiftEndTime.hour()
      ) {
        let singleShiftDate = new Date(shiftDate.toDate());
        singleShiftDate.setHours(0, 0, 0, 0);
        let singleShiftDateJs = dayjs(singleShiftDate);

        let shiftStartDate = singleShiftDateJs
          .add(shiftStartTime.hour(), "hour")
          .add(shiftStartTime.minute(), "minute")
          .toDate();

        let shiftEndDate = singleShiftDateJs
          .add(1, "day")
          .add(shiftEndTime.hour(), "hour")
          .add(shiftEndTime.minute(), "minute")
          .toDate();

        let durationInMinutes = differenceInMinutes(
          shiftEndDate,
          shiftStartDate
        );

        setShiftDetail({
          ...shiftDetail,
          start_date: shiftStartDate,
          end_date: shiftEndDate,
          start_date_string: format(
            shiftDetail.start_date,
            "yyyy-MM-dd HH:mm:SS"
          ),
          end_date_string: format(shiftDetail.end_date, "yyyy-MM-dd HH:mm:SS"),
        });

        setShiftDuration(convertMinutestoHour(durationInMinutes));
      } else {
        let singleShiftDate = new Date(shiftDate.toDate());
        singleShiftDate.setHours(0, 0, 0, 0);
        let singleShiftDateJs = dayjs(singleShiftDate);

        let shiftStartDate = !!shiftStartTime
          ? singleShiftDateJs
              .add(shiftStartTime.hour(), "hour")
              .add(shiftStartTime.minute(), "minute")
              .toDate()
          : singleShiftDateJs.add(14, "hour").add(0, "minute").toDate();

        let shiftEndDate = !!shiftEndTime
          ? singleShiftDateJs
              .add(shiftEndTime.hour(), "hour")
              .add(shiftEndTime.minute(), "minute")
              .toDate()
          : singleShiftDateJs.add(22, "hour").add(0, "minute").toDate();

        let durationInMinutes = differenceInMinutes(
          shiftEndDate,
          shiftStartDate
        );

        setShiftDetail({
          ...shiftDetail,
          start_date: shiftStartDate,
          end_date: shiftEndDate,
          start_date_string: format(
            shiftDetail.start_date,
            "yyyy-MM-dd HH:mm:SS"
          ),
          end_date_string: format(shiftDetail.end_date, "yyyy-MM-dd HH:mm:SS"),
        });

        setShiftDuration(convertMinutestoHour(durationInMinutes));
      }
    }
  }, [shiftDate, shiftStartTime, shiftEndTime]);

  const getShiftType = (startDate: any) => {
    const tsDayShiftTime = new Date();
    tsDayShiftTime.setHours(11, 59, 59, 999);

    const tsEveningShiftTime = new Date();
    tsEveningShiftTime.setHours(19, 59, 59, 999);

    const tsNightShiftTime = new Date();
    tsNightShiftTime.setHours(3, 59, 59, 999);

    const startHour = startDate.getHours();
    const startMinutes = startDate.getMinutes();
    const startTimeOfDay =
      startDate.getHours() * 3600 +
      startDate.getMinutes() * 60 +
      startDate.getSeconds();
    const dayShiftEnd = 11 * 3600 + 59 * 60 + 59;
    const eveningShiftEnd = 19 * 3600 + 59 * 60 + 59;
    const nightShiftEnd = 3 * 3600 + 59 * 60 + 59;

    if (startHour >= 4 && startTimeOfDay < dayShiftEnd) {
      return "Day";
    }
    if (startHour >= 12 && startTimeOfDay < eveningShiftEnd) {
      return "Eve";
    }
    if (startHour >= 20 || (startHour >= 0 && startTimeOfDay < nightShiftEnd)) {
      return "Noc";
    }
    return null;
  };

  const handleStarttimeClear = () => {
    setShiftStartTime(null);
    setShiftEndTime(null);
    shiftDetail.start_date_string = "";
    shiftDetail.end_date_string = "";
  };

  const handleEndtimeClearclick = () => {
    setShiftEndTime(null);
    shiftDetail.end_date_string = "";
  };

  return (
    <>
      <Box
        sx={{
          width: "calc(100%-32px)",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          border: "1px solid #F1F2F4",
          padding: "16px",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            width: "98%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#17191C",
              lineHeight: "24.3px",
            }}
          >
            Date and duration
          </Typography>
          <Chip
            sx={{
              backgroundColor: "#F9FAFA",
              border: "1px solid #F1F2F4",
              color: !!shiftDuration ? "#29A347" : "#8F98A3",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "22.4px",
              borderRadius: "10px !important",
            }}
            label={
              !!shiftDuration
                ? `${String(shiftDuration)} per week`
                : "0h per week"
            }
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              color: "#737F8C",
              justifyContent: "center",
            }}
          >
            <LableTextFieldBox>
              <TextFieldLableTypography text="Start date" required={true} />
            </LableTextFieldBox>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{
                  justifyContent: "center",
                  background: "#F5FAFE",
                  width: "100%",
                  "& .MuiSvgIcon-root": {
                    color: "#0D80F2 !important",
                  },
                  ".MuiInputBase-input": {
                    padding: "8.5px 14px !important",
                    fontSize: "16px",
                    fontWeight: "400",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #0D80F2 !important",
                    borderRadius: "8px",
                  },
                  ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "2px solid #0D80F2 !important",
                    borderRadius: "8px",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #CFE5FC",
                    borderRadius: "8px",
                  },

                  "& .MuiInputLabel-root": { display: "none" },
                  "& .MuiPickersDay-root": {
                    "&.Mui-selected": {
                      background: "#0D80F2 !important",
                      color: "#FFFFFF !important",
                    },
                  },

                  Input: {
                    "&::placeholder": {
                      color: "#6EB2F7",
                      opacity: "5",
                    },
                  },
                }}
                slotProps={{
                  textField: {
                    inputProps: {
                      readOnly: true,
                    },
                  },
                  day: {
                    sx: {
                      ['&[data-mui-date="true"] .Mui-selected']: {
                        backgroundColor: "#0D80F2",
                      },
                      ":not(.Mui-selected)": {
                        backgroundColor: "#fff",
                      },
                      "&.Mui-selected": {
                        color: "#fff",
                        backgroundColor: "#0D80F2 !important",
                        ":hover": {
                          color: "#fff",
                          backgroundColor: "#0D80F2",
                        },
                      },
                      ":hover": {
                        color: "#fff",
                        backgroundColor: "#0D80F2",
                      },
                    },
                  },
                }}
                format="MM.DD.YYYY"
                onChange={(newValue) => setShiftDate(newValue)}
                value={shiftDate}
                disablePast={true}
              />
            </LocalizationProvider>
            {!!props.errorMessage && (
              <span
                style={{ fontSize: "10px", fontWeight: "400", color: "red" }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22.4px",
                  }}
                  className="red-text"
                >
                  {getErrorDetail("start_date", props.errorMessage)}
                </Typography>
              </span>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              color: "#737F8C",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <LableTextFieldBox>
                <TextFieldLableTypography
                  text="Shift start time"
                  required={true}
                />
              </LableTextFieldBox>
              <Box
                sx={{
                  paddingTop: "1%",
                  cursor: "pointer",
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.9999 2.60039C5.01727 2.60039 2.5999 5.01776 2.5999 8.00039C2.5999 10.983 5.01727 13.4004 7.9999 13.4004C10.9825 13.4004 13.3999 10.983 13.3999 8.00039C13.3999 5.01776 10.9825 2.60039 7.9999 2.60039ZM1.3999 8.00039C1.3999 4.35502 4.35453 1.40039 7.9999 1.40039C11.6453 1.40039 14.5999 4.35502 14.5999 8.00039C14.5999 11.6458 11.6453 14.6004 7.9999 14.6004C4.35453 14.6004 1.3999 11.6458 1.3999 8.00039ZM7.06658 5.8359C7.064 5.3173 7.48477 4.90039 7.99857 4.90039C8.51372 4.90039 8.93324 5.31679 8.93324 5.83372C8.93324 6.34909 8.51528 6.76706 7.99991 6.76706C7.48526 6.76706 7.06775 6.35027 7.06658 5.8359ZM7.06658 5.8359C7.06657 5.83517 7.06657 5.83445 7.06657 5.83372H7.66656L7.06659 5.83807C7.06658 5.83735 7.06658 5.83662 7.06658 5.8359ZM8.26656 5.83372C8.26656 5.83302 8.26656 5.83231 8.26656 5.8316C8.26656 5.831 8.26655 5.8304 8.26655 5.82979M7.9999 7.40039C8.33127 7.40039 8.5999 7.66902 8.5999 8.00039V11.3337C8.5999 11.6651 8.33127 11.9337 7.9999 11.9337C7.66853 11.9337 7.3999 11.6651 7.3999 11.3337V8.00039C7.3999 7.66902 7.66853 7.40039 7.9999 7.40039Z"
                    fill="#737F8C"
                  />
                </svg>
              </Box>
            </Box>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker
                sx={{
                  justifyContent: "center",
                  background: "#F5FAFE",
                  width: "100%",
                  "& .MuiSvgIcon-root": {
                    color: "#0D80F2 !important",
                  },
                  ".MuiInputBase-input": {
                    padding: "8.5px 14px !important",
                    fontSize: "16px",
                    fontWeight: "400",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("shiftStartTime", props.errorMessage)
                          ? "red"
                          : "#CFE5FC !important",
                    },
                    "&:hover fieldset": {
                      borderColor:
                      !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("shiftStartTime", props.errorMessage)
                          ? "red"
                          : "#0D80F2 !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor:
                      !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("shiftStartTime", props.errorMessage)
                          ? "red"
                          : "#0D80F2 !important",
                    },
                    borderRadius: "8px",
                  },
                  Input: {
                    "&::placeholder": {
                      color: "#6EB2F7",
                      opacity: "5",
                    },
                  },
                }}
                onChange={(newValue) => {
                  setShiftStartTime(newValue);
                  setShiftEndTime(null);
                }}
                onError={(newError) => setShiftStartTimeError(newError)}
                minutesStep={5}
                value={shiftStartTime}
                slotProps={{
                  field: {
                    clearable: true,
                    onChange: handleStarttimeClear,
                  },
                  textField: {
                    placeholder: "Shift start time",
                    inputProps: {
                      readOnly: true,
                    },
                  },
                  layout: {
                    sx: {
                      ul: {
                        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                        },
                        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb":
                          {
                            borderRadius: "32px",
                            backgroundColor: grey["400"],
                          },
                        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                          {
                            backgroundColor: grey["500"],
                          },
                        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                          {
                            backgroundColor: grey["500"],
                          },
                        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                          {
                            backgroundColor: grey["500"],
                          },
                        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner":
                          {
                            backgroundColor: "#0000000",
                          },
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>

            <span
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "red",
                height: "22.4px",
              }}
            >
              {!!props.errorMessage && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22.4px",
                  }}
                  className="red-text"
                >
                  {getErrorDetail("shiftStartTime", props.errorMessage)}
                </Typography>
              )}
            </span>
          </Box>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <LableTextFieldBox>
                <TextFieldLableTypography
                  text="Shift end time "
                  required={true}
                />
              </LableTextFieldBox>
              <Box
                sx={{
                  paddingTop: "1%",
                  cursor: "pointer",
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.9999 2.60039C5.01727 2.60039 2.5999 5.01776 2.5999 8.00039C2.5999 10.983 5.01727 13.4004 7.9999 13.4004C10.9825 13.4004 13.3999 10.983 13.3999 8.00039C13.3999 5.01776 10.9825 2.60039 7.9999 2.60039ZM1.3999 8.00039C1.3999 4.35502 4.35453 1.40039 7.9999 1.40039C11.6453 1.40039 14.5999 4.35502 14.5999 8.00039C14.5999 11.6458 11.6453 14.6004 7.9999 14.6004C4.35453 14.6004 1.3999 11.6458 1.3999 8.00039ZM7.06658 5.8359C7.064 5.3173 7.48477 4.90039 7.99857 4.90039C8.51372 4.90039 8.93324 5.31679 8.93324 5.83372C8.93324 6.34909 8.51528 6.76706 7.99991 6.76706C7.48526 6.76706 7.06775 6.35027 7.06658 5.8359ZM7.06658 5.8359C7.06657 5.83517 7.06657 5.83445 7.06657 5.83372H7.66656L7.06659 5.83807C7.06658 5.83735 7.06658 5.83662 7.06658 5.8359ZM8.26656 5.83372C8.26656 5.83302 8.26656 5.83231 8.26656 5.8316C8.26656 5.831 8.26655 5.8304 8.26655 5.82979M7.9999 7.40039C8.33127 7.40039 8.5999 7.66902 8.5999 8.00039V11.3337C8.5999 11.6651 8.33127 11.9337 7.9999 11.9337C7.66853 11.9337 7.3999 11.6651 7.3999 11.3337V8.00039C7.3999 7.66902 7.66853 7.40039 7.9999 7.40039Z"
                    fill="#737F8C"
                  />
                </svg>
              </Box>
            </Box>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker
                sx={{
                  justifyContent: "center",
                  background: "#F5FAFE",
                  width: "100%",
                  "& .MuiSvgIcon-root": {
                    color: "#0D80F2 !important",
                  },
                  ".MuiInputBase-input": {
                    padding: "8.5px 14px !important",
                    fontSize: "16px",
                    fontWeight: "400",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                      !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("shiftEndTime", props.errorMessage)
                          ? "red"
                          : "#CFE5FC !important",
                    },
                    "&:hover fieldset": {
                      borderColor:
                      !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("shiftEndTime", props.errorMessage)
                          ? "red"
                          : "#0D80F2 !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor:
                      !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("shiftEndTime", props.errorMessage)
                          ? "red"
                          : "#0D80F2 !important",
                    },
                    borderRadius: "8px",
                  },
                  Input: {
                    "&::placeholder": {
                      color: "#6EB2F7",
                      opacity: "5",
                    },
                  },
                }}
                minutesStep={5}
                value={shiftEndTime}
                onError={(newError) => setShiftEndTimeError(newError)}
                slotProps={{
                  field: {
                    clearable: true,
                    onChange: handleEndtimeClearclick,
                  },
                  textField: {
                    placeholder: "Shift end time",
                    inputProps: {
                      readOnly: true,
                    },
                  },
                  layout: {
                    sx: {
                      ul: {
                        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                        },
                        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb":
                          {
                            borderRadius: "32px",
                            backgroundColor: grey["400"],
                          },
                        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                          {
                            backgroundColor: grey["500"],
                          },
                        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                          {
                            backgroundColor: grey["500"],
                          },
                        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                          {
                            backgroundColor: grey["500"],
                          },
                        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner":
                          {
                            backgroundColor: "#0000000",
                          },
                      },
                    },
                  },
                }}
                onChange={(newValue) => {
                  setShiftEndTime(newValue);
                }}
              />
            </LocalizationProvider>
            <span
              style={{
                fontSize: "10px",
                fontWeight: "400",
                color: "red",
                height: "22.4px",
              }}
            >
              {!!props.errorMessage && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22.4px",
                  }}
                  className="red-text"
                >
                  {getErrorDetail("shiftEndTime", props.errorMessage)}
                </Typography>
              )}
            </span>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            color: "#737F8C",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LableTextFieldBox>
            <TextFieldLableTypography text="Duration" required={false} />
          </LableTextFieldBox>
          <PlatformTextField
            sx={{
              width: "100%",
              "& fieldset": {
                border: "1px solid #F1F2F4 !important",
                borderRadius: "8px",
              },
              ".MuiInputBase-root .MuiOutlinedInput-input": {
                backgroundColor: "#F1F2F4 !important",
              },
            }}
            InputProps={{
              readOnly: true,
            }}
            value={
              !!shiftDuration
                ? `${getShiftType(shiftDetail.start_date)} ${String(
                    shiftDuration
                  )}`
                : "Shift duration will be specified when the start and end time selected"
            }
            handleChange={function (e: any): void {}}
            placeholder={
              "Shift duration will be specified when the start and end time selected"
            }
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            color: "#737F8C",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
            }}
          >
            <LableTextFieldBox>
              <TextFieldLableTypography text="Time preset" required={false} />
            </LableTextFieldBox>
            <Box
              sx={{
                paddingTop: "1%",
                cursor: "pointer",
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.9999 2.60039C5.01727 2.60039 2.5999 5.01776 2.5999 8.00039C2.5999 10.983 5.01727 13.4004 7.9999 13.4004C10.9825 13.4004 13.3999 10.983 13.3999 8.00039C13.3999 5.01776 10.9825 2.60039 7.9999 2.60039ZM1.3999 8.00039C1.3999 4.35502 4.35453 1.40039 7.9999 1.40039C11.6453 1.40039 14.5999 4.35502 14.5999 8.00039C14.5999 11.6458 11.6453 14.6004 7.9999 14.6004C4.35453 14.6004 1.3999 11.6458 1.3999 8.00039ZM7.06658 5.8359C7.064 5.3173 7.48477 4.90039 7.99857 4.90039C8.51372 4.90039 8.93324 5.31679 8.93324 5.83372C8.93324 6.34909 8.51528 6.76706 7.99991 6.76706C7.48526 6.76706 7.06775 6.35027 7.06658 5.8359ZM7.06658 5.8359C7.06657 5.83517 7.06657 5.83445 7.06657 5.83372H7.66656L7.06659 5.83807C7.06658 5.83735 7.06658 5.83662 7.06658 5.8359ZM8.26656 5.83372C8.26656 5.83302 8.26656 5.83231 8.26656 5.8316C8.26656 5.831 8.26655 5.8304 8.26655 5.82979M7.9999 7.40039C8.33127 7.40039 8.5999 7.66902 8.5999 8.00039V11.3337C8.5999 11.6651 8.33127 11.9337 7.9999 11.9337C7.66853 11.9337 7.3999 11.6651 7.3999 11.3337V8.00039C7.3999 7.66902 7.66853 7.40039 7.9999 7.40039Z"
                  fill="#737F8C"
                />
              </svg>
            </Box>
          </Box>

          <FormControlLabel
            sx={{
              ".MuiFormControlLabel-label": {
                fontSize: "16px !important",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#000000DE",
              },
            }}
            control={
              <Switch
                disabled
                sx={{
                  width: "58px",
                  ".Mui-checked": {
                    color: "#0D80F2 !important",
                  },
                  ".Mui-checked+.MuiSwitch-track": {
                    backgroundColor: "#0D80F2 !important",
                  },
                }}
                checked={false}
              />
            }
            label="Save as preset"
          />
        </Box>
      </Box>
    </>
  );
};
