import { createContext, useContext } from "react";
import { LoginDetail } from "../model-lib";

interface ILoginDetailContext {
  loginDetail: LoginDetail;
  setLoginDetail: React.Dispatch<React.SetStateAction<LoginDetail>>;
}

export const LoginDetailContext = createContext<
  ILoginDetailContext | undefined
>(undefined);

export const useLoginDetailContext = (): ILoginDetailContext => {
  const context = useContext(LoginDetailContext);
  if (context === undefined) {
    throw new Error(
      "useDepartmentDetailContext must be used within a MyProvider"
    );
  }
  return context;
};
