import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  MenuItem,
} from "@mui/material";
import {
  CloseIcon,
  ContainedButton,
  LableTextFieldBox,
  OutlinedButton,
} from "../../atom";
import { useState } from "react";
import { CalendarDropDown } from "../../atom/dropdown/calendarDropdown";
import { TextFieldLableTypography } from "../../atom/typography/textFieldLableTypography";

interface IMarketPlacefilterDialog {
  handleShowFilterClick: () => void;
  handleCancelFilterClick: () => void;
}

export const MarketPlacefilterDialog = (props: IMarketPlacefilterDialog) => {
  return (
    <>
      <Dialog
        maxWidth="md"
        open={true}
        sx={{
          position: "absolute",
          top: "-27%",
          left: "75%",
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
              minWidth: "400px",
              borderRadius: "8px",
              boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.1)",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "#17191C", fontSize: "18px", fontWeight: "600" }}
        >
          Marketplace filter
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.handleCancelFilterClick}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#0D80F2",
          }}
        >
          <CloseIcon sx={null} />
        </IconButton>
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "18px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                color: "#737F8C",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LableTextFieldBox>
                <TextFieldLableTypography
                  text="Lines of business"
                  required={false}
                />
              </LableTextFieldBox>
              <CalendarDropDown
                handleChange={function (e: any): void {}}
                value={1}
                sx={null}
              >
                <MenuItem value={1}> All </MenuItem>
              </CalendarDropDown>
            </Box>
            <Box
              sx={{
                width: "100%",
                color: "#737F8C",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LableTextFieldBox>
                <TextFieldLableTypography
                  text="Talent qualifications"
                  required={false}
                />
              </LableTextFieldBox>
              <CalendarDropDown
                sx={null}
                handleChange={function (e: any): void {}}
                value={1}
              >
                <MenuItem value={1}> All </MenuItem>
              </CalendarDropDown>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  color: "#737F8C",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LableTextFieldBox>
                  <TextFieldLableTypography text="Regions" required={false} />
                </LableTextFieldBox>
                <CalendarDropDown
                  sx={null}
                  handleChange={function (e: any): void {}}
                  value={1}
                >
                  <MenuItem value={1}> All </MenuItem>
                </CalendarDropDown>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}
            ></Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0px 16px",
            }}
          >
            <Box
              sx={{
                width: "30%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <OutlinedButton
                text={"Reset"}
                handleClick={props.handleCancelFilterClick}
                sx={{ height: "40px" }}
              />
            </Box>
            <Box
              sx={{
                width: "70%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <OutlinedButton
                text={"Cancel"}
                handleClick={props.handleCancelFilterClick}
                sx={{ height: "40px" }}
              />
              <ContainedButton
                text={"Apply"}
                handleClick={function (): void {}}
                sx={{ height: "40px" }}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
