import React from 'react';
import { Route, Navigate, RouteProps, Outlet } from 'react-router-dom';


const isAuthenticated = (): boolean => {
  let accesToken = localStorage.getItem("accessTokenState");
  if (!!accesToken) {
    let tokenDetail = JSON.parse(accesToken);

    if(!!tokenDetail.state && !!tokenDetail.state.token) {
        return true;
    }
  }
  return false;
};

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/" replace />;
};

export default ProtectedRoute;