import { useEffect } from "react";
import { currentActiveScreenState } from "../../../libs/state-management-lib";
import StaffingPartnerTemplate from "../../template/settings/staffingPartnerTemplate";

const StaffingPartner = () => {
  useEffect(() => {
    currentActiveScreenState.setState("/settings/organization");
  }, []);

  return <StaffingPartnerTemplate />;
};

export default StaffingPartner;
