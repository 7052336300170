import { Box } from "@mui/material";
import { ContainedButton } from "../buttons/containedButton";
import { OutlinedButton } from "../buttons/outlinedButton";

interface ISettingsFooterBox {
  handleCancelClick: () => void;
  handleSaveClick: () => void;
}

const SettingsFooterBox = (props: ISettingsFooterBox) => {
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "16px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            borderTop: "1px solid #CFE5FC",
            borderRadius: "0px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "95%",
            gap: "16px",
            padding: "16px 16px 16px 28px",
          }}
        >
          <OutlinedButton
            sx={{ height: "38px" }}
            text="Cancel"
            handleClick={props.handleCancelClick}
          />
          <ContainedButton
            sx={{ height: "38px" }}
            text="Save"
            handleClick={props.handleSaveClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsFooterBox;
