import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import {
  ActiveStatusAlert,
  ContainedButton,
  ErrorAlert,
  FilterIcon,
  OutlinedButton,
  OverFlowBox,
  PlatformTextField,
  PlayCircleIcon,
  PlusIcon,
  ProfileIcon,
  SearchTextField,
  SettingBox,
  SuccessAlert,
  Typography12_400,
  Typography16_400,
  Typography16_600,
  Typography18_600,
  UpdateAlert,
} from "../../atom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { StaffDashboard } from "./staffDashboard";
import AddIcon from "@mui/icons-material/Add";
import {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  activeConfirmationPopupState,
  activeStatusAlertState,
  errorAlertState,
  errorMessageState,
  inActiveConfirmationPopupState,
  loadingIconState,
  organizationDetailState,
  successAlertState,
  updateAlertState,
} from "../../../libs/state-management-lib";
import {
  createStaff,
  getStaffById,
  getStaffDetailList,
  updateStaff,
} from "../../../libs/services-lib/staffService";
import { StaffDetailForm } from "./staffDetailForm";
import { StaffInfo } from "./staffInfo";
import {
  getAllLocationsByOrgId,
  getOrganizationRoles,
  getRoleTypeList,
  getStaffTypeList,
} from "../../../libs/services-lib";
import Joi from "joi";
import { StaffDetail, StaffLocationMappings } from "../../../libs/model-lib";
import { staffDetailState } from "../../../libs/state-management-lib/staffState";
import { EmptyGuid, emptyStaffDetail, Encryption } from "../../../libs/common-lib";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { format } from "date-fns";
import { useNavigate, useSearchParams } from "react-router-dom";

export const StaffSetting = () => {
  const navigate = useNavigate();

  useEffect(() => {
    refreshEvent();
  }, []);

  const refreshEvent = () => {
    (async function () {
      loadingIconState.setState(true);
      await Promise.all([
        getStaffDetailList(),
        getStaffTypeList(),
        getRoleTypeList(),
        getAllLocationsByOrgId(organizationDetailState.getState().id),
        getOrganizationRoles(organizationDetailState.getState().id),
      ]);
      loadingIconState.setState(false);
    })();
  };

  const handleAddStaff = () => {
    navigate(`/settings/talent/${Encryption(EmptyGuid)}`);
  };

  const handleStaffDashboardCallBack = async (staffId: string) => {
  navigate(`/settings/talent/${Encryption(staffId)}`);
  };

  const handleActiveStafft = async (activeStaffId: string) => {
    loadingIconState.setState(true);
    await getStaffById(activeStaffId);
    let staffDetails = staffDetailState.getState();
    staffDetails.is_active = false;
    staffDetails.date_of_birth = 
      staffDetails.date_of_birth;
    try {
      await updateStaff(staffDetails);
      refreshEvent();
    } catch (error) {}
    loadingIconState.setState(false);
    activeConfirmationPopupState.setState(false);
  };

  const handleInActiveStafft = async (InActiveStaffId: string) => {
    loadingIconState.setState(true);
    await getStaffById(InActiveStaffId);
    let staffDetails = staffDetailState.getState();
    staffDetails.is_active = true;
    staffDetails.date_of_birth = staffDetails.date_of_birth;
    try {
      await updateStaff(staffDetails);
      refreshEvent();
    } catch (error) {}
    loadingIconState.setState(false);
    inActiveConfirmationPopupState.setState(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          width:"100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "16px 24px 16px 24px",
            gap: "10px",
            borderRadius: "8px",
            backgroundColor: "#FEF8F5",
            marginTop: "20px",
            marginLeft: "20px",
            width:"42%",
          }}
        >
          <PlayCircleIcon
            sx={{
              color: "#F7906E",
            }}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                alignItems: "center",
                color: "#F7906E",
              }}
            >
              Setting up talents
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "12px",
                alignItems: "center",
                color: "#F7906E",
                lineHeight: "29px",
                 paddingLeft:"5px"
              }}
            >
              1 min.
            </Typography>
          </Box>
          <CloseOutlinedIcon
            sx={{
              color: "#F7906E",
              top: "5.2px",
              left: "5.2px",
              alignItems: "flex-end",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 20px)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid #CFE5FC",
            padding: "10px",
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: "10px",
            }}
          >
            <Typography18_600 text="Talent" color="#17191C" />
          </Box>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <SearchTextField
              placeholder={"Search"}
              value={""}
              handleChange={(e: any) => {}}
            />
            <FilterIcon
              sx={{
                // color: "#0D80F2",
                // cursor: "pointer",
                marginTop: "1%",
                paddingLeft: "2%",
                color:"#ABB2BA"
              }}
            />
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "8px 16px",
                gap: "8px",
                borderRadius: "8px",
              }}
            >
              <ContainedButton
                text="Add talent"
                startIcon={
                  <AddIcon
                    sx={{ color: "#FFFFFF", width: "24px", height: "24px" }}
                  />
                }
                sx={{ height: "40px" }}
                handleClick={handleAddStaff}
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "24px 18px 34px 10px",
            gap: "10px",
            height: "calc(100% - 58px)",
            width: "calc(100% - 36px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "10px",
              gap: "10px",
              width: "calc(100% - 20px)",
              height: "calc(100% - 20px)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: "6px",
                  zIndex: 1,
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <StaffDashboard
                    handleStaffDashboardCallBack={handleStaffDashboardCallBack}
                    handleActiveStafft={handleActiveStafft}
                    handleInActiveStafft={handleInActiveStafft}
                    handleAddStaff={handleAddStaff}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
