import { useEffect } from "react";
import { LayoutTemplate } from "../template";
import { CalendarTemplate } from "../template/calendarTemplate";
import { currentActiveScreenState } from "../../libs/state-management-lib";

export const Calendar = () => {
  useEffect(() => {
    currentActiveScreenState.setState("/schedule");
  }, []);

  return (
    <>
      <CalendarTemplate />
    </>
  );
};
