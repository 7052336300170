import { useEffect } from "react";
import { currentActiveScreenState } from "../../../libs/state-management-lib";
import LocationDetailTemplate from "../../template/settings/locationDetailTemplate";

const LocationDetail = () => {
  useEffect(() => {
    currentActiveScreenState.setState("/settings/organization");
  }, []);
  return <LocationDetailTemplate />;
};

export default LocationDetail;
