import { axiosInstance, emptyDepartmentDetail, handleApiErrorAlert, handleApiSuccessAlert } from "../common-lib";
import { DepartmentDetail } from "../model-lib";
import {
  apiAlertState,
  departmentDetailState,
  departmentListState,
  errorAlertState,
  errorMessageState,
  organizationDetailState,
  orgDepartmentListState,
  successAlertState,
} from "../state-management-lib";

const organization_base_url = `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/Department`;

export const createDepartment = async (departmentDetail: DepartmentDetail) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/CreateDepartment`,
      departmentDetail
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      await getDepartmentByOrganizationId(
        organizationDetailState.getState().id
      );
      
      handleApiSuccessAlert("Department created successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
    throw error;
  }
};
export const getDepartmentByOrganizationId = async (organizationId: string) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/getDepartmentByOrganizationId?organizationId=${organizationId}`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      departmentListState.setState({
        departmentDetailList: response.data.data,
      });
    }
  } catch (error) {}
};

export const getDepartmentById = async (depId: string) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/GetDepartmentById?departmentId=${depId}`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      departmentDetailState.setState(response.data.data);
      return response.data.data;
    }

    return emptyDepartmentDetail;
  } catch (error) {}
};

export const getAllDepartmentsByLocationId = async (locationId: any) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/GetAllDepartmentsByLocationId?locationId=${locationId}`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      departmentListState.setState({
        departmentDetailList: response.data.data,
      });

      return response.data.data;
    }

    return [];
  } catch (error) {}
};

export const getAllDepartmentsByOrgId = async (organizationId: string) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/GetDepartmentByOrganizationId?organizationId=${organizationId}`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      orgDepartmentListState.setState({
        departmentDetailList: response.data.data,
      });

      return response.data.data;
    }

    return [];
  } catch (error) {}
};

export const updateDepartment = async (departmentDetail: DepartmentDetail) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/UpdateDepartment`,
      departmentDetail
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      await getDepartmentByOrganizationId(
        organizationDetailState.getState().id
      );

      handleApiSuccessAlert("Department updated successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
    throw error;
  }
};

export const deleteDepartment = async (departmentDetail: DepartmentDetail) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/DeleteDepartment`,
      departmentDetail
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      await getDepartmentByOrganizationId(
        organizationDetailState.getState().id
      );

      handleApiSuccessAlert("Department removed successfully");
    }
  } catch (error) {
    handleApiErrorAlert(error);
  }
};
