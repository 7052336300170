import { createContext, useContext } from "react";
import { StaffDetail } from "../model-lib";

interface IStaffDetailContext {
  staffDetail: StaffDetail;
  setStaffDetail: React.Dispatch<React.SetStateAction<StaffDetail>>;
  primaryLocationId: string;
  setPrimaryLocationId: React.Dispatch<React.SetStateAction<string>>;
  otherLocationId: string;
  setOtherLocationId: React.Dispatch<React.SetStateAction<string>>;
  primaryDepartmentId: string;
  setPrimaryDepartmentId: React.Dispatch<React.SetStateAction<string>>;
  otherDepartmentId: string;
  setOtherDepartmentId: React.Dispatch<React.SetStateAction<string>>;
  primaryUnitId: string;
  setPrimaryUnitId: React.Dispatch<React.SetStateAction<string>>;
  otherUnitId: string;
  setOtherUnitId: React.Dispatch<React.SetStateAction<string>>;
}

export const StaffDetailContext = createContext<
  IStaffDetailContext | undefined
>(undefined);

export const useStaffDetailContext = (): IStaffDetailContext => {
  const context = useContext(StaffDetailContext);
  if (context === undefined) {
    throw new Error("useStaffDetailContext must be used within a MyProvider");
  }
  return context;
};
