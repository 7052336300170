import { OrganizationDetail } from "../model-lib";
import { createContext, useContext } from "react";

interface IOrganizationDetailContext {
  organizationDetail: OrganizationDetail;
  setOrganizationDetail: React.Dispatch<
    React.SetStateAction<OrganizationDetail>
  >;
}

export const OrganizationDetailContext = createContext<
  IOrganizationDetailContext | undefined
>(undefined);

export const useOrganizationDetailContext = (): IOrganizationDetailContext => {
  const context = useContext(OrganizationDetailContext);
  if (context === undefined) {
    throw new Error(
      "useOrganizationDetailContext must be used within a MyProvider"
    );
  }
  return context;
};
