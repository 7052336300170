import { Box, Typography } from "@mui/material";
import Joi from "joi";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  EmptyLocationDetail,
  EmptyGuid,
  Decryption,
} from "../../../libs/common-lib";
import { LocationDetailContext } from "../../../libs/context-lib/locationDetailContext";
import { LocationDetail, LocationContactDetail } from "../../../libs/model-lib";
import {
  getLocationById,
  updateLocation,
  createLocation,
} from "../../../libs/services-lib";
import {
  currentActiveScreenState,
  loadingIconState,
  locationClearedState,
  organizationDetailState,
} from "../../../libs/state-management-lib";
import { OverFlowBox, SettingBox } from "../../atom";
import SettingsFooterBox from "../../atom/box/settingsFooterBox";
import SettingsPlayBox from "../../atom/box/settingsPlayBox";
import { SettingNavigation } from "../../molecule";
import { LocationContactInfoForm } from "../../organism/location/locationContactInfoForm";
import { LocationDetailForm } from "../../organism/location/locationDetailForm";
import { DetailSettingBox } from "../../organism";

const LocationDetailTemplate = () => {
  const [errorMessage, setErrorMessage] = useState<any[]>([]);
  const { id } = useParams();
  const [locationDetail, setLocationDetail] =
    useState<LocationDetail>(EmptyLocationDetail);
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      if (!!id) {
        if (Decryption(id) == EmptyGuid || !!!Decryption(id)) {
          setLocationDetail(EmptyLocationDetail);
        } else {
          loadingIconState.setState(true);
          let locDetail = await getLocationById(Decryption(id));
          setLocationDetail(locDetail);
          loadingIconState.setState(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (!!errorMessage && errorMessage.length > 0) {
      let selectedPara = document.querySelectorAll("p.red-text");
      if (!!selectedPara && selectedPara.length > 0 && !!selectedPara[0]) {
        selectedPara[0].closest("div")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [errorMessage]);

  const locationDetailSchema = Joi.object<LocationDetail>({
    id: Joi.string().allow(""),
    name: Joi.string().required().max(255).messages({
      "string.empty": `Location name cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    address_1: Joi.string().required().max(255).messages({
      "string.empty": `Home address 1 cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    address_2: Joi.string().allow("").max(255).messages({
      "string.max": `The maximum character limit should be 255`,
    }),
    city: Joi.string().required().max(255).messages({
      "string.empty": `City cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    state: Joi.string().required().max(255).messages({
      "string.empty": `State cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    postal_code: Joi.string().min(5).max(5).required().messages({
      "string.empty": `Postal code cannot be an empty field`,
      "string.min": `Postal code is invalid`,
      "string.max": `Postal code is invalid`,
    }),
    location_type: Joi.number().positive().greater(0).required().messages({
      "number.positive": `Location type cannot be an empty field`,
      "any.required": `Location type cannot be an empty field`,
    }),
    org_id: Joi.string().allow(""),
    is_active: Joi.boolean(),
    timezone: Joi.string().required().messages({
      "string.empty": `Timezone cannot be an empty field`,
    }),
    week_start_day: Joi.number().allow(0),
    locationContactDetails: Joi.object<LocationContactDetail>({
      id: Joi.string().allow(""),
      name: Joi.string().allow("").max(255).messages({
        "string.max": `The maximum character limit should be 255`,
      }),
      title: Joi.string().allow("").max(255).messages({
        "string.max": `The maximum character limit should be 255`,
      }),
      phone_number: Joi.string()
        .allow("")
        .pattern(/^\+1 \(\d{3}\) \d{3}-\d{4}$/)
        .max(17)
        .messages({
          "string.pattern.base": "Enter valid phone number",
        }),
      location_id: Joi.string().allow(""),
      email: Joi.string().allow("").max(255).email({ tlds: false }).messages({
        "string.email": `Contact email is not valid`,
        "string.max": `The maximum character limit should be 255`,
      }),
      is_active: Joi.boolean(),
    }),
  }).options({ abortEarly: false });

  const handleLocationSaveClick = async () => {
    const locDetailValidate = { ...locationDetail };

    if (
      locDetailValidate.locationContactDetails.phone_number == "+" ||
      locDetailValidate.locationContactDetails.phone_number == "+1"
    ) {
      locDetailValidate.locationContactDetails.phone_number = "";
    }

    const result = locationDetailSchema.validate(locDetailValidate);
    let errorMessage: any[] = [];

    if (!!result.error && !!result.error.details) {
      result.error.details.forEach((cc) => {
        let field = cc.path[0];
        if (cc.path.length > 1) {
          field = cc.path.join("$");
        }
        errorMessage.push({ field: field, errorMessage: cc.message });
      });

      setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage([]);
    loadingIconState.setState(true);

    const locDetail = { ...locationDetail };

    if (!!locDetail.id && locDetail.id != EmptyGuid) {
      try {
        await updateLocation(locDetail);
      } catch (error) {}
    } else {
      locDetail.org_id = organizationDetailState.getState().id;
      locDetail.id = EmptyGuid;
      locDetail.locationContactDetails.id = EmptyGuid;
      locDetail.locationContactDetails.location_id = EmptyGuid;
      try {
        await createLocation(locDetail);
        setLocationDetail(EmptyLocationDetail);
        locationClearedState.setState(!locationClearedState.getState());
      } catch (error) {}
    }
    loadingIconState.setState(false);
  };

  const handleLocationCancelClick = () => {
    navigate("/settings/locations-departments");
  };

  return (
    <DetailSettingBox
      navigationSelectedMenu="Location"
      playBoxTitle="Setting up locations and departments"
      playBoxDuration="1 min."
      pageTitle={
        !!id && (Decryption(id) == EmptyGuid || !!!Decryption(id)) ? "Add location" : "Update location"
      }
      handleSaveClick={handleLocationSaveClick}
      handleCancelClick={handleLocationCancelClick}
    >
      <LocationDetailContext.Provider
        value={{ locationDetail, setLocationDetail }}
      >
        <LocationDetailForm errorMessage={errorMessage} />
        <LocationContactInfoForm errorMessage={errorMessage} />
      </LocationDetailContext.Provider>
    </DetailSettingBox>
  );
};

export default LocationDetailTemplate;
