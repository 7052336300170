import { Box } from "@mui/material"
import { ReactNode } from "react"

interface ILableTextFieldBox {
    children: ReactNode;
}

export const LableTextFieldBox = (props: ILableTextFieldBox) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "6px",
      }}
    >
      {props.children}
    </Box>
  );
}