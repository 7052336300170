import { Box } from "@mui/material"
import { ReactNode } from "react";

interface ITextFieldPlacementBox {
    children: ReactNode;
}

export const TextFieldPlacementBox = (props: ITextFieldPlacementBox) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        marginTop: "20px",
        gap: "12px",
      }}
    >
      {props.children}
    </Box>
  );
}