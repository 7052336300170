import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import {
  Address,
  Module,
  OrgRole,
  OrgRolePermissionMapping,
  OrganizationDetail,
  OrganizationOnboarding,
  SubModule,
} from "../model-lib";
import { emptyOrgOnboardingDetail, emptyOrgRole } from "../common-lib";

export const organizationDetailState = create<OrganizationDetail>()(
  devtools(
    persist(
      (set, get) => ({
        id: "",
        name: "",
        npi: "",
        definitive_id: "",
        medicare_provider_number: "",
        number_of_staffed_beds: 0,
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        postal_code: "",
        contact_name: "",
        contact_phone_number: "",
        contact_email: "",
        contact_title: "",
        tenant_id: "",
        number_of_employee: 0,
        primary_phone_number: "",
        website: "",
        is_active: true,
        staff_types: [],
        invitation_id: null,
        contact_person_email: "",
        facility_type: 0,
      }),
      { name: "organizationDetailState" }
    )
  )
);

export const orgRoleState = create<OrgRole>()(
  devtools(
    persist((set, get) => emptyOrgRole, {
      name: "orgRoleState",
    })
  )
);

interface IOrgRoleListState {
  orgRoleList: OrgRole[];
}

export const orgRoleListState = create<IOrgRoleListState>()(
  devtools(
    persist((set, get) => ({ orgRoleList: [] }), {
      name: "orgRoleListState",
    })
  )
);

interface IOrgRolePermissionMappingState {
  orgRolePermissionList: OrgRolePermissionMapping[];
}

export const orgRolePermissionListState =
  create<IOrgRolePermissionMappingState>()(
    devtools(
      persist((set, get) => ({ orgRolePermissionList: [] }), {
        name: "orgRolePermissionListState",
      })
    )
  );

interface IModuleListState {
  moduleList: Module[];
}

export const moduleListState = create<IModuleListState>()(
  devtools(
    persist((set, get) => ({ moduleList: [] }), {
      name: "moduleListState",
    })
  )
);

interface ISubModuleListState {
  subModuleList: SubModule[];
}

export const subModuleListState = create<ISubModuleListState>()(
  devtools(
    persist((set, get) => ({ subModuleList: [] }), {
      name: "subModuleListState",
    })
  )
);

interface IAddressListState {
  addressList: Address[];
}

export const addressListState = create<IAddressListState>()(
  devtools(
    persist((set, get) => ({ addressList: [] }), {
      name: "addressListState",
    })
  )
);

export const organizationOnboardingState = create<OrganizationOnboarding>()(
  devtools(
    persist((set, get) => emptyOrgOnboardingDetail, {
      name: "organizationOnboardingState",
    })
  )
);
