import { useEffect } from "react";
import { currentActiveScreenState } from "../../../libs/state-management-lib";
import LocationTemplate from "../../template/settings/locationTemplate";

const Location = () => {
  useEffect(() => {
    currentActiveScreenState.setState("/settings/organization");
  }, []);

  return <LocationTemplate />;
};

export default Location;
