import { SxProps, TextField, Theme, Typography } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import { ChangeEvent } from "react";

interface IPlatformTextField {
  value: string;
  handleChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  errorMessage?: string;
  placeholder: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  InputProps?: any;
}

export const PlatformTextField = (props: IPlatformTextField) => {
  return (
    <>
      <TextField
        size="small"
        sx={{
          ...props.sx,
          borderRadius: 8,
          border: !!props.errorMessage ? "red" : "#CFE5FC",

          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: !!props.errorMessage ? "red" : "#CFE5FC",
            },
            "&:hover fieldset": {
              borderColor: !!props.errorMessage ? "red" : "#0D80F2",
            },
            "&.Mui-focused fieldset": {
              borderColor: !!props.errorMessage ? "red" : "#0D80F2",
            },
            borderRadius: "8px",
          },
          "& .Mui-disabled": {
            background: "#F1F2F4 !important",
            "&:hover fieldset": {
              border: "1px solid #ABB2BA !important",
            },
          },

          input: {
            "&::placeholder": {
              textOverflow: "ellipsis !important",
              color: "#6EB2F7",
              opacity: "5",
            },
          },
        }}
        inputProps={{
          style: {
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#031A30",
            letterSpacing: "0.5px",
            backgroundColor: "#F5FAFE",
            borderRadius: "8px",
          },
        }}
        InputProps={{
          style: {
            backgroundColor: "#F5FAFE",
            borderRadius: "8px",
          },
        }}
        placeholder={props.placeholder}
        variant="outlined"
        value={props.value}
        onChange={props.handleChange}
        disabled={!!props.disabled && props.disabled ? true : false}
      />

      {!!props.errorMessage && (
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22.4px",
            color: "red",
          }}
          className="red-text"
        >
          {props.errorMessage}
        </Typography>
      )}
    </>
  );
};

export const PlatformLoginTextField = (props: IPlatformTextField) => {
  return (
    <>
      <TextField
        size="small"
        sx={{
          ...props.sx,
          borderRadius: 8,
          width: "100%",
          border: !!props.errorMessage ? "#F2460D" : "#F0F0F0",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: !!props.errorMessage ? "#F2460D" : "#F0F0F0",
              backgroundColor: " rgba(0, 0, 0, 0.06)",
            },
            "&:hover fieldset": {
              border: "1px solid #1F5DA5",
              borderRadius: "8px",
            },
          },
          ".Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #1F5DA5 !important",
            borderRadius: "8px",
          },
          input: {
            "&::placeholder": {
              color: " #00000099",
              opacity: "5",
            },
            "&:-webkit-autofill": {
              transitionDelay: "9999s",
              transitionProperty: "background-color, color",
            },
          },
        }}
        InputProps={{
          style: {
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#000000DE",
            letterSpacing: "0.15px",
            backgroundColor: "rgba(240,240,240,0)  !important",
            borderRadius: "8px",
          },
        }}
        disabled={props.disabled}
        placeholder={props.placeholder}
        variant="outlined"
        value={props.value}
        onChange={props.handleChange}
      />

      {!!props.errorMessage && (
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "20px",
            color: "#F2460D", 
            backgroundColor: "rgba(240,240,240,0) !important",
            alignItems: "flex-start",
          }}
          className="red-text"
        >
          {props.errorMessage}
        </Typography>
      )}
    </>
  );
};

interface IPhoneNumbertextField {
  label?: string;
  placeholder?: string;
  value: string;
  handleChange: (e: any) => void;
  errorMessage?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

export const PlatformPhoneNumberTextField = (props: IPhoneNumbertextField) => {
  return (
    <>
      <MuiPhoneNumber
        sx={{
          ...props.sx,
          borderRadius: 8,
          width: "100%",
          border: !!props.errorMessage ? "red" : "#F0F0F0",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: !!props.errorMessage ? "red" : "#F0F0F0",
              backgroundColor: " rgba(0, 0, 0, 0.06)",
            },
            "&:hover fieldset": {
              borderColor: !!props.errorMessage ? "red" : "#F0F0F0",
            },
            "&.Mui-focused fieldset": {
              borderColor: !!props.errorMessage ? "red" : "1px solid #1F5DA5",
            },
            "& .MuiPhoneNumber-flagButton": {
              padding: "0px !important",
            },
          },
          input: {
            "&::placeholder": {
              color: !!props.errorMessage ? "#F2460D" : " #00000099",
              opacity: "5",
            },
          },
        }}
        inputProps={{
          style: {
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#000000DE",
            letterSpacing: "0.15px",
            backgroundColor: "#F0F0F0",
            borderRadius: "8px",
          },
        }}
        InputProps={{
          style: {
            borderRadius: "8px",
          },
        }}
        fullWidth
        size="small"
        variant="outlined"
        defaultCountry={"us"}
        onlyCountries={["us"]}
        disabled={props.disabled}
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.handleChange}
      />
      {!!props.errorMessage && (
        <span style={{ fontSize: "10px", fontWeight: "400", color: "red" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "22.4px",
            }}
            className="red-text"
          >
            {props.errorMessage}
          </Typography>
        </span>
      )}
    </>
  );
};
