import { createContext, useContext } from "react";
import { OrganizationRole } from "../model-lib";

interface IRoleDetailContext {
  roleDetail: OrganizationRole;
  setRoleDetail: React.Dispatch<React.SetStateAction<OrganizationRole>>;
}

export const RoleDetailContext = createContext<IRoleDetailContext | undefined>(
  undefined
);

export const useRoleDetailContext = (): IRoleDetailContext => {
  const context = useContext(RoleDetailContext);
  if (context === undefined) {
    throw new Error("useRoleDetailContext must be used within a MyProvider");
  }
  return context;
};
