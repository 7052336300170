import { useEffect } from "react";
import { currentActiveScreenState } from "../../../libs/state-management-lib";
import { ProfileSettingsTemplate } from "../../template/profileSettings/profileSettingsTemplate";

export const ProfileSettings = () => {
  useEffect(() => {
    if (window.location.pathname.includes("/profilesettings")) {
      currentActiveScreenState.setState("/profilesettings/profile-details");
    } else {
      currentActiveScreenState.setState("/settings/organization");
    }
  }, []);

  return (
    <>
      <ProfileSettingsTemplate />
    </>
  );
};
