import {
  InputAdornment,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { ChangeEvent } from "react";
import SearchIcon from "@mui/icons-material/Search";

interface ISearchTextField {
  value: string;
  handleChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  errorMessage?: string;
  placeholder: string;
  sx?: SxProps<Theme>;
}

export const SearchTextField = (props: ISearchTextField) => {
  return (
    <>
      <TextField
        disabled
        size="small"
        sx={{
          ...props.sx,
          borderRadius: 8,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: !!props.errorMessage
                ? "1px solid red"
                : "1px solid #CFE5FC",
            },
            "&:hover fieldset": {
              "& .Mui-disabled": {
                borderColor: "#F1F2F4 !important",
              },
              borderColor: !!props.errorMessage ? "red" : "#CFE5FC",
            },
            "&.Mui-focused fieldset": {
              borderColor: !!props.errorMessage ? "red" : "#CFE5FC",
            },
          },
          "& .Mui-disabled": {
            background: "#F1F2F4 !important",
            "&:hover fieldset": {
              border: "1px solid #ABB2BA !important",
            },
          },
          "& .Mui-disabled .MuiSvgIcon-root": {
            color: "#ABB2BA !important",
          },
          input: {
            "&::placeholder": {
              textOverflow: "ellipsis !important",
              color: "#6EB2F7",
              opacity: "5",
            },
          },
        }}
        inputProps={{
          style: {
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#031A30",
            letterSpacing: "0.5px",
            backgroundColor: "#F5FAFE",
            borderRadius: "8px",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "#6EB2F7" }} />
            </InputAdornment>
          ),
          style: {
            backgroundColor: "#F5FAFE",
            borderRadius: "8px",
          },
        }}
        placeholder={props.placeholder}
        variant="outlined"
        value={props.value}
        onChange={props.handleChange}
      />

      {!!props.errorMessage && (
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22.4px",
            color: "red",
          }}
          className="red-text"
        >
          {props.errorMessage}
        </Typography>
      )}
    </>
  );
};
