import { useNavigate } from "react-router-dom";
import {
  OverFlowBox,
  SettingNavigationBox,
} from "../atom";
import { activeLocationTabState } from "../../libs/state-management-lib";

export interface IProfileSettingNavigation {
  selectedMenu: string;
}

export const ProfileSettingNavigation = (props: IProfileSettingNavigation) => {
  const navigate = useNavigate();

  const handleNavigation = (navigationMenu: string) => {
    activeLocationTabState.setState(0);
    navigate(navigationMenu);
  };

  return (
    <OverFlowBox
      sx={{
        width: "13%",
        minWidth:"224px",
        height: "calc(100% - 24px)",
        display: "flex",
        flexDirection: "column",
        padding: "12px 16px",
        gap: "4px",
        background: (theme) => theme.palette.secondary.main,
        borderRight: "1px solid #CFE6FC",
      }}
    >
      <SettingNavigationBox
        sx={{
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
          height: "48px",
        }}
        navigationText="Profile details"
        active={props.selectedMenu == "ProfileDetails"}
        handleNavigationClick={() => {
          handleNavigation("/profilesettings/profile-details");
        }}
      ></SettingNavigationBox>
      <SettingNavigationBox
        sx={{
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
          height: "48px",
        }}
        navigationText="Notifications and sound"
        active={props.selectedMenu == "NotificationsAndSound"}
        handleNavigationClick={() => {
          handleNavigation("/profilesettings/notifications-and-sound");
        }}
      ></SettingNavigationBox>
      <SettingNavigationBox
        sx={{
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
          height: "48px",
        }}
        navigationText="Language and time format"
        active={props.selectedMenu == "LanguageAndTimeFormat"}
        handleNavigationClick={() => {
          handleNavigation("/profilesettings/language-and-time-format");
        }}
      ></SettingNavigationBox>
      <SettingNavigationBox
        sx={{
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
          height: "48px",
        }}
        navigationText="Privacy and security"
        active={props.selectedMenu == "PrivacyAndSecurity"}
        handleNavigationClick={() => {
          handleNavigation("/profilesettings/privacy-and-security");
        }}
      ></SettingNavigationBox>
    </OverFlowBox>
  );
};
