import { create } from "zustand";
import { AgencyDetail } from "../model-lib/agencyDetail";
import { devtools, persist } from "zustand/middleware";
import { emptyAgencyDetail } from "../common-lib";

export const agencyDetailState = create<AgencyDetail>()(
  devtools(
    persist((set, get) => emptyAgencyDetail, { name: "agencyDetailState" })
  )
);
