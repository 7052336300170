import { Box } from "@mui/material";

export const Unauthorized = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        <img
          src="/images/common/401_unauthorized.jpg"
          alt="you are not access this page - unauthorized"
        />
      </Box>
    </>
  );
};
