import { Box, SxProps, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ReactNode } from "react";

interface IOverFlowBox {
  children: ReactNode;
  sx: SxProps<Theme>;
}

export const OverFlowBox = (props: IOverFlowBox) => {
  return (
    <Box
      sx={{
        ...props.sx,
        overflowY: "scroll",
        overflowX: "hidden",
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          width: "6px",
          height: "6px",
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          borderRadius: "32px",
          backgroundColor: grey["400"],
        },
        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
          {
            backgroundColor: grey["500"],
          },
        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
          {
            backgroundColor: grey["500"],
          },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
          {
            backgroundColor: grey["500"],
          },
        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
          backgroundColor: "#0000000",
        },
      }}
    >
      {props.children}
    </Box>
  );
};
