import { Box } from "@mui/material";
import { LocationDepartmentDetail, StaffDetail } from "../../../../libs/model-lib";

interface ICalendarAddShiftButtonBox {
  locationDepartmentDetail: LocationDepartmentDetail;
  shiftDate: Date;
  staffDetail?: StaffDetail;
  handleAddShiftOpen: (shiftOpen: boolean, locationDepartmentDetail: LocationDepartmentDetail, shiftDate: Date, staffDetail?: StaffDetail) => void;
}

export const CalendarEmptyAddShiftButtonBox = (props: ICalendarAddShiftButtonBox) => {
  
  const handleAddShiftClick = () => {
    props.handleAddShiftOpen(
      true,
      props.locationDepartmentDetail,
      props.shiftDate,
      props.staffDetail
    );
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          fontSize: "12px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#CFE5FC",
          cursor: "pointer",
          height: "100%",
          maxHeight: "96px",
          borderBottom: "1px solid #E7F2FE"
        }}
        onClick={handleAddShiftClick}
      >
        + Add shift
      </Box>
    </>
  );
};
