import axios from "axios";
import {
  addressListState,
  moduleListState,
  orgRoleListState,
  orgRolePermissionListState,
  orgRoleState,
  organizationDetailState,
  subModuleListState,
} from "../state-management-lib/organizationState";
import { axiosInstance, EmptyOrganizationDetail, handleApiErrorAlert, handleApiSuccessAlert } from "../common-lib";
import {
  OrgRole,
  OrgRolePermissionMapping,
  OrganizationDetail,
  OrganizationOnboarding,
} from "../model-lib";
import {
  apiAlertState,
  errorAlertState,
  errorMessageState,
  successAlertState,
} from "../state-management-lib";

const platform_base_url = `${process.env.REACT_APP_PLATFORM_URL}/api/Organization`;
const azure_map_url = `${process.env.REACT_APP_AZURE_MAP_URL}`;

export const getOrganizationDetailById = async (orgId: string) => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetOrganizationById?orgId=${orgId}`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      organizationDetailState.setState(response.data.data);
    }
  } catch (error) {}
};

export const getOrganizationByUserId = async (userId: string) => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetOrganizationByUserId?userId=${userId}`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      organizationDetailState.setState(response.data.data);
      return response.data.data;
    }

    return EmptyOrganizationDetail;
  } catch (error) {}
};

export const updateOrganization = async (
  organizationDetail: OrganizationDetail
) => {
  try {
    let response = await axiosInstance.post(
      `${platform_base_url}/UpdateOrganization`,
      organizationDetail
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      await getOrganizationDetailById(organizationDetail.id);
      handleApiSuccessAlert("Organization updated successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
  }
};

export const getOrganizationRoles = async (orgId: string) => {
  try {
    let response = await axiosInstance.get(
      `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/OrganizationRole/GetRoleByOrganizationId?organizationId=${orgId}`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      orgRoleListState.setState({ orgRoleList: response.data.data });
    }
  } catch (error) {}
};

export const getOrganizationRolePermissionMapping = async () => {
  try {
    let response = await axiosInstance.get(
      `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/OrgRole`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      orgRoleListState.setState({ orgRoleList: response.data.data });
    }
  } catch (error) {}
};

export const getModuleList = async () => {
  try {
    let response = await axiosInstance.get(
      `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/RolePermissionMapping/GetModules`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      moduleListState.setState({ moduleList: response.data.data });
    }
  } catch (error) {}
};

export const getSubModuleList = async () => {
  try {
    let response = await axiosInstance.get(
      `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/RolePermissionMapping/GetSubModulesById?moduleId=null`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      subModuleListState.setState({ subModuleList: response.data.data });
    }
  } catch (error) {}
};

export const getOrgPermissionListState = async (roleId: number) => {
  try {
    let response = await axiosInstance.get(
      `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/RolePermissionMapping/GetRolePermissionMappings?roleId=${roleId}`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      orgRolePermissionListState.setState({
        orgRolePermissionList: response.data.data,
      });
    }
  } catch (error) {}
};

export const createOrganizationRole = async (role: OrgRole) => {
  try {
    let response = await axiosInstance.post(
      `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/OrganizationRole/CreateRole`,
      role
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      orgRoleState.setState(response.data.data);
      handleApiSuccessAlert("Organization Role created successfully");
      return response.data.data;
    }

    return null;
  } catch (error) {
    handleApiErrorAlert(error);
  }
};

export const updateOrganizationRole = async (role: OrgRole) => {
  try {
    let response = await axiosInstance.post(
      `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/OrganizationRole/UpdateRole`,
      role
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Organization role updated successfully");
    }

    //return null;
  } catch (error) {
    handleApiErrorAlert(error);
  }
};

export const createRolePermissionMapping = async (
  rolePermissionMapping: OrgRolePermissionMapping[]
) => {
  try {
    let response = await axiosInstance.post(
      `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/RolePermissionMapping/CreateRolePermissionMapping`,
      rolePermissionMapping
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      // orgRoleState.setState(response.data.data);
      // return response.data.data;
    }

    // return null;
  } catch (error) {}
};

export const updateRolePermissionMapping = async (
  rolePermissionMapping: OrgRolePermissionMapping[]
) => {
  try {
    let response = await axiosInstance.post(
      `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/RolePermissionMapping/UpdateRolePermissionMapping`,
      rolePermissionMapping
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      // orgRoleState.setState(response.data.data);
      // return response.data.data;
    }

    // return null;
  } catch (error) {}
};

export const deleteOrgRole = async (role: OrgRole) => {
  try {
    let response = await axiosInstance.post(
      `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/OrganizationRole/DeleteRole`,
      role
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Organization role removed successfully");
    }
  } catch (error) {
    handleApiErrorAlert(error);
  }
};

export const getAddressValue = async (address: string) => {
  try {
    let response = await axios.get(
      `${azure_map_url}${address}&subscription-key=4JyFoaoCPn0ZfaqcbvKZ5XxmBB6HpFM2JiqNoPeTWeMbkGespDvMJQQJ99AGACYeBjFefErkAAAgAZMPHZaV`
    );
    let addressNameList: any[] = [];
    if (!!response && !!response.data) {
      let val = response.data.results;
      val.forEach((cc: any) => {
        addressNameList.push(cc.address);
      });
      addressListState.setState({ addressList: addressNameList });
    }
  } catch (error) {}
};

export const organizationOnboarding = async (
  orgOnboardingDetail: OrganizationOnboarding
) => {
  try {
    let response = await axiosInstance.post(
      `${platform_base_url}/OrganizationOnboarding`,
      orgOnboardingDetail
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Organization onboarding successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
    throw error;
  }
};
