import { useNavigate } from "react-router-dom";
import {
  LocationDepartmentIcon,
  OrganizationIcon,
  OverFlowBox,
  PermissionsIcon,
  RolesIcon,
  SettingNavigationBox,
  SettingNavigationBoxWithoutHover,
  StaffIcon,
  StaffingPartnersIcon,
  StaffPresetsIcon,
  UsersIcon,
} from "../atom";
import { activeLocationTabState } from "../../libs/state-management-lib";

export interface ISettingNavigation {
  selectedMenu: string;
}

export const SettingNavigation = (props: ISettingNavigation) => {
  const navigate = useNavigate();
  const iconStyle = {
    width: "20px",
    height: "20px",
    color: "primary.contrastText",
  };

  const handleNavigation = (navigationMenu: string) => {
    activeLocationTabState.setState(0);
    navigate(navigationMenu);
  };

  return (
    <OverFlowBox
      sx={{
        width: "calc(20% - 32px)",
        minWidth: "200px",
        height: "calc(100% - 24px)",
        display: "flex",
        flexDirection: "column",
        padding: "12px 16px",
        gap: "4px",
        background: (theme) => theme.palette.secondary.main,
        borderRight: "1px solid #CFE6FC",
      }}
    >
      <SettingNavigationBox
        navigationText="Organization"
        active={props.selectedMenu == "Organization"}
        handleNavigationClick={() => {
          handleNavigation("/settings/organization");
        }}
      >
        <OrganizationIcon sx={iconStyle} />
      </SettingNavigationBox>
      <SettingNavigationBox
        navigationText="Locations & Departments"
        active={props.selectedMenu == "Location"}
        handleNavigationClick={() => {
          handleNavigation("/settings/locations-departments");
        }}
      >
        <LocationDepartmentIcon sx={iconStyle} />
      </SettingNavigationBox>
      <SettingNavigationBox
        navigationText="Roles"
        active={props.selectedMenu == "Roles"}
        handleNavigationClick={() => {
          handleNavigation("/settings/roles");
        }}
      >
        <RolesIcon sx={iconStyle} />
      </SettingNavigationBox>
      <SettingNavigationBoxWithoutHover
        navigationText="Permissions"
        active={props.selectedMenu == "Permissions"}
        handleNavigationClick={() => {}}
      >
        <PermissionsIcon sx={iconStyle} />
      </SettingNavigationBoxWithoutHover>
      <SettingNavigationBox
        navigationText="Talents"
        active={props.selectedMenu == "Talents"}
        handleNavigationClick={() => {
          handleNavigation("/settings/talents");
        }}
      >
        <StaffIcon sx={iconStyle} />
      </SettingNavigationBox>
      <SettingNavigationBoxWithoutHover
        navigationText="Shift presets"
        active={props.selectedMenu == "Shift presets"}
        handleNavigationClick={() => {}}
      >
        <StaffPresetsIcon sx={iconStyle} />
      </SettingNavigationBoxWithoutHover>
      <SettingNavigationBoxWithoutHover
        navigationText="Users"
        active={props.selectedMenu == "Users"}
        handleNavigationClick={() => {}}
      >
        <UsersIcon sx={iconStyle} />
      </SettingNavigationBoxWithoutHover>
      <SettingNavigationBox
        navigationText="Staffing partners"
        active={props.selectedMenu == "StaffingPartner"}
        handleNavigationClick={() => {
          handleNavigation("/settings/staffingpartners");
        }}
      >
        <StaffingPartnersIcon sx={iconStyle} />
      </SettingNavigationBox>
    </OverFlowBox>
  );
};
