import { Box, MenuItem } from "@mui/material";
import {
  DropdownWithBorder,
  FormContainerBox,
  FormPlacementBox,
  LableTextFieldBox,
  MultiSelectDropdown,
  PlatformTextField,
  TextFieldPlacementBox,
  Typography12_600,
  Typography14_400,
  Typography16_400,
  Typography18_600,
} from "../../atom";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  departmentListState,
  locationDetailListState,
  orgDepartmentListState,
  orgUnitDetailListState,
  primaryQualificationListState,
  roleDetailState,
} from "../../../libs/state-management-lib";
import {
  DepartmentDetail,
  GenericMasterData,
  LocationDetail,
  OrganizationRole,
  UnitDetail,
} from "../../../libs/model-lib";
import {
  getAllDepartmentsByLocationId,
  getSubDepartmentByDepartmentId,
  getSubDepartmentByLocationId,
} from "../../../libs/services-lib";
import {
  EmptyGuid,
  emptyRoleDetail,
  getErrorDetail,
} from "../../../libs/common-lib";
import { useRoleDetailContext } from "../../../libs/context-lib/roleDetailContext";

interface IRolesDetailFoem {
  errorMessage: any[];
}

export const RolesDetailFoem = (props: IRolesDetailFoem) => {
  const [locDetailList, setLocDetailList] = useState<LocationDetail[]>(
    locationDetailListState.getState().locationDetailList
  );

  const [departmentList, setDepartmentList] = useState<DepartmentDetail[]>(
    orgDepartmentListState.getState().departmentDetailList
  );

  const [qualificationList, setQualificationList] = useState<any[]>(
    primaryQualificationListState.getState().primaryQualificationList
  );

  const [unitList, setUnitList] = useState<UnitDetail[]>(
    orgUnitDetailListState.getState().orgUnitDetailList
  );

  const { roleDetail, setRoleDetail } = useRoleDetailContext();

  const handleLocationChange = async (event: any) => {
    setRoleDetail({
      ...roleDetail,
      location_id: event.target.value,
    });

    if (!!event.target.value && event.target.value.length > 0) {
      let deptList = orgDepartmentListState
        .getState()
        .departmentDetailList.filter((cc: DepartmentDetail) =>
          event.target.value.find((dd: string) => dd == cc.location_Id)
        );

      setDepartmentList(deptList);
    }
  };

  const handleLocationRemove = (e: any) => {
    let locationList = !!roleDetail.location_id
      ? roleDetail.location_id.filter((cc) => cc != e)
      : [];
    setRoleDetail({ ...roleDetail, location_id: locationList });

    let deptList = orgDepartmentListState
      .getState()
      .departmentDetailList.filter((cc: DepartmentDetail) =>
        locationList
          .filter((location: string) => location != e)
          .find((dd: string) => dd == cc.location_Id)
      );

    let selectedDepartment: string[] = [];

    roleDetail.department_id?.forEach((element) => {
      if (deptList.find((cc) => cc.id == element)) {
        selectedDepartment.push(element);
      }
    });

    let selectedSubDepartment: string[] = [];

    let subDeptList = orgUnitDetailListState
      .getState()
      .orgUnitDetailList.filter((cc: UnitDetail) =>
        selectedDepartment.find((dd: string) => dd == cc.department_id)
      );

    roleDetail.sub_department_id?.forEach((element) => {
      if (subDeptList.find((cc) => cc.id == element)) {
        selectedSubDepartment.push(element);
      }
    });

    setDepartmentList(deptList);
    setRoleDetail({
      ...roleDetail,
      location_id: locationList,
      department_id: selectedDepartment,
      sub_department_id: selectedSubDepartment,
    });
    setUnitList(subDeptList);
  };

  const handleDepartmentChange = async (event: any) => {
    setRoleDetail({ ...roleDetail, department_id: event.target.value });

    if (!!event.target.value && event.target.value.length > 0) {
      let subDeptList = orgUnitDetailListState
        .getState()
        .orgUnitDetailList.filter((cc: UnitDetail) =>
          event.target.value.find((dd: string) => dd == cc.department_id)
        );

      setUnitList(subDeptList);
    }
  };

  const handleDepartmentRemove = (e: any) => {
    let depaermentList = !!roleDetail.department_id
      ? roleDetail.department_id.filter((cc) => cc != e)
      : [];

    let selectedSubDepartment: string[] = [];

    let subDeptList = orgUnitDetailListState
      .getState()
      .orgUnitDetailList.filter((cc: UnitDetail) =>
        depaermentList.find((dd: string) => dd == cc.department_id)
      );

    roleDetail.sub_department_id?.forEach((element) => {
      if (subDeptList.find((cc) => cc.id == element)) {
        selectedSubDepartment.push(element);
      }
    });

    setRoleDetail({
      ...roleDetail,
      department_id: depaermentList,
      sub_department_id: selectedSubDepartment,
    });
    setUnitList(subDeptList);
  };

  const handleSubDepartmentChange = (event: any) => {
    setRoleDetail({ ...roleDetail, sub_department_id: event.target.value });
  };

  const handleSubDepartmentRemove = (e: any) => {
    let subDepaermentList = !!roleDetail.sub_department_id
      ? roleDetail.sub_department_id.filter((cc) => cc != e)
      : [];
    setRoleDetail({ ...roleDetail, sub_department_id: subDepaermentList });
  };

  const handleQualificationChange = (event: any) => {
    let value = [];
    value.push({
      qualification_id: event.target.value,
      is_primary: true,
    });
    setRoleDetail({ ...roleDetail, qualifications: value });
  };

  const handleQualificationClear = () => {
    setRoleDetail({ ...roleDetail, qualifications: [] });
  };

  return (
    <>
      <FormPlacementBox sx={{ height: "580px" }}>
        <FormContainerBox>
          <Box sx={{ width: "100%", gap: "10px" }}>
            <Typography18_600 text="Role details" color="#17191C" />
          </Box>

          <TextFieldPlacementBox>
            <Box sx={{ width: "33%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Role name" color="#737F8C" required />
                <PlatformTextField
                  placeholder={"Enter role name"}
                  value={roleDetail.role_name}
                  handleChange={(e: any) => {
                    setRoleDetail({
                      ...roleDetail,
                      role_name: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("role_name", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "33%" }}>
              <LableTextFieldBox>
                <Typography12_600
                  text="Abbreviation"
                  color="#737F8C"
                  required
                />
                <PlatformTextField
                  placeholder={"Enter abbreviation name"}
                  value={roleDetail.abbreviation}
                  handleChange={(e: any) => {
                    setRoleDetail({
                      ...roleDetail,
                      abbreviation: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail(
                    "abbreviation",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
              <Typography14_400 text="5 character limit" color="#737F8C" />
            </Box>
            <Box sx={{ width: "33%" }}>
              <LableTextFieldBox>
                <Typography12_600
                  text="Primary qualification"
                  color="#737F8C"
                  required
                />
                <DropdownWithBorder
                  value={
                    !!roleDetail.qualifications &&
                    roleDetail.qualifications.length > 0 &&
                    !!roleDetail.qualifications[0].qualification_id
                      ? roleDetail.qualifications[0]?.qualification_id
                      : 0
                  }
                  handleChange={handleQualificationChange}
                  handleClear={handleQualificationClear}
                  errorMessage={getErrorDetail(
                    "qualifications",
                    props.errorMessage
                  )}
                >
                  <MenuItem value={0} disabled>
                    Select primary qualification
                  </MenuItem>
                  {qualificationList.map((cc) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      <Typography16_400 text={cc.name} color="#031A30" />
                    </MenuItem>
                  ))}
                </DropdownWithBorder>
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
        <FormContainerBox>
          <Box sx={{ width: "100%", gap: "10px" }}>
            <Typography18_600 text="Location details" color="#17191C" />
          </Box>

          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Locations" color="#737F8C" />
                <MultiSelectDropdown
                  placeholder="Select locations"
                  handleChange={handleLocationChange}
                  value={!!roleDetail.location_id ? roleDetail.location_id : []}
                  label={""}
                  listItems={locDetailList}
                  handleRemove={handleLocationRemove}
                  labelValue="name"
                >
                  <MenuItem value={0} disabled>
                    Select locations
                  </MenuItem>
                  {locDetailList?.map((cc) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      <Typography16_400 text={cc.name} color="#031A30" />
                    </MenuItem>
                  ))}
                </MultiSelectDropdown>
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Departments" color="#737F8C" />
                <MultiSelectDropdown
                  placeholder="Select departments"
                  handleChange={handleDepartmentChange}
                  value={
                    !!roleDetail.department_id ? roleDetail.department_id : []
                  }
                  label={""}
                  listItems={departmentList}
                  handleRemove={handleDepartmentRemove}
                  labelValue="name"
                >
                  <MenuItem value={0} disabled>
                    Select departments
                  </MenuItem>
                  {departmentList?.map((cc) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      <Typography16_400 text={cc.name} color="#031A30" />
                    </MenuItem>
                  ))}
                </MultiSelectDropdown>
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Sub-departments" color="#737F8C" />
                <MultiSelectDropdown
                  placeholder="Select sub-departments"
                  handleChange={handleSubDepartmentChange}
                  value={
                    !!roleDetail.sub_department_id
                      ? roleDetail.sub_department_id
                      : []
                  }
                  label={""}
                  listItems={unitList}
                  handleRemove={handleSubDepartmentRemove}
                  labelValue="name"
                >
                  <MenuItem value={0} disabled>
                    Select sub-departments
                  </MenuItem>
                  {unitList?.map((cc: UnitDetail) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      <Typography16_400 text={cc.name} color="#031A30" />
                    </MenuItem>
                  ))}
                </MultiSelectDropdown>
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
      </FormPlacementBox>
    </>
  );
};
