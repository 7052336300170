import { AgencyDetail } from "./agencyDetail";

export interface StaffDetail {
  id: string;
  org_id: string;
  agency_id: string;
  first_name: string;
  last_name: string;
  mobile: string;
  email: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zipcode: string | null;
  ssn: string;
  npi: string;
  date_of_birth: string | null;
  hourly_pay_rate: number;
  overtime_pay_rate: number;
  contracted_hours: number;
  staff_type: number;
  role_ids: number[];
  is_active: boolean;
  created_on: Date;
  staffLocationMappings: StaffLocationMappings[];
  staffWorkingHours: number | null;
  agencyDetails: AgencyDetail | null;
}

export interface StaffLocationMappings {
  staff_id: string;
  location_id: string;
  department_id: string | null;
  sub_department_id: string | null;
  is_primary_location: boolean;
}

export interface StaffWorkingHours {
  staff_id: string;
  working_hour: number;
}

export interface SearchStaffCriteria {
  PageSize: number;
  PageNumber: number;
  isPaginationEnabled: boolean;
  org_id: string;
  SortOptions: StaffSortingRequest[];
  Filters: StaffFilterRequest[];
}

export interface StaffSortingRequest {
  ColumnName: StaffSortingColumnEnum;
  SortValue?: StaffSortDirectionEnum;
}

export interface StaffFilterRequest {
  ColumnName: StaffFilterColumnEnum;
  FilterValue?: String;
  FilterType?: StaffFilterOperatorEnum;
}

export enum StaffSortingColumnEnum {
  FirstName = 0,
  LastName = 1,
  mobile = 2,
  email = 3,
  ssn = 4,
  npi = 5,
  StaffType = 6,
}

export enum StaffFilterColumnEnum {
  LocationId = 0,
  DepartmentId = 1,
  SubDepartmentId = 2,
  FirstName = 3,
  LastName = 4,
  mobile = 5,
  email = 6,
  ssn = 7,
  npi = 8,
  StaffType = 9,
  RoleId = 10,
  IsActive = 11,
}

export enum StaffSortDirectionEnum {
  Asc = 0,
  Desc = 1,
}

export enum StaffFilterOperatorEnum {
  Contains = 0,
  Equal = 1,
  In = 2,
}
