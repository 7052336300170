import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { ConfirmationTitle, LocationDetail } from "../model-lib";

interface ILocationDetailListState {
  locationDetailList: LocationDetail[];
}

export const locationDetailListState = create<ILocationDetailListState>()(
  devtools(
    persist((set, get) => ({ locationDetailList: [] }), {
      name: "locationDetailListState",
    })
  )
);

export const locationDetailState = create<LocationDetail>()(
  devtools(
    persist(
      (set, get) => ({
        id: "",
        name: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        postal_code: "",
        location_type: 0,
        org_id: "",
        is_active: true,
        timezone: "",
        week_start_day: 0,
        locationContactDetails: {
          id: "",
          email: "",
          is_active: true,
          location_id: "",
          name: "",
          phone_number: "",
          title: "",
        },
      }),
      {
        name: "locationDetailState",
      }
    )
  )
);

export const confirmationPopupTitleState = create<ConfirmationTitle>()(
  devtools(
    persist((set, get) => ({ title: "" }), {
      name: "confirmationPopupTitleState",
    })
  )
);
