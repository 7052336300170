import { axiosInstance, handleApiErrorAlert, handleApiSuccessAlert } from "../common-lib";
import { AgencyDetail } from "../model-lib/agencyDetail";
import {
  apiAlertState,
  errorAlertState,
  errorMessageState,
  successAlertState,
} from "../state-management-lib";

const platform_base_url = `${process.env.REACT_APP_PLATFORM_URL}api/Agency`;

export const agencyOnboarding = async (agencyDetail: AgencyDetail) => {
  try {
    let response = await axiosInstance.post(
      `${platform_base_url}/AgencyOnboarding`,
      agencyDetail
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Agency onboarded successfully");
      return response.data.data;
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
  }
};
