import axios from "axios";
import { axiosInstance, handleApiErrorAlert } from "../common-lib";
import {
  accessTokenState,
  errorAlertState,
  errorMessageState,
  userDetailstate,
} from "../state-management-lib";
import { UserDetail } from "../model-lib";

const user_base_url = `${process.env.REACT_APP_PLATFORM_URL}api/user`;
const auth_base_url = `${process.env.REACT_APP_PLATFORM_URL}api/auth`;

export const getUserDetail = async () => {
  try {
    let response = await axiosInstance.get(`${user_base_url}/getUserDetail`);

    if (!!response && !!response.data && response.status == 200) {
      userDetailstate.setState(response.data);
      return response.data;
    }

    return null;
  } catch (error) {}
};

export const generateUserOTP = async (signInDetails: any) => {
  try {
    let response = await axios.post(
      `${auth_base_url}/generateUserOTP`,
      signInDetails
    );

    if (!!response) {
    }
  } catch (error) {}
};

export const generateToken = async (signInDetails: any) => {
  try {
    let response = await axios.post(
      `${auth_base_url}/generateToken`,
      signInDetails
    );

    if (!!response && !!response.data) {
      accessTokenState.setState({ token: response.data });
    }
  } catch (error) {}
};

export const createUser = async (userDetail: UserDetail) => {
  try {
    let response = await axios.post(`${user_base_url}/createUser`, userDetail);

    if (!!response && !!response.data && response.data.statusCode == 200) {
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
    throw error;
  }
};
