import { createContext, useContext } from "react";
import { UnitDetail } from "../model-lib";

interface IUnitDetailContext {
  unitDetail: UnitDetail;
  setUnitDetail: React.Dispatch<React.SetStateAction<UnitDetail>>;
}

export const UnitDetailContext = createContext<IUnitDetailContext | undefined>(
  undefined
);

export const useUnitDetailContext = (): IUnitDetailContext => {
  const context = useContext(UnitDetailContext);
  if (context === undefined) {
    throw new Error("useUnitDetailContext must be used within a MyProvider");
  }
  return context;
};
