import { SearchShift, ShareOpenShifts, ShareShift, Shift, ShiftRotation, ShiftSeries } from "../model-lib";
import { createContext, useContext } from "react";

interface IShareShiftDetailContext {
  shareShift: ShareShift;
  setShareShift: React.Dispatch<React.SetStateAction<ShareShift>>;
}

export const ShareShiftDetailContext = createContext<
  IShareShiftDetailContext | undefined
>(undefined);

export const useShareShiftDetailContext = (): IShareShiftDetailContext => {
  const context = useContext(ShareShiftDetailContext);
  if (context === undefined) {
    throw new Error(
      "useShareShiftDetailContext must be used within a MyProvider"
    );
  }
  return context;
};

interface IShiftDetailContext {
  shiftDetail: Shift;
  setShiftDetail: React.Dispatch<React.SetStateAction<Shift>>;
}

export const ShiftDetailContext = createContext<
  IShiftDetailContext | undefined
>(undefined);

export const useShiftDetailContext = (): IShiftDetailContext => {
  const context = useContext(ShiftDetailContext);
  if (context === undefined) {
    throw new Error("useShiftDetailContext must be used within a MyProvider");
  }
  return context;
};

interface IShiftRotationContext {
  shiftRotation: ShiftRotation;
  setShiftRotation: React.Dispatch<React.SetStateAction<ShiftRotation>>;
}

export const ShiftRoationContext = createContext<
IShiftRotationContext | undefined
>(undefined);

export const useShiftRotationContext = (): IShiftRotationContext => {
  const context = useContext(ShiftRoationContext);
  if (context === undefined) {
    throw new Error("useShiftRotationContext must be used within a MyProvider");
  }
  return context;
};

interface IShareOpenShiftsContext {
  shareOpenShifts: ShareOpenShifts;
  setShareOpenShifts: React.Dispatch<React.SetStateAction<ShareOpenShifts>>;
}


export const ShareOpenShiftsContext = createContext<
IShareOpenShiftsContext | undefined
>(undefined);

export const useShareOpenShiftsContext = (): IShareOpenShiftsContext => {
  const context = useContext(ShareOpenShiftsContext);
  if (context === undefined) {
    throw new Error("useShareOpenShiftsContext must be used within a MyProvider");
  }
  return context;
};


interface ISearchShiftContext {
  searchShift: SearchShift;
  setSearchShift: React.Dispatch<React.SetStateAction<SearchShift>>;
  allShifts: Shift[];
  setAllShifts: React.Dispatch<React.SetStateAction<Shift[]>>;
  dateRange: Date[];
  setDateRange: React.Dispatch<React.SetStateAction<Date[]>>;
}

export const SearchShiftContext = createContext<ISearchShiftContext | undefined>(undefined);

export const useSearchShiftContext = (): ISearchShiftContext => {
  const context = useContext(SearchShiftContext);

  if (context === undefined) {
    throw new Error(
      "useSearchShiftContext must be used within a MyProvider"
    );
  }

  return context;
}

interface IShiftSeriesContext {
  shiftSeries: ShiftSeries;
  setShiftSeries: React.Dispatch<React.SetStateAction<ShiftSeries>>;
}

export const ShiftSeriesContext = createContext<IShiftSeriesContext | undefined>(undefined);

export const useShiftSeriesContext = (): IShiftSeriesContext => {
  const context = useContext(ShiftSeriesContext);

  if (context === undefined) {
    throw new Error("useShiftSeriesContext must be used within a MyProvider");
  }

  return context;
}

