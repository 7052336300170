import { Button, SxProps, Theme } from "@mui/material";

interface IOutlinedButton {
  text: string;
  sx: SxProps<Theme>;
  disabled?: boolean;
  handleClick: () => void;
  startIcon?:any;
}

export const OutlinedButton = (props: IOutlinedButton) => {
  return (
    <>
      <Button
      disabled = {props.disabled}
        size="small"
        variant="outlined"
        startIcon={props.startIcon}
        sx={{
          ...props.sx,
          border: !!props.disabled && props.disabled ? "0px !important" : "1px solid #0D80F2 !important",
          background: !!props.disabled && props.disabled ? "#ABB2BA !important" : "#FFFFFF !important",
          borderRadius: "8px",
          textTransform: "none",
          color: !!props.disabled && props.disabled ? "#FFFFFF !important" : "#0D80F2 !important",
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
        }}
        onClick={props.handleClick}
      >
        {props.text}
      </Button>
    </>
  );
};
