import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { NavigationBar } from "../organism";
import {
  ErrorAlert,
  GiftBoxIcon,
  OverFlowBox,
  Typography14_600,
  Typography18_600,
} from "../atom";
import { SettingsTemplate } from "./settingsTemplate";
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  apiAlertState,
  currentActiveScreenState,
  errorAlertState,
  errorMessageState,
  loadingIconState,
  organizationDetailState,
} from "../../libs/state-management-lib";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ApiAlert } from "../../libs/model-lib/apiAlert";
import { OrganizationDetail } from "../../libs/model-lib";

interface ILayoutTemplate {
  children: ReactNode;
}

export const LayoutTemplate = (props: ILayoutTemplate) => {
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [showLayoutHeader, setShowLayoutHeader] = useState<boolean>(true);
  const [orgnizationName, setOrgnizationName] = useState(
    organizationDetailState.getState()
  );

  const [showNavigationBar, setShowNavigationBar] = useState<boolean>(false);
  const [apiAlert, setApiAlert] = useState<ApiAlert>({
    alertMessage: "",
    serverity: "success",
    showAlert: false,
  });

  const [profileSettingScreen, setProfileSettingScreen] =
    useState<boolean>(false);

  useLayoutEffect(() => {
    const loadIconStateSub = loadingIconState.subscribe(async (state) => {
      setShowLoadingIcon(state);
    });

    const organizationNameStateSub = organizationDetailState.subscribe(
      async (state) => {
        setOrgnizationName(state);
      }
    );

    const currentActiveScreenStateSub = currentActiveScreenState.subscribe(
      async (state) => {
        if (!!state) {
          if (
            state == "/schedule" ||
            state == "/signin" ||
            state == "/SignUp" ||
            state == "/unauthorized" ||
            state == "/pagenotfound"
          ) {
            setShowLayoutHeader(false);
          } else {
            setShowLayoutHeader(true);
          }

          if (state == "/signin" || state == "/SignUp") {
            setShowNavigationBar(false);
          } else {
            setShowNavigationBar(true);
          }

          if (
            state == "/profilesettings/profile-details" ||
            state == "/profilesettings/notifications-and-sound" ||
            state == "/profilesettings/language-and-time-format" ||
            state == "/profilesettings/privacy-and-security"
          ) {
            setProfileSettingScreen(true);
          } else {
            setProfileSettingScreen(false);
          }
        } else {
          setShowLayoutHeader(true);
          setShowNavigationBar(true);
        }
      }
    );

    const apiAlertStateSub = apiAlertState.subscribe(async (state) => {
      setApiAlert(state);
    });

    return () => {
      loadIconStateSub();
      currentActiveScreenStateSub();
      organizationNameStateSub();
      apiAlertStateSub();
    };
  }, []);

  return (
    <>
      {showLoadingIcon && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingIconState.getState()}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {showNavigationBar && (
          <OverFlowBox
            sx={{
              width: "7%",
              minWidth: "100px",
              height: "calc(100% - 48px)",
              display: "flex",
              flexDirection: "column",
              background: (theme) => theme.palette.secondary.main,
              padding: "24px 16px 24px 16px",
              borderRight: "1px solid #CFE6FC",
            }}
          >
            <NavigationBar />
          </OverFlowBox>
        )}

        <Box
          sx={{
            width: showNavigationBar ? "93%" : "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column"
          }}
        >
          {showLayoutHeader && (
            <Box
              sx={{
                height: "7%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "16px",
                gap: "208px",
                borderBottom: "1px solid #CFE6FC",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  gap: "10px",
                }}
              >
                {profileSettingScreen == false && (
                  <Typography18_600
                    text={orgnizationName.name}
                    color="#031A30"
                  />
                )}
                {profileSettingScreen == true && (
                  <Typography18_600 text={"Profile settings"} color="#031A30" />
                )}
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "32px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    padding: "16px 24px",
                    gap: "10px",
                    background:
                      "linear-gradient(269.92deg, #6ADEB4 8.53%, #1FA7F8 119.54%)",
                    borderRadius: "50px",
                  }}
                >
                  <AccessTimeIcon fontSize="small" sx={{ color: "#FFFFFF" }} />
                  <Typography14_600
                    text="Trial ends in 7 days"
                    color="#FFFFFF"
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: "8px",
                    gap: "10px",
                  }}
                >
                  <GiftBoxIcon sx={{ color: " #0D80F2" }} />
                </Box>
              </Box> */}
            </Box>
          )}

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: showLayoutHeader ? "93%" : "100%",
            }}
          >
            {props.children}
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={apiAlert.showAlert}
        autoHideDuration={6000}
        onClose={() => {
          let alert = { ...apiAlert };
          alert.showAlert = false;
          setApiAlert({ ...apiAlert, showAlert: false });
          apiAlertState.setState(alert);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => {
            let alert = { ...apiAlert };
            alert.showAlert = false;
            setApiAlert({ ...apiAlert, showAlert: false });
            apiAlertState.setState(alert);
          }}
          severity={apiAlert.serverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {apiAlert.alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
