import { Box, MenuItem } from "@mui/material";
import {
  ContainedButton,
  DropdownWithBorder,
  OutlinedButton,
  Typography12_600,
  Typography16_400,
  Typography18_600,
} from "../../atom";

export const LangugaeTimeFormatSetting = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "calc(100%-48px)",
            height: "91% ",
            display: "flex",
            flexDirection: "column",
            padding: "24px 24px 0px 24px",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              width: "42%",
              minWidth:"598px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px",
              padding: "16px",
              gap: "16px",
              border: "1px solid #F1F2F4",
            }}
          >
            <Typography18_600 text={"Language"} color="#17191C" />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <Typography12_600 text="Interface language" color="#737F8C" />
              <DropdownWithBorder value={1} handleChange={(e: any) => {}}>
                <MenuItem value={1}>
                  <Typography16_400 text={"English"} color="#031A30" />
                </MenuItem>
              </DropdownWithBorder>
            </Box>
          </Box>
          <Box
            sx={{
              width: "42%",
              minWidth:"598px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px",
              padding: "16px",
              gap: "16px",
              border: "1px solid #F1F2F4",
            }}
          >
            <Typography18_600 text={"Time format"} color="#17191C" />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                  }}
                >
                  <Typography12_600 text="Time zone" color="#737F8C" />
                  <DropdownWithBorder value={1} handleChange={(e: any) => {}}>
                    <MenuItem value={1}>
                      <Typography16_400
                        text={"(EST) - UTC-5"}
                        color="#031A30"
                      />
                    </MenuItem>
                  </DropdownWithBorder>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "50%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                  }}
                >
                  <Typography12_600 text="Start of the week" color="#737F8C" />
                  <DropdownWithBorder value={1} handleChange={(e: any) => {}}>
                    <MenuItem value={1}>
                      <Typography16_400 text={"Sunday"} color="#031A30" />
                    </MenuItem>
                  </DropdownWithBorder>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                  }}
                >
                  <Typography12_600 text="Date format" color="#737F8C" />
                  <DropdownWithBorder value={1} handleChange={(e: any) => {}}>
                    <MenuItem value={1}>
                      <Typography16_400 text={"mm/dd/yyyy"} color="#031A30" />
                    </MenuItem>
                  </DropdownWithBorder>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "50%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                  }}
                >
                  <Typography12_600 text="Time format" color="#737F8C" />
                  <DropdownWithBorder value={1} handleChange={(e: any) => {}}>
                    <MenuItem value={1}>
                      <Typography16_400 text={"12 hours"} color="#031A30" />
                    </MenuItem>
                  </DropdownWithBorder>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "calc(100%-44px)",
            height: "9%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0px 16px 0px 28px",
            gap: "16px",
            borderTop: "1px solid #CFE5FC",
          }}
        >
          <OutlinedButton
            text={"Cancel"}
            sx={{ height: "40px",  width:"82px" }}
            handleClick={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <ContainedButton
            text={"Save changes"}
            sx={{ height: "40px", padding: "0px 16px", minWidth:"132px" }}
            handleClick={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </Box>
      </Box>
    </>
  );
};
