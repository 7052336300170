import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { UnitDetail, UnitDetailList } from "../model-lib";

export const unitDetailState = create<UnitDetail>()(
  devtools(
    persist(
      (set, get) => ({
        id: "",
        name: "",
        department_id: "",
        is_active: true,
      }),
      { name: "unitDetailState" }
    )
  )
);

interface IUnitListState {
  unitDetailList: UnitDetailList[];
}

export const unitListState = create<IUnitListState>()(
  devtools(
    persist((set, get) => ({ unitDetailList: [] }), {
      name: "unitListState",
    })
  )
);

interface IOrgUnitDetailListState {
  orgUnitDetailList: UnitDetail[];
}

export const orgUnitDetailListState = create<IOrgUnitDetailListState>()(
  devtools(
    persist((set, get) => ({ orgUnitDetailList: [] }), {
      name: "orgUnitDetailListState",
    })
  )
);

