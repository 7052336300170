import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  RadioGroup,
} from "@mui/material";
import {
  DropdownWithBorder,
  FormContainerBox,
  LableTextFieldBox,
  MultiSelectDropdown,
  PlatformTextField,
  TextFieldPlacementBox,
  Typography12_600,
  Typography14_400,
  Typography14_600,
  Typography16_400,
  Typography18_600,
} from "../../atom";
import { DepartmentDetail, OrgRole } from "../../../libs/model-lib";
import {
  departmentListState,
  loadingIconState,
  locationDetailListState,
  orgRoleListState,
  roleTypeListState,
} from "../../../libs/state-management-lib";
import { staffDetailState } from "../../../libs/state-management-lib/staffState";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  EmptyGuid,
  emptyStaffDetail,
  getErrorDetail,
} from "../../../libs/common-lib";
import { useShallow } from "zustand/react/shallow";
import {
  getAllDepartmentsByLocationId,
  getSubDepartmentByDepartmentId,
} from "../../../libs/services-lib";
import { useStaffDetailContext } from "../../../libs/context-lib/staffDetailContext";
import StaffDetail from "../../page/settings/staffDetail";

interface IStaffInfo {
  errorMessage: any[];
}
export const StaffInfo = (props: IStaffInfo) => {
  const {
    staffDetail,
    setStaffDetail,
    primaryLocationId,
    setPrimaryLocationId,
    primaryDepartmentId,
    setPrimaryDepartmentId,
    primaryUnitId,
    setPrimaryUnitId,
    otherLocationId,
    setOtherLocationId,
    otherDepartmentId,
    setOtherDepartmentId,
    otherUnitId,
    setOtherUnitId,
  } = useStaffDetailContext();
  const [roleTypeIds, setRoleTypeIds] = useState<any[]>([]);
  const [orgRoleList, setOrgRoleList] = useState<OrgRole[]>(
    orgRoleListState.getState().orgRoleList
  );
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const [primaryDepartmentList, setPrimaryDepartmentList] = useState<
    DepartmentDetail[]
  >([]);
  const [otherDepartmentList, setOtherDepartmentList] = useState<
    DepartmentDetail[]
  >([]);

  const [primaryUnitList, setPrimaryUnitList] = useState<DepartmentDetail[]>(
    []
  );
  const [otherUnitList, setOtherUnitList] = useState<DepartmentDetail[]>([]);

  useEffect(() => {
    let sd = staffDetailState.getState();
    sd.role_ids = staffDetail.role_ids;
    sd.npi = staffDetail.npi;
    sd.ssn = staffDetail.ssn;
    sd.staffLocationMappings = staffDetail.staffLocationMappings;

    staffDetailState.setState(sd);
    if (initialLoad && staffDetail.id != EmptyGuid) {
      handleStaffLocationDropdown();
      setRoleTypeIds(staffDetail.role_ids);
      setInitialLoad(!initialLoad);
    }
  }, [staffDetail]);

  useEffect(() => {
    handleStaffLocationChange();
  }, [
    primaryLocationId,
    primaryDepartmentId,
    primaryUnitId,
    otherLocationId,
    otherDepartmentId,
    otherUnitId,
  ]);

  const handleRoleTypeChange = (e: any) => {
    setStaffDetail({ ...staffDetail, role_ids: e.target.value });
    setRoleTypeIds(e.target.value);
  };
  const handleRoleTypeRemove = (e: any) => {
    let roleList = staffDetail.role_ids.filter((cc) => cc != e);
    setStaffDetail({ ...staffDetail, role_ids: roleList });
  };

  const handlePrimaryLocationChange = async (event: any) => {
    loadingIconState.setState(true);
    setPrimaryLocationId(event.target.value);

    if (!!event.target.value) {
      let departList = await getAllDepartmentsByLocationId(event.target.value);
      setPrimaryDepartmentList(departList);
    } else {
      setPrimaryLocationId("");
      setPrimaryDepartmentList([]);
    }

    setPrimaryDepartmentId("");
    setPrimaryUnitId("");
    loadingIconState.setState(false);
  };

  const handleOtherLocationChange = async (event: any) => {
    loadingIconState.setState(true);
    setOtherLocationId(event.target.value);

    if (!!event.target.value) {
      let departList = await getAllDepartmentsByLocationId(event.target.value);
      setOtherDepartmentList(departList);
    } else {
      setOtherLocationId("");
      setOtherDepartmentList([]);
    }

    setOtherDepartmentId("");
    setOtherUnitId("");
    loadingIconState.setState(false);
  };

  const handlePrimaryDepartmentChange = async (event: any) => {
    loadingIconState.setState(true);
    setPrimaryDepartmentId(event.target.value);

    if (!!event.target.value) {
      let unitList = await getSubDepartmentByDepartmentId(event.target.value);
      setPrimaryUnitList(unitList);
    }

    setPrimaryUnitId("");
    loadingIconState.setState(false);
  };

  const handleOtherDepartmentChange = async (event: any) => {
    loadingIconState.setState(true);
    setOtherDepartmentId(event.target.value);

    if (!!event.target.value) {
      let unitList = await getSubDepartmentByDepartmentId(event.target.value);
      setOtherUnitList(unitList);
    }

    setOtherUnitId("");
    loadingIconState.setState(false);
  };

  const handleStaffLocationChange = () => {
    let staffId = staffDetailState.getState();
    let altStaffDetail = { ...staffDetail };

    if (!!primaryLocationId && primaryLocationId != EmptyGuid) {
      altStaffDetail.staffLocationMappings = [
        {
          staff_id: staffDetail.id,
          location_id: primaryLocationId,
          department_id:
            !!primaryDepartmentId && primaryDepartmentId != EmptyGuid
              ? primaryDepartmentId
              : null,
          sub_department_id:
            !!primaryUnitId && primaryUnitId != EmptyGuid
              ? primaryUnitId
              : null,
          is_primary_location: true,
        },
      ];

      setStaffDetail(altStaffDetail);
    } else {
      altStaffDetail.staffLocationMappings = [];

      setStaffDetail(altStaffDetail);
    }

    if (!!otherLocationId && otherLocationId != EmptyGuid) {
      altStaffDetail.staffLocationMappings.push({
        staff_id: staffDetail.id,
        location_id: otherLocationId,
        department_id:
          !!otherDepartmentId && otherDepartmentId != EmptyGuid
            ? otherDepartmentId
            : null,
        sub_department_id:
          !!otherUnitId && otherUnitId != EmptyGuid ? otherUnitId : null,
        is_primary_location: false,
      });

      setStaffDetail(altStaffDetail);
    }
  };

  const handleStaffLocationDropdown = async () => {
    if (
      !!staffDetail.staffLocationMappings &&
      staffDetail.staffLocationMappings.length > 0
    ) {
      if (
        !!staffDetail.staffLocationMappings.find((cc) => cc.is_primary_location)
      ) {
        let primaryStaffLocationMapping =
          staffDetail.staffLocationMappings.find(
            (cc) => cc.is_primary_location
          );

        if (!!primaryStaffLocationMapping) {
          setPrimaryLocationId(primaryStaffLocationMapping?.location_id);

          let departList = await getAllDepartmentsByLocationId(
            primaryStaffLocationMapping?.location_id
          );
          setPrimaryDepartmentList(departList);

          if (!!primaryStaffLocationMapping?.department_id) {
            let unitList = await getSubDepartmentByDepartmentId(
              primaryStaffLocationMapping?.department_id
            );
            setPrimaryUnitList(unitList);

            setPrimaryDepartmentId(primaryStaffLocationMapping?.department_id);
          }

          if (!!primaryStaffLocationMapping?.sub_department_id) {
            setPrimaryUnitId(primaryStaffLocationMapping?.sub_department_id);
          }
        }
      }

      if (
        !!staffDetail.staffLocationMappings.find(
          (cc) => !cc.is_primary_location
        )
      ) {
        let otherStaffLocationMapping = staffDetail.staffLocationMappings.find(
          (cc) => !cc.is_primary_location
        );

        if (!!otherStaffLocationMapping) {
          setOtherLocationId(otherStaffLocationMapping?.location_id);

          let subDepartList = await getAllDepartmentsByLocationId(
            otherStaffLocationMapping?.location_id
          );
          setOtherDepartmentList(subDepartList);

          if (!!otherStaffLocationMapping.department_id) {
            let otherUnitList = await getSubDepartmentByDepartmentId(
              otherStaffLocationMapping?.department_id
            );
            setOtherUnitList(otherUnitList);

            setOtherDepartmentId(otherStaffLocationMapping?.department_id);
          }

          if (!!otherStaffLocationMapping?.sub_department_id) {
            setOtherUnitId(otherStaffLocationMapping?.sub_department_id);
          }
        }
      }
    }
  };

  const handleNPIChange = (event: any) => {
    const inputValue = event.target.value;
    const npiValue = inputValue.replace(/\D/g, "");
    if (npiValue.length <= 10) {
      setStaffDetail({
        ...staffDetail,
        npi: npiValue,
      });
    }
  };

  const formatNumber = (value: any) => {
    const digits = value.replace(/\D/g, "");
    if (digits.length <= 3) {
      return digits;
    } else if (digits.length <= 5) {
      return `${digits.slice(0, 3)}-${digits.slice(3)}`;
    } else {
      return `${digits.slice(0, 3)}-${digits.slice(3, 5)}-${digits.slice(
        5,
        9
      )}`;
    }
  };

  const handleSSNchange = (event: any) => {
    const newValue = event.target.value;
    setStaffDetail({
      ...staffDetail,
      ssn: formatNumber(newValue),
    });
  };

  const handleHourlyPayRate = (event: any) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,2}(\.\d{0,2})?$/;
    if (regex.test(inputValue)) {
      setStaffDetail({
        ...staffDetail,
        hourly_pay_rate: inputValue,
      });
    }
  };

  const hourlyOvertimePayRate = (event: any) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,2}(\.\d{0,2})?$/;
    if (regex.test(inputValue)) {
      setStaffDetail({
        ...staffDetail,
        overtime_pay_rate: inputValue,
      });
    }
  };

  const contractedHours = (event: any) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,2}(\.\d{0,2})?$/;
    if (regex.test(inputValue)) {
      setStaffDetail({
        ...staffDetail,
        contracted_hours: inputValue,
      });
    }
  };

  const handlePrimaryLocationClear = () => {
    setPrimaryLocationId("");
    setPrimaryDepartmentId("");
    setPrimaryUnitId("");
    setPrimaryDepartmentList([]);
    setPrimaryUnitList([]);
    setOtherLocationId("");
    setOtherDepartmentId("");
    setOtherUnitId("");
    setOtherDepartmentList([]);
    setOtherUnitList([]);
  };

  const handlePrimaryDepartmentClear = () => {
    setPrimaryDepartmentId("");
    setPrimaryUnitId("");
    setPrimaryUnitList([]);
  };

  const handlePrimaryUnitClear = () => {
    setPrimaryUnitId("");
  };

  const handleOtherLocationClear = () => {
    setOtherLocationId("");
    setOtherDepartmentId("");
    setOtherUnitId("");
    setOtherDepartmentList([]);
    setOtherUnitList([]);
  };

  const handleOtherDepartmentClear = () => {
    setOtherDepartmentId("");
    setOtherUnitId("");
    setOtherUnitList([]);
  };

  const handleOtherUnitClear = () => {
    setOtherUnitId("");
  };

  return (
    <>
      <FormContainerBox>
        <Box sx={{ width: "100%" }}>
          <Typography18_600 text="Role" color="#17191C" />
        </Box>
        <TextFieldPlacementBox>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Role" color="#737F8C" required />
              <MultiSelectDropdown
                handleChange={handleRoleTypeChange}
                value={staffDetail.role_ids}
                label={""}
                listItems={orgRoleList}
                handleRemove={handleRoleTypeRemove}
                labelValue="abbreviation"
                errorMessage={getErrorDetail("role_ids", props.errorMessage)}
                placeholder="Select roles"
              >
                <MenuItem value={EmptyGuid} disabled>
                  Select one or a few roles
                </MenuItem>
                {orgRoleList.map((cc: OrgRole) => (
                  <MenuItem value={cc.id} key={cc.id}>
                    {cc.abbreviation}
                  </MenuItem>
                ))}
              </MultiSelectDropdown>
            </LableTextFieldBox>
          </Box>
        </TextFieldPlacementBox>
      </FormContainerBox>
      <FormContainerBox>
        <Box sx={{ width: "100%" }}>
          <Typography18_600 text="Location information" color="#17191C" />
        </Box>
        <TextFieldPlacementBox>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Primary work location" color="#737F8C" />
              <DropdownWithBorder
                sx={{
                  "&.Mui-disabled": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #ABB2BA !important",
                    },
                  },
                }}
                value={primaryLocationId}
                handleChange={handlePrimaryLocationChange}
                handleClear={handlePrimaryLocationClear}
                disable={staffDetail.staff_type == 103}
              >
                <MenuItem value={""} disabled>
                  Select primary work location
                </MenuItem>
                {locationDetailListState
                  .getState()
                  .locationDetailList.map((cc) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      <Typography16_400 text={cc.name} color="#031A30" />
                    </MenuItem>
                  ))}
              </DropdownWithBorder>
              <Typography14_400
                text="You can select location where talent can work."
                color="#737F8C"
              />
            </LableTextFieldBox>
          </Box>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Other work location" color="#737F8C" />
              <DropdownWithBorder
                sx={{
                  "&.Mui-disabled": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #ABB2BA !important",
                    },
                  },
                }}
                value={otherLocationId}
                handleChange={handleOtherLocationChange}
                handleClear={handleOtherLocationClear}
                disable={
                  staffDetail.staff_type == 103 || primaryLocationId == ""
                }
              >
                <MenuItem value={""} disabled>
                  Select other work location
                </MenuItem>
                {locationDetailListState
                  .getState()
                  .locationDetailList.map((cc) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      <Typography16_400 text={cc.name} color="#031A30" />
                    </MenuItem>
                  ))}
              </DropdownWithBorder>
              <Typography14_400
                text="You can select location where talent can work in addition to their main location."
                color="#737F8C"
              />
            </LableTextFieldBox>
          </Box>
        </TextFieldPlacementBox>

        <TextFieldPlacementBox>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Primary department" color="#737F8C" />
              <DropdownWithBorder
                sx={{
                  "&.Mui-disabled": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #ABB2BA !important",
                    },
                  },
                }}
                value={primaryDepartmentId}
                handleChange={handlePrimaryDepartmentChange}
                handleClear={handlePrimaryDepartmentClear}
                disable={staffDetail.staff_type == 103}
              >
                <MenuItem value={""} disabled>
                  Select primary department
                </MenuItem>
                {primaryDepartmentList.map((cc) => (
                  <MenuItem value={cc.id} key={cc.id}>
                    <Typography16_400 text={cc.name} color="#031A30" />
                  </MenuItem>
                ))}
              </DropdownWithBorder>
              <Typography14_400
                text="You can select department where talent can work."
                color="#737F8C"
              />
            </LableTextFieldBox>
          </Box>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Other deparment" color="#737F8C" />
              <DropdownWithBorder
                sx={{
                  "&.Mui-disabled": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #ABB2BA !important",
                    },
                  },
                }}
                value={otherDepartmentId}
                handleChange={handleOtherDepartmentChange}
                handleClear={handleOtherDepartmentClear}
                disable={
                  staffDetail.staff_type == 103 || primaryLocationId == ""
                }
              >
                <MenuItem value={""} disabled>
                  Select other deparment
                </MenuItem>
                {otherDepartmentList.map((cc) => (
                  <MenuItem value={cc.id} key={cc.id}>
                    <Typography16_400 text={cc.name} color="#031A30" />
                  </MenuItem>
                ))}
              </DropdownWithBorder>
              <Typography14_400
                text="You can select department where talent can work in addition to their main department."
                color="#737F8C"
              />
            </LableTextFieldBox>
          </Box>
        </TextFieldPlacementBox>

        <TextFieldPlacementBox>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Primary sub-department" color="#737F8C" />
              <DropdownWithBorder
                sx={{
                  "&.Mui-disabled": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #ABB2BA !important",
                    },
                  },
                }}
                value={primaryUnitId}
                handleChange={(e: any) => {
                  setPrimaryUnitId(e.target.value);
                }}
                handleClear={handlePrimaryUnitClear}
                disable={staffDetail.staff_type == 103}
              >
                <MenuItem value={""} disabled>
                  Select primary sub-department
                </MenuItem>
                {primaryUnitList.map((cc) => (
                  <MenuItem value={cc.id} key={cc.id}>
                    <Typography16_400 text={cc.name} color="#031A30" />
                  </MenuItem>
                ))}
              </DropdownWithBorder>
              <Typography14_400
                text="You can select sub-department where talent can work."
                color="#737F8C"
              />
            </LableTextFieldBox>
          </Box>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Other sub-department" color="#737F8C" />
              <DropdownWithBorder
                sx={{
                  "&.Mui-disabled": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #ABB2BA !important",
                    },
                  },
                }}
                value={otherUnitId}
                handleChange={(e: any) => {
                  setOtherUnitId(e.target.value);
                }}
                handleClear={handleOtherUnitClear}
                disable={
                  staffDetail.staff_type == 103 || primaryLocationId == ""
                }
              >
                <MenuItem value={""} disabled>
                  Select other sub-department
                </MenuItem>
                {otherUnitList.map((cc) => (
                  <MenuItem value={cc.id} key={cc.id}>
                    <Typography16_400 text={cc.name} color="#031A30" />
                  </MenuItem>
                ))}
              </DropdownWithBorder>
              <Typography14_400
                text="You can select sub-department where talent can work in addition to their main sub-department."
                color="#737F8C"
              />
            </LableTextFieldBox>
          </Box>
        </TextFieldPlacementBox>

        <TextFieldPlacementBox>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Social security number" color="#737F8C" />
              <PlatformTextField
                placeholder={"eg. 121-14-5765"}
                value={staffDetail.ssn}
                handleChange={handleSSNchange}
                errorMessage={getErrorDetail("ssn", props.errorMessage)}
              />
            </LableTextFieldBox>
          </Box>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="NPI" color="#737F8C" />
              <PlatformTextField
                placeholder={"eg. 1245319599"}
                value={staffDetail.npi}
                handleChange={handleNPIChange}
                errorMessage={getErrorDetail("npi", props.errorMessage)}
              />
            </LableTextFieldBox>
          </Box>
        </TextFieldPlacementBox>
      </FormContainerBox>
      <FormContainerBox>
        <Box sx={{ width: "100%" }}>
          <Typography18_600 text="HR information" color="#17191C" />
        </Box>
        <TextFieldPlacementBox>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Hourly pay rate" color="#737F8C" />
              <PlatformTextField
                placeholder={"$ Add hourly pay rate"}
                value={String(staffDetail.hourly_pay_rate)}
                handleChange={handleHourlyPayRate}
                errorMessage={getErrorDetail(
                  "hourly_pay_rate",
                  props.errorMessage
                )}
              />
            </LableTextFieldBox>
          </Box>
          {(staffDetail.staff_type == 0 || staffDetail.staff_type == 102) && (
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600
                  text="Hourly overtime pay rate"
                  color="#737F8C"
                />
                <PlatformTextField
                  placeholder={"$ Add hourly overtime pay rate"}
                  value={String(staffDetail.overtime_pay_rate)}
                  handleChange={hourlyOvertimePayRate}
                  errorMessage={getErrorDetail(
                    "overtime_pay_rate",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
          )}
        </TextFieldPlacementBox>
        <TextFieldPlacementBox>
          {(staffDetail.staff_type == 0 || staffDetail.staff_type == 102) && (
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Contracted hours" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Add contracted hours"}
                  value={String(staffDetail.contracted_hours)}
                  handleChange={contractedHours}
                  errorMessage={getErrorDetail(
                    "contracted_hours",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
          )}
        </TextFieldPlacementBox>
      </FormContainerBox>
    </>
  );
};
