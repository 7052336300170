import { Select, SxProps, Theme, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ReactNode } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ISignUpDropDown {
  handleChange: (e: any) => void;
  children: ReactNode;
  value: any;
  errorMessage?: string;
  placeholder?: string;
  sx?: SxProps<Theme>;
}

export const SignUpDropDown = (props: ISignUpDropDown) => {
  return (
    <>
      <Select
        size="small"
        IconComponent={ExpandMoreIcon}
        sx={{
          ...props.sx,
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "24px",
          background: "#0000000F",
          borderRadius: "8px",
          color: "#0000008A",
          width: "100%",

          "& .MuiSvgIcon-root": { color: "#0000008A" },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: !!props.errorMessage
              ? "red !important"
              : "#0000000F !important",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: !!props.errorMessage
              ? "red !important"
              : "#0000003B !important",
          },
          "& .MuiInputLabel-root": { display: "none" },
          "&:hover": {
            "&& fieldset": {
              border: !!props.errorMessage
                ? "1px solid red !important"
                : "1px solid #0000003B",
            },
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: !!props.errorMessage
              ? "2px solid red !important"
              : "2px solid #1F5DA5 !important",
            borderRadius: "8px",
          },
        }}
        MenuProps={{
          sx: {
            ...props.sx,
            maxHeight: "300px !important",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              width: "6px",
              height: "6px",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: "32px",
              backgroundColor: grey["400"],
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
              {
                backgroundColor: grey["500"],
              },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
              {
                backgroundColor: grey["500"],
              },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
              {
                backgroundColor: grey["500"],
              },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: "#0000000",
            },
          },
        }}
        placeholder={props.placeholder}
        onChange={props.handleChange}
        value={props.value}
        displayEmpty
      >
        {props.children}
      </Select>
      {!!props.errorMessage && (
        <span style={{ fontSize: "10px", fontWeight: "400", color: "red" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "22.4px",
            }}
            className="red-text"
          >
            {props.errorMessage}
          </Typography>
        </span>
      )}
    </>
  );
};
