import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FacilitySignUpForms } from "../organism/signUp/facilitySignUpForms";
import { AgencySignUpForms } from "../organism/signUp/agencySignUpForms";
import { currentActiveScreenState } from "../../libs/state-management-lib";

export const SignUpTemplate = () => {
  const [openScreen, setOpenScreen] = useState("signUp");

  return (
    <>
      <Grid container>
        {/* left grid */}
        <Grid item xs={6}>
          <Box
            sx={{
              minHeight: "900px",
              minWidth: "720px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              paddingTop: "30px",
            }}
          >
            {/* header container logo */}
            <Box
              sx={{
                minWidth: "720px",
                minheight: "40px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <img
                src="/images/common/logo.png"
                style={{ width: "160px", height: "40px" }}
              />
            </Box>
            {/* Body container */}
            <Box
              sx={{
                minWidth: "720px",
                height: "670px",
                minHeight: "670px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "60px 0px 24px 0px",
                gap: "24px",
              }}
            >
              <AgencySignUpForms />
            </Box>
            {/* FooterContainer */}
            <Box
              sx={{
                minWidth: "720px",
                minheight: "58px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  minWidth: "720px",
                  minHeight: "22px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.17px",
                    color: "#00000099",
                  }}
                >
                  By using Shiftnex you agree to the{" "}
                  <span
                    style={{
                      fontWeight: "500",
                      color: "#1F5DA5",
                      lineHeight: "22px",
                      letterSpacing: "0.1px",
                      cursor: "pointer",
                    }}
                  >
                    Terms of service
                  </span>{" "}
                  and{" "}
                  <span
                    style={{
                      fontWeight: "500",
                      color: "#1F5DA5",
                      lineHeight: "22px",
                      letterSpacing: "0.1px",
                      cursor: "pointer",
                    }}
                  >
                    Privacy policy
                  </span>
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.17px",
                    color: "#00000099",
                  }}
                >
                  Version 1.0.0
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* right grid */}
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              src="/images/common/SignIn.png"
              style={{ height: "100vh", width: "auto" }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
