import { create } from "zustand";
import { DepartmentDetail } from "../model-lib";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

interface IDepartmentListState {
  departmentDetailList: DepartmentDetail[];
}

export const departmentListState = create<IDepartmentListState>()(
  devtools(
    persist((set, get) => ({ departmentDetailList: [] }), {
      name: "departmentListState",
    })
  )
);

interface IOrgDepartmentListState {
  departmentDetailList: DepartmentDetail[];
}

export const orgDepartmentListState = create<IOrgDepartmentListState>()(
  devtools(
    persist((set, get) => ({ departmentDetailList: [] }), {
      name: "departmentListState",
    })
  )
);

export const departmentDetailState = create<DepartmentDetail>()(
  devtools(
    persist(
      (set, get) => ({
        id: "",
        name: "",
        address_1: "",
        department_location: "",
        location_Id: "",
        description: "",
        department_type: 0,
        staff_type_ids: [],
        org_role_ids: [],
        is_active: true,
        address_2: "",
        city: "",
        state: "",
        zip_code: "",
        number_of_beds: 0,
        departmentContactDetails: {
          
          id: "",
          email: "",
          department_id: "",
          name: "",
          phone_number: "",
          title: "",
        },
      }),
      { name: "departmentDetailState" }
    )
  )
);
