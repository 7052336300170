import { Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { LayoutTemplate, SignUpTemplate } from "./ui-component/template";
import {
  Setting,
  Calendar,
  Dashboard,
  SignIn,
  FacilitySignUp,
  NotFoundPage,
} from "./ui-component/page";
import { MarketPlace } from "./ui-component/page/marketPlace";
import Location from "./ui-component/page/settings/location";
import Roles from "./ui-component/page/settings/roles";
import Staffing from "./ui-component/page/settings/staffing";
import StaffingPartner from "./ui-component/page/settings/staffingPartner";
import LocationDetail from "./ui-component/page/settings/locationDetail";
import DepartmentDetail from "./ui-component/page/settings/departmentDetail";
import UnitDetail from "./ui-component/page/settings/unitDetail";
import RoleDetail from "./ui-component/page/settings/roleDetail";
import StaffDetail from "./ui-component/page/settings/staffDetail";
import { ProfileSettings } from "./ui-component/page/profileSettings/profileSettings";
import { NotificationsAndSound } from "./ui-component/page/profileSettings/notificationsAndSound";
import { LanguageAndTimeFormat } from "./ui-component/page/profileSettings/languageAndTimeFormat";
import { PrivacyAndSecurity } from "./ui-component/page/profileSettings/privacyAndSecurity";
import ProtectedRoute from "./libs/common-lib/protectedRoute";
import { Unauthorized } from "./ui-component/page/unauthorized";

const theme = createTheme({
  palette: {
    primary: {
      main: "#CFE5FC",
      contrastText: "#3D99F5",
    },
    secondary: {
      main: "#F5FAFE",
      contrastText: "#3D99F5",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LayoutTemplate>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/agencySignup" element={<SignUpTemplate />} />
          <Route path="/SignUp" element={<FacilitySignUp />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute element={<Dashboard />} />}
          />
          <Route
            path="/schedule"
            element={<ProtectedRoute element={<Calendar />} />}
          />
          <Route
            path="/settings/organization"
            element={<ProtectedRoute element={<Setting />} />}
          />
          <Route
            path="/settings/locations-departments/:id?"
            element={<ProtectedRoute element={<Location />} />}
          />
          <Route
            path="/settings/location/:id"
            element={<ProtectedRoute element={<LocationDetail />} />}
          />
          <Route
            path="/settings/department/:id"
            element={<ProtectedRoute element={<DepartmentDetail />} />}
          />
          <Route
            path="/settings/sub-department/:id"
            element={<ProtectedRoute element={<UnitDetail />} />}
          />
          <Route
            path="/settings/roles"
            element={<ProtectedRoute element={<Roles />} />}
          />
          <Route
            path="/settings/role/:id"
            element={<ProtectedRoute element={<RoleDetail />} />}
          />
          <Route
            path="/settings/talents"
            element={<ProtectedRoute element={<Staffing />} />}
          />
          <Route
            path="/settings/talent/:id"
            element={<ProtectedRoute element={<StaffDetail />} />}
          />
          <Route
            path="/settings/staffingpartners"
            element={<ProtectedRoute element={<StaffingPartner />} />}
          />
          <Route
            path="/marketplace"
            element={<ProtectedRoute element={<MarketPlace />} />}
          />
          <Route
            path="/talents"
            element={<ProtectedRoute element={<Staffing />} />}
          />
          <Route
            path="/profilesettings/profile-details"
            element={<ProtectedRoute element={<ProfileSettings />} />}
          />
          <Route
            path="/profilesettings/notifications-and-sound"
            element={<ProtectedRoute element={<NotificationsAndSound />} />}
          />
          <Route
            path="/profilesettings/language-and-time-format"
            element={<ProtectedRoute element={<LanguageAndTimeFormat />} />}
          />
          <Route
            path="/profilesettings/privacy-and-security"
            element={<ProtectedRoute element={<PrivacyAndSecurity />} />}
          />
          <Route
            path="/unauthorized"
            element={<ProtectedRoute element={<Unauthorized />} />}
          />
          <Route
            path="*"
            element={<ProtectedRoute element={<NotFoundPage />} />}
          />
        </Routes>
      </LayoutTemplate>
    </ThemeProvider>
  );
};

export default App;
