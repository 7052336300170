import {
  Avatar,
  Box,
  Chip,
  IconButton,
  ListItemButton,
  Menu,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  ActiveConfirmationPopup,
  ActiveIcon,
  ContainedButton,
  ContainedDeleteButton,
  DeleteIcon,
  InActiveConfirmationPopup,
  OutlinedButton,
  Typography14_400,
  Typography14_600,
  Typography16_400,
} from "../../atom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useEffect, useLayoutEffect, useState } from "react";
import { EmptyGuid, stringAvatar } from "../../../libs/common-lib";
import { AgencyDetail } from "../../../libs/model-lib/agencyDetail";
import {
  activeConfirmationPopupState,
  agencyDetailListState,
  agencyMappedDetailListState,
  confirmationPopupTitleState,
  inActiveConfirmationPopupState,
  locationDetailListState,
  orgDepartmentListState,
} from "../../../libs/state-management-lib";
import { DepartmentDetail, LocationDetail } from "../../../libs/model-lib";
import { IosSwitch } from "../../atom/Switch/iosSwitch";

interface IStaffingPartnerDashboard {
  handleActiveStaffingPartner: (activeStaffingPartnerId: string) => void;
  handleInActiveStaffingPartner: (inActiveStaffingPartnerId: string) => void;
}

export const StaffingPartnerDashboard = (props: IStaffingPartnerDashboard) => {
  const [agencyMappingList, setAgencyMappingList] = useState<AgencyDetail[]>(
    agencyMappedDetailListState.getState().agencyMappedDetailList
  );
  const [staffingPartnerActionState, setStaffingPartnerActionState] = useState<{
    anchorEl: HTMLElement | null;
    rowId: number | null;
  }>({ anchorEl: null, rowId: null });
  const [activeChecked, setActiveChecked] = useState<boolean>(false);
  const [activeStaffingPartnerId, setStaffingPartnerId] = useState("");
  const [showActiveConfirmationPopup, setshowActiveConfirmationPopup] =
    useState(false);
  const [showInActiveConfirmationPopup, setshowInActiveConfirmationPopup] =
    useState(false);

  useLayoutEffect(() => {
    const agencyMappedDetailListStateSub =
      agencyMappedDetailListState.subscribe((state) => {
        setAgencyMappingList(state.agencyMappedDetailList);
      });

    const inActiveConfirmationPopupStateSub =
      inActiveConfirmationPopupState.subscribe(async (state) => {
        setshowInActiveConfirmationPopup(state);
      });

    const activeConfirmationPopupStateSub =
      activeConfirmationPopupState.subscribe(async (state) => {
        setshowActiveConfirmationPopup(state);
      });

    return () => {
      agencyMappedDetailListStateSub();
      inActiveConfirmationPopupStateSub();
      activeConfirmationPopupStateSub();
    };
  }, []);

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const getAgencyLocationDetails = (locationIds: string[] | undefined) => {
    if (!!!locationIds || locationIds.length == 0) {
      return "";
    }

    let selectedLocation = "";

    locationIds.forEach((loc: string) => {
      let filteredLocation = locationDetailListState
        .getState()
        .locationDetailList.filter((locationDetail: LocationDetail) => {
          return locationDetail.id == loc;
        });

      if (
        !!filteredLocation &&
        filteredLocation.length > 0 &&
        !!filteredLocation[0].name
      ) {
        selectedLocation =
          selectedLocation +
          (!!selectedLocation ? ", " : "") +
          filteredLocation[0].name;
      }
    });

    return selectedLocation;
  };

  const getAgencyDepartmentDetails = (departIds: string[] | undefined) => {
    if (!!!departIds || departIds.length == 0) {
      return "";
    }

    let selectedDeparts = "";

    departIds.forEach((depart: string) => {
      let filteredDeparts = orgDepartmentListState
        .getState()
        .departmentDetailList.filter((deptDetail: DepartmentDetail) => {
          return deptDetail.id == depart;
        });

      if (
        !!filteredDeparts &&
        filteredDeparts.length > 0 &&
        !!filteredDeparts[0].name
      ) {
        selectedDeparts =
          selectedDeparts +
          (!!selectedDeparts ? ", " : "") +
          filteredDeparts[0].name;
      }
    });

    return selectedDeparts;
  };

  const handleClose = () => {
    setStaffingPartnerActionState({ anchorEl: null, rowId: null });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, params: any) => {
    setStaffingPartnerActionState({
      anchorEl: event.currentTarget,
      rowId: params.id,
    });
    setActiveChecked(params.row.agency_mapping_detail.is_active);
  };

  const handleActiveStaffingPartnerClick = (params: any) => {
    if (activeChecked) {
      activeConfirmationPopupState.setState(true);
    } else {
      inActiveConfirmationPopupState.setState(true);
    }
    let staffingPartnerId = params.row.agency_mapping_detail.id;
    setStaffingPartnerId(staffingPartnerId);
    let staffingPartnerName = params.row.name;
    confirmationPopupTitleState.setState(staffingPartnerName);
    setStaffingPartnerActionState({ anchorEl: null, rowId: null });
  };

  const handleCloseClick = () => {
    activeConfirmationPopupState.setState(false);
  };

  const handleInactivePopupClick = () => {
    inActiveConfirmationPopupState.setState(false);
  };

  const handleActiveStaffingPartner = () => {
    props.handleActiveStaffingPartner(activeStaffingPartnerId);
  };

  const handleInActiveLocation = () => {
    props.handleInActiveStaffingPartner(activeStaffingPartnerId);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: "6px",
          zIndex: 1,
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            borderRadius: "8px",
            width: "100%",
            height: "90%",
          }}
        >
          <DataGrid
            sx={{
              width: "100%",
              height: "100%",

              "& .theme--header": {
                backgroundColor: "#F9FAFA",
                color: "#00000099",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.17px",
              },
              "& .MuiDataGrid-row": {
                borderBottom: "1px solid #E3E5E8",
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#F5FAFE",
              },
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                background: "#F9FAFA",
              },
              "& .MuiCheckbox-root": {
                color: "#ABB2BA !important",
              },
              "& .Mui-checked": {
                color: "#0D80F2 !important",
              },
              "& .MuiDataGrid-scrollbarFiller": {
                backgroundColor: "#F9FAFA",
              },
              "& .MuiDataGrid-root .MuiDataGrid-filler": {
                backgroundColor: "#F9FAFA",
              },
              border: "1px solid #E3E5E8",
            }}
            rows={agencyMappingList}
            columns={[
              {
                field: "name",
                headerName: "Agency name",
                headerClassName: "theme--header",
                flex: 1,
                renderCell: (params: any) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "8px 0px",
                        height: "32px",
                        justifyContent: "flex-start",
                        gap: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <Avatar
                        variant="square"
                        src={params.row.logo_url}
                        sx={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "8px",
                        }}
                      />
                      <Typography14_600 text={params.row.name} />
                    </Box>
                  );
                },
              },
              {
                field: "agency_admin_email",
                headerName: "Email",
                headerClassName: "theme--header",
                flex: 1,
                sortable: false,
                renderCell: (params: any) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "8px 0px",
                        height: "32px",
                        justifyContent: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <Typography14_400
                        text={
                          !!params.row.contact_person_details
                            ? params.row.contact_person_details.email
                            : ""
                        }
                        color="rgba(0, 0, 0, 0.87)"
                      />
                    </Box>
                  );
                },
              },
              {
                field: "location",
                headerName: "Location",
                headerClassName: "theme--header",
                flex: 1,
                sortable: false,
                valueGetter: (value, row) =>
                  `${getAgencyLocationDetails(
                    row.agency_mapping_detail?.location_ids
                  )} `,
              },
              {
                field: "department",
                headerName: "Department",
                headerClassName: "theme--header",
                flex: 1,
                sortable: false,
                valueGetter: (value, row) =>
                  `${getAgencyDepartmentDetails(
                    row.agency_mapping_detail?.department_ids
                  )} `,
              },
              {
                field: "is_active",
                headerName: "Status",
                headerClassName: "theme--header",
                flex: 1,
                sortable: false,
                renderCell: (params: any) => {
                  return (
                    <Chip
                      label={
                        params.row.agency_mapping_detail.is_active
                          ? "Active"
                          : "Inactive"
                      }
                      sx={{
                        height: "22px",
                        background: params.row.agency_mapping_detail.is_active
                          ? "#F7FDF8"
                          : "#ffece5",
                        border: "1px solid #EBFAEE",
                        borderRadius: "4px",
                        color: params.row.agency_mapping_detail.is_active
                          ? "#29A347"
                          : "#ff5722",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    />
                  );
                },
              },
              {
                field: "marketplace",
                headerName: "Marketplace presence",
                headerClassName: "theme--header",
                flex: 1,
                sortable: false,
              },
              {
                field: "actions",
                type: "actions",
                headerClassName: "theme--header",
                cellClassName: "actions",
                align: "right",
                width: 60,
                renderCell: (params: any) => {
                  const isOpen = staffingPartnerActionState.rowId === params.id;
                  return (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                      key={`action-${params.id}`}
                    >
                      <Box
                        sx={{ color: "#0D80F2" }}
                        onClick={(event) => handleClick(event, params)}
                      >
                        <IconButton
                          sx={{
                            background: "#FFFFFF",
                            color: "#0D80F2",
                            borderRadius: "10px !important",
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </Box>
                      <Menu
                        anchorEl={staffingPartnerActionState.anchorEl}
                        id="account-menu"
                        open={isOpen}
                        onClose={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            width: "228px",
                            borderRadius: "8px",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&::before": {
                              content: '""',
                              display: "bstaffk",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        {/* <ListItemButton
                          sx={{ gap: "7px" }}
                          key={`menu-item-1-${params.id}`}
                          onClick={handleClose}
                        >
                          <DeleteIcon sx={{ color: "#0D80F2" }} />{" "}
                          <Typography16_400 text="Delete" color="#031A30" />
                        </ListItemButton> */}
                        <ListItemButton
                          sx={{ gap: "7px" }}
                          key={`menu-item-2-${params.id}`}
                          onClick={() =>
                            handleActiveStaffingPartnerClick(params)
                          }
                        >
                          <ActiveIcon sx={{ color: "#0D80F2" }} />
                          <Typography16_400 text="Active" color="#031A30" />
                          <Box sx={{ marginLeft: "35%" }}>
                            <IosSwitch checked={activeChecked} />
                          </Box>
                        </ListItemButton>
                      </Menu>
                    </Box>
                  );
                },
              },
            ]}
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterValues: [],
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            checkboxSelection
          />
        </Box>
      </Box>
      <ActiveConfirmationPopup
        title={`Deactivate ${confirmationPopupTitleState.getState()}`}
        content={`Are you sure you want to deactivate “${confirmationPopupTitleState.getState()}” staffing partner?`}
        open={showActiveConfirmationPopup}
        handleClick={handleCloseClick}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px",
            gap: "16px",
          }}
        >
          <OutlinedButton
            sx={{ height: "40px" }}
            text="Cancel"
            handleClick={handleCloseClick}
          />
          <ContainedDeleteButton
            sx={{ height: "40px" }}
            text="Deactivate staffing partner"
            handleClick={handleActiveStaffingPartner}
          />
        </Box>
      </ActiveConfirmationPopup>
      <InActiveConfirmationPopup
        title={`Activate ${confirmationPopupTitleState.getState()}`}
        content={`Are you sure you want to activate “${confirmationPopupTitleState.getState()}” staffing partner?`}
        open={showInActiveConfirmationPopup}
        handleClick={handleInactivePopupClick}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px",
            gap: "16px",
          }}
        >
          <OutlinedButton
            sx={{ height: "40px" }}
            text="Cancel"
            handleClick={handleInactivePopupClick}
          />
          <ContainedButton
            sx={{ height: "40px" }}
            text="Activate staffing partner"
            handleClick={handleInActiveLocation}
          />
        </Box>
      </InActiveConfirmationPopup>
    </>
  );
};
