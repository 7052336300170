import { Box, Typography } from "@mui/material";
import {
  AgencySuccessAlert,
  ContainedButton,
  PlatformLoginTextField,
  Typography15_500,
  Typography16_400,
  Typography24_500,
} from "../../atom";
import { useNavigate } from "react-router-dom";
import { SignUpContainedButton } from "../../atom/buttons/signupContainedButton";
import { useEffect, useLayoutEffect, useState } from "react";
import { LoginDetail } from "../../../libs/model-lib";
import { getErrorDetail } from "../../../libs/common-lib";
import { useLoginDetailContext } from "../../../libs/context-lib/loginDetailContext";

interface ICodeVerifyForm {
  errorMessage: any[];
  handleVerifyCodeClick: () => void;
  handleResendCodeClick: () => void;
}
export const CodeVerifyForm = (props: ICodeVerifyForm) => {
  const navigate = useNavigate();
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const seconds = String(countDown % 60).padStart(2, "0");
  const minutes = String(Math.floor(countDown / 60)).padStart(2, "0");
  const { loginDetail, setLoginDetail } = useLoginDetailContext();

  useEffect(() => {
    handleTimer();
  }, []);

  useEffect(() => {
    let timerId: string | number | NodeJS.Timer | undefined;

    if (runTimer) {
      setCountDown(60 * 2);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const handleVerifyClick = () => {
    props.handleVerifyCodeClick();
  };

  const handleResendCodeClick = () => {
    if (minutes == "00" && seconds == "00") {
      props.handleResendCodeClick();
      handleTimer();
    }
  };

  const getResendOTPTextColor = () => {
    if (minutes == "00" && seconds == "00") {
      return "#075CDA";
    } else {
      return "#00000099";
    }
  };

  const handleTimer = () => {
    if (minutes == "00" && seconds == "00") {
      setRunTimer((t) => !t);
    }
  };

  const handleCodeChange = (event: any) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue)) {
      setLoginDetail({
        ...loginDetail,
        otp: inputValue,
      });
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "24px",
          width: "calc(100% - 20px)",
          padding: "10px",
          justifyContent: "center",
        }}
      >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <svg
                width="80"
                height="81"
                viewBox="0 0 80 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M52.5 10C47.9688 10 43.9062 12.5 41.5625 16.25C39.375 20.1562 39.375 25 41.5625 28.75C43.9062 32.6562 47.9688 35 52.5 35C56.875 35 60.9375 32.6562 63.2812 28.75C65.4688 25 65.4688 20.1562 63.2812 16.25C60.9375 12.5 56.875 10 52.5 10ZM52.5 45C44.375 45 37.0312 40.7812 32.9688 33.75C28.9062 26.875 28.9062 18.2812 32.9688 11.25C37.0312 4.375 44.375 0 52.5 0C60.4688 0 67.8125 4.375 71.875 11.25C75.9375 18.2812 75.9375 26.875 71.875 33.75C67.8125 40.7812 60.4688 45 52.5 45Z"
                  fill="#1FA7F8"
                />
                <path
                  opacity="0.4"
                  d="M33.4375 34.5312C35.1562 37.3438 37.6562 39.8438 40.4688 41.5625L27.0312 55L33.4375 61.5625C35.4688 63.4375 35.4688 66.7188 33.4375 68.5938C31.5625 70.625 28.2812 70.625 26.4062 68.5938L20 62.1875L17.0312 65L23.4375 71.5625C25.4688 73.4375 25.4688 76.7188 23.4375 78.5938C21.5625 80.625 18.2812 80.625 16.4062 78.5938L6.40625 68.5938C4.375 66.7188 4.375 63.4375 6.40625 61.5625L33.4375 34.5312Z"
                  fill="#1FA7F8"
                />
              </svg>
            </Box>

        <Typography24_500 text="Secure verification" color="#000000DE" />
        <Typography16_400
          text="We’ve just sent you a temporary 6-digit code verification."
          color="#00000099"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "5px",
            width: "calc(100% - 6px)",
            justifyContent: "center",
          }}
        >
          <PlatformLoginTextField
            sx={{
              "&:hover fieldset": {
                border: "1px solid #0000003B !important",
                borderRadius: "8px",
              },
            }}
            placeholder="Enter the code"
            value={loginDetail.otp}
            handleChange={handleCodeChange}
            errorMessage={getErrorDetail("otp", props.errorMessage)}
          />
        </Box>

        <SignUpContainedButton
          text="Confirm"
          disabled={!!!loginDetail.otp}
          handleClick={handleVerifyClick}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "15px",
              lineHeight: "26px",
              letterSpacing: "0.46px",
              color: getResendOTPTextColor(),
              cursor: "pointer",
            }}
            onClick={handleResendCodeClick}
          >
            Resend the code{" "}
          </Typography>
          {runTimer && (
            <span
              style={{
                color: getResendOTPTextColor(),
              }}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "22px",
                  letterSpacing: "0.1px",
                }}
              >{`in ${minutes}:${seconds}`}</Typography>
            </span>
          )}
        </Box>
      </Box>
    </>
  );
};
