import axios from "axios";
import {
  accessTokenState,
  userDetailstate,
  loadingIconState,
  numberOfEmployeeListState,
  numberOfStaffBedListState,
  locationTypeListState,
  weekDayStartListState,
  timeZoneListState,
  departmentTypeListState,
  roleTypeListState,
  shiftBreakTypeListState,
  staffTypeListState,
  primaryQualificationListState,
  facilityTypeListState,
  lineOfBusinessListState,
  jobQualificationListState,
  recurrencePeriodListState,
} from "../state-management-lib";
import { axiosInstance } from "../common-lib";
import { getTimeZones } from "@vvo/tzdb";
import { TimeZoneDetail } from "../model-lib";

const platform_base_url = `${process.env.REACT_APP_PLATFORM_URL}api/Master`;

export const getNumbeOfStaffBeds = async () => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetGenericMastersByType?type=NumberOfStaffedBeds`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      numberOfStaffBedListState.setState({
        numberOfStaffBedList: response.data.data,
      });
    }
  } catch (error) {}
};

export const getNumberOfEmployee = async () => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetGenericMastersByType?type=NumberOfEmployees`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      numberOfEmployeeListState.setState({
        numberOfEmployeeList: response.data.data,
      });
    }
  } catch (error) {}
};

export const getLocationTypeList = async () => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetGenericMastersByType?type=LocationType`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      locationTypeListState.setState({
        locationTypeList: response.data.data,
      });
    }
  } catch (error) {}
};

export const getWeekDayStartList = async () => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetGenericMastersByType?type=WeekStartDay`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      weekDayStartListState.setState({
        weekDayStartList: response.data.data,
      });

      return response.data.data;
    }
    return [];
  } catch (error) {}
};

export const getTimeZoneList = () => {
  let allTimeZoneList = getTimeZones();
  let timeZoneList: TimeZoneDetail[] = [];

  allTimeZoneList.forEach((cc) => {
    timeZoneList.push({
      name:
        cc.name +
        " (GMT " +
        cc.currentTimeFormat.substring(0, 6) +
        ") " +
        cc.currentTimeFormat.substring(6, cc.currentTimeFormat.length),
      originalName: cc.name,
      alternativeName: cc.alternativeName,
      currentTimeFormat: cc.currentTimeFormat,
      abbreviation: cc.abbreviation,
      rawOffSetInMinutes: cc.currentTimeOffsetInMinutes,
    });
  });

  timeZoneListState.setState({ timeZoneList: timeZoneList });
};

export const getRoleTypeList = async () => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetGenericMastersByType?type=RoleType`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      roleTypeListState.setState({
        roleTypeList: response.data.data,
      });
    }
  } catch (error) {}
};

export const getShiftBreakTypeList = async () => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetGenericMastersByType?type=ShiftBreakType`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      shiftBreakTypeListState.setState({
        shiftBreakTypeList: response.data.data,
      });
    }
  } catch (error) {}
};

export const getStaffTypeList = async () => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetGenericMastersByType?type=StaffType`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      staffTypeListState.setState({
        staffTypeList: response.data.data,
      });
    }
  } catch (error) {}
};

export const getDepartmentTypeList = async () => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetGenericMastersByType?type=DepartmentType`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      departmentTypeListState.setState({
        departmentTypeList: response.data.data,
      });
    }
  } catch (error) {}
};

export const getPrimaryQualificationList = async () => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetGenericMastersByType?type=PrimaryQualification`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      primaryQualificationListState.setState({
        primaryQualificationList: response.data.data,
      });
    }
  } catch (error) {}
};

export const getFacilityTypeeList = async () => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetGenericMastersByType?type=FacilityType`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      facilityTypeListState.setState({
        facilityTypeList: response.data.data,
      });
    }
  } catch (error) {}
};

export const getLineOfBusinessList = async () => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetGenericMastersByType?type=LinesOfBusiness`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      lineOfBusinessListState.setState({
        lineOfBusinessList: response.data.data,
      });
    }
  } catch (error) {}
};


export const getTalentJobQualification = async () => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetTalentJobQualifications`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      jobQualificationListState.setState({
        jobQualificationList: response.data.data,
      });
    }
  } catch (error) {}
};


export const getRecurrencePeriodList = async () => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetGenericMastersByType?type=RecurrencePeriod`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      recurrencePeriodListState.setState({
        recurrencePeriodList: response.data.data,
      });
    }
  } catch (error) {}
};
