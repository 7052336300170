import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { SearchShift } from "../model-lib";
import { format } from "date-fns";
import { EmptyGuid } from "../common-lib";

export const searchShiftLocationState = create<string>()(
  devtools(
    persist((set, get) => "", {
      name: "searchShiftLocationState",
    })
  )
);
