import {
  FormControl,
  IconButton,
  Select,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { grey } from "@mui/material/colors";
import ClearIcon from "@mui/icons-material/Clear";
import { EmptyGuid } from "../../../libs/common-lib";

interface IDropdownWithBorder {
  handleChange: (e: any) => void;
  children: ReactNode;
  value: any;
  handleClear?: () => void;
  errorMessage?: string;
  placeholder?: string;
  disable?: boolean;
  sx?: SxProps<Theme>;
  renderValue?: any;
}
export const DropdownWithBorder = (props: IDropdownWithBorder) => {
  return (
    <>
      <FormControl size="small">
        <Select
          IconComponent={ExpandMoreIcon}
          labelId="simple-select-filled-label"
          id="simple-select-filled"
          sx={{
            ...props.sx,
            maxHeight: 45,
            borderRadius: 2,
            backgroundColor: "#F5FAFE",
            fontSize: "16px",
            fontWeight: "400",
            color: "#6EB2F7",
            letterSpacing: "0.5px",
            "& .MuiSvgIcon-root": { color: "#0D80F2" },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: !!props.errorMessage ? "red" : "#CFE6FC",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: !!props.errorMessage ? "red" : "#0D80F2",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: !!props.errorMessage
                ? "2px solid red !important"
                : "2px solid #0D80F2 !important",
              borderRadius: "8px",
            },
            "& .MuiInputLabel-root": { display: "none" },
            "& .Mui-disabled.MuiSvgIcon-root": { color: "#B6B9BC !important" },
            "& .Mui-disabled": {
              background: "#F1F2F4 !important",
            },
          }}
          endAdornment={
            (!!props.value && props.value != EmptyGuid) && (
              <IconButton
                edge="end"
                aria-label="clear"
                onClick={props.handleClear}
                sx={{ position: "absolute", right: 33, top: 3, padding: 1 }}
              >
                <ClearIcon sx={{ fontSize: 20 }} />
              </IconButton>
            )
          }
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: "200px !important",
                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  width: "6px",
                  height: "6px",
                },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                  borderRadius: "32px",
                  backgroundColor: "#E3E5E8",
                },
                "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                  {
                    backgroundColor: "#E3E5E8",
                  },
                "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                  {
                    backgroundColor: "#E3E5E8",
                  },
                "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                  {
                    backgroundColor: "#E3E5E8",
                  },
                "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                  backgroundColor: "#0000000",
                },
              },
              style: {
                maxHeight: 250,
              },
            },
          }}
          placeholder={props.placeholder}
          displayEmpty
          value={props.value}
          onChange={props.handleChange}
          disabled={!!props.disable && props.disable ? true : false}
          renderValue={props.renderValue}
        >
          {props.children}
        </Select>
      </FormControl>
      {!!props.errorMessage && (
        <span style={{ fontSize: "10px", fontWeight: "400", color: "red" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "22.4px",
            }}
            className="red-text"
          >
            {props.errorMessage}
          </Typography>
        </span>
      )}
    </>
  );
};
