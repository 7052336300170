import {
  axiosInstance,
  handleApiErrorAlert,
  handleApiSuccessAlert,
} from "../common-lib";
import { AgencyMapping } from "../model-lib";
import {
  agencyDetailListState,
  agencyMappedDetailListState,
  apiAlertState,
  errorAlertState,
  errorMessageState,
  organizationDetailState,
  successAlertState,
} from "../state-management-lib";

const platform_base_url = `${process.env.REACT_APP_PLATFORM_URL}api/Agency`;

export const getAgencyByName = async (name: string, orgId: string) => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetAgencyByName?name=${name}&orgId=${orgId}`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      agencyDetailListState.setState({ agencyDetailList: response.data.data });
    }
  } catch (error) {}
};

export const getMappedAgency = async (orgId: string) => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/GetAgencyNameByOrgId?orgId=${orgId}`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      agencyMappedDetailListState.setState({
        agencyMappedDetailList: response.data.data,
      });
      return response.data.data;
    }

    return [];
  } catch (error) {}
};

export const createAgencyOrgMapping = async (
  agencyMappingDetail: AgencyMapping
) => {
  try {
    let response = await axiosInstance.post(
      `${platform_base_url}/CreateAgencyOrgMapping`,
      agencyMappingDetail
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Agency mapping done successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
  }
};

export const updateStaffingPartner = async (staffingPartnerDetail: any) => {
  try {
    let response = await axiosInstance.post(
      `${platform_base_url}/UpdateStaffingPartner`,
      staffingPartnerDetail
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      await getMappedAgency(organizationDetailState.getState().id);
      handleApiSuccessAlert("Agency updated successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
  }
};
