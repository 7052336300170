import { useEffect } from "react";
import { NotificationsAndSoundTemplate } from "../../template/profileSettings/notificationsAndSoundTemplate";
import { currentActiveScreenState } from "../../../libs/state-management-lib";

export const NotificationsAndSound = () => {
  useEffect(() => {
    if (window.location.pathname.includes("/profilesettings")) {
      currentActiveScreenState.setState(
        "/profilesettings/notifications-and-sound"
      );
    } else {
      currentActiveScreenState.setState("/settings/organization");
    }
  }, []);
  return (
    <>
      <NotificationsAndSoundTemplate />
    </>
  );
};
