import { Button, SxProps, Theme } from "@mui/material";

interface IContainedButton {
  text: string;
  sx: SxProps<Theme>;
  handleClick: () => void;
  disabled?: boolean;
  startIcon?:any
}

export const ContainedButton = (props: IContainedButton) => {
  return (
    <>
      <Button
        disabled={props.disabled}
        size="small"
        variant="contained"
        startIcon={props.startIcon}
        sx={{         
          ...props.sx,
          background: !!props.disabled && props.disabled ? "#ABB2BA !important" : "#0D80F2 !important",
          borderRadius: "8px",
          textTransform: "none",
          color: "#FFFFFF !important",
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
          boxShadow:"none !important",
        }}
        onClick={props.handleClick}
      >
        {props.text}
      </Button>
    </>
  );
};

export const ContainedDeleteButton = (props: IContainedButton) => {
  return (
    <>
      <Button
        disabled={props.disabled}
        size="small"
        variant="contained"
        startIcon={props.startIcon}
        sx={{         
          ...props.sx,
          background: !!props.disabled && props.disabled ? "#ABB2BA !important" : "#F2460D !important",
          borderRadius: "8px",
          textTransform: "none",
          color: "#FFFFFF !important",
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
          boxShadow:"none !important",
        }}
        onClick={props.handleClick}
      >
        {props.text}
      </Button>
    </>
  );
};
