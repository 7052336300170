import { Box, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { PlayCircleIcon } from "../icons/icon";

interface ISettingsPlayBox {
    text: string;
    duration: string;
}

const SettingsPlayBox = (props: ISettingsPlayBox) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          width: "42%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: "16px 24px 16px 24px",
          gap: "10px",
          borderRadius: "8px",
          backgroundColor: "#FEF8F5",
          marginTop: "20px",
          marginLeft: "20px",
        }}
      >
        <PlayCircleIcon
          sx={{
            color: "#F7906E",
          }}
        />
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "18px",
              alignItems: "center",
              color: "#F7906E",
            }}
          >
           {props.text}
          </Typography>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "12px",
              alignItems: "center",
              color: "#F7906E",
              lineHeight: "29px",
               paddingLeft:"5px"
            }}
          >
            {props.duration}
          </Typography>
        </Box>
        <CloseOutlinedIcon
          sx={{
            color: "#F7906E",
            top: "5.2px",
            left: "5.2px",
            alignItems: "flex-end",
          }}
        />
      </Box>
    </Box>
  );
};

export default SettingsPlayBox;
