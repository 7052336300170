import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { ConfirmationTitle, StaffDetail } from "../model-lib";
import { emptyStaffDetail } from "../common-lib";

export const staffDetailState = create<StaffDetail>()(
  devtools(
    persist((set, get) => emptyStaffDetail, {
      name: "staffDetailState",
    })
  )
);

interface IStaffDetailListState {
  staffDetailList: StaffDetail[];
}

export const staffDetailListState = create<IStaffDetailListState>()(
  devtools(
    persist((set, get) => ({ staffDetailList: [] }), {
      name: "staffDetailListState",
    })
  )
);

export const confirmationPopupTitleState = create<ConfirmationTitle>()(
  devtools(
    persist((set, get) => ({ title: "" }), {
      name: "confirmationPopupTitleState",
    })
  )
);

export const calendarStaffTabValueState = create<number>()(
  devtools(
    persist((set, get) => 0, {
      name: "calendarStaffTabValueState",
    })
  )
);
