import {
  Box,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  DropdownWithBorder,
  FormContainerBox,
  FormPlacementBox,
  LableTextFieldBox,
  PlatformTextField,
  TextFieldPlacementBox,
  Typography12_600,
  Typography16_400,
  Typography18_600,
} from "../../atom";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  GenericMasterData,
  LocationDetail,
  TimeZoneDetail,
} from "../../../libs/model-lib";
import {
  addressListState,
  loadingIconState,
  locationClearedState,
  locationDetailState,
  locationTypeListState,
  organizationDetailState,
  timeZoneListState,
  weekDayStartListState,
} from "../../../libs/state-management-lib";
import { useShallow } from "zustand/react/shallow";
import { FormCheckbox } from "../../atom/checkbox/formCheckbox";
import { getErrorDetail } from "../../../libs/common-lib";
import { AutoCompleteTextInput } from "../../atom/textField/autoComplete";
import {
  getAddressValue,
  getLocationTypeList,
  getTimeZoneList,
  getWeekDayStartList,
} from "../../../libs/services-lib";
import {
  LocationDetailContext,
  useLocationDetailContext,
} from "../../../libs/context-lib/locationDetailContext";

interface ILocationDetailForm {
  errorMessage: any[];
}

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

export const LocationDetailForm = (props: ILocationDetailForm) => {
  const { locationDetail, setLocationDetail } = useLocationDetailContext();

  const [locationTypeList, setLocationTypeList] = useState<GenericMasterData[]>(
    locationTypeListState.getState().locationTypeList
  );
  const [weekDayStartList, setWeekDayStartList] = useState<GenericMasterData[]>(
    weekDayStartListState.getState().weekDayStartList
  );

  const [timeZoneList, setTimeZoneList] = useState<TimeZoneDetail[]>(
    timeZoneListState.getState().timeZoneList
  );
  const [addressValue, setAddressValue] = useState<readonly any[]>([]);
  const [sameAsOrgName, setSameAsOrgName] = useState<boolean>(false);
  const [sameAsOrgAddress, setSameAsOrgAddress] = useState<boolean>(false);

  useLayoutEffect(() => {
    const locationTypeListStateSub = locationTypeListState.subscribe(
      async (state) => {
        setLocationTypeList(state.locationTypeList);
      }
    );

    const weekDayStartListStateSub = weekDayStartListState.subscribe(
      async (state) => {
        setWeekDayStartList(state.weekDayStartList);
      }
    );

    const timeZoneListStateSub = timeZoneListState.subscribe(async (state) => {
      setTimeZoneList(state.timeZoneList);
    });

    const addressListStateSub = addressListState.subscribe(async (state) => {
      setAddressValue(state.addressList);
    });

    const locationClearedStateSub = locationClearedState.subscribe(
      async (state) => {
        setSameAsOrgName(false);
        setSameAsOrgAddress(false);
      }
    );

    return () => {
      locationTypeListStateSub();
      weekDayStartListStateSub();
      timeZoneListStateSub();
      addressListStateSub();
      locationClearedStateSub();
    };
  }, []);

  useEffect(() => {
    (async function () {
      loadingIconState.setState(true);
      await Promise.all([
        getLocationTypeList(),
        getWeekDayStartList(),
        getTimeZoneList(),
      ]);
      loadingIconState.setState(false);
    })();
  }, []);

  const handleSameOrgNameChecked = (e: any) => {
    if (e.target.checked) {
      setLocationDetail({
        ...locationDetail,
        name: organizationDetailState.getState().name,
      });
      setSameAsOrgName(true);
    } else if (!e.target.checked) {
      setLocationDetail({
        ...locationDetail,
        name: "",
      });

      setSameAsOrgName(false);
    }
  };

  const handleSameOrgAddressChecked = (e: any) => {
    let organizationDetail = organizationDetailState.getState();
    if (e.target.checked) {
      setLocationDetail({
        ...locationDetail,
        address_1: organizationDetail.address_1,
        address_2: organizationDetail.address_2,
        city: organizationDetail.city,
        state: organizationDetail.state,
        postal_code: organizationDetail.postal_code,
      });
      setSameAsOrgAddress(true);
    } else if (!e.target.checked) {
      setLocationDetail({
        ...locationDetail,
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        postal_code: "",
      });
      setSameAsOrgAddress(false);
    }
  };

  const handlePostalcodeChange = (event: any) => {
    const inputValue = event.target.value;
    const postalCode = inputValue.replace(/\D/g, "");
    if (postalCode.length <= 5) {
      setLocationDetail({
        ...locationDetail,
        postal_code: postalCode,
      });
    }
  };

  const handleAddressAutopopulate = async (event: any, value: any) => {
    if (event.target.value.length >= 3) {
      await getAddressValue(event.target.value);
    }
  };

  const handleAutocompleteChange = (event: any, value: any) => {
    if (!!value) {
      setLocationDetail({
        ...locationDetail,
        address_1: `${!!value.streetNumber ? value.streetNumber : ""} ${
          !!value.streetName ? value.streetName : ""
        }`,
        address_2: "",
        city: `${!!value.municipality ? value.municipality : ""}`,
        state: `${!!value.countrySubdivision ? value.countrySubdivision : ""}`,
        postal_code: `${!!value.postalCode ? value.postalCode : ""}`,
      });
    }
  };

  const handleClearLocationType = ()=>{
    setLocationDetail({
      ...locationDetail,
      location_type: 0,
    });
  }

  const handleClearTimezone = ()=>{
    setLocationDetail({
      ...locationDetail,
      timezone: "",
    });
  }

  return (
    <>
      <FormPlacementBox>
        <FormContainerBox>
          <Box sx={{ width: "100%", gap: "10px" }}>
            <Typography18_600 text="Location details" color="#17191C" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FormCheckbox
              sx={{
                paddingLeft: "0px !important",
              }}
              onChange={handleSameOrgNameChecked}
              checked={sameAsOrgName}
            />
            <Typography16_400
              text="Same as organization name"
              color="#17191C"
            />
          </Box>

          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Name" color="#737F8C" required />
                <PlatformTextField
                  placeholder={"Enter location name"}
                  value={locationDetail.name}
                  handleChange={(e: any) => {
                    setLocationDetail({
                      ...locationDetail,
                      name: e.target.value,
                    });
                    setSameAsOrgName(false);
                  }}
                  errorMessage={getErrorDetail("name", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600
                  text="Location type"
                  color="#737F8C"
                  required
                />
                <DropdownWithBorder
                  value={locationDetail.location_type}
                  handleChange={(e: any) => {
                    setLocationDetail({
                      ...locationDetail,
                      location_type: e.target.value,
                    });
                  }}
                  handleClear={handleClearLocationType}
                  errorMessage={getErrorDetail(
                    "location_type",
                    props.errorMessage
                  )}
                >
                  <MenuItem value={0} selected disabled>
                    {" "}
                    Select location type
                  </MenuItem>
                  {locationTypeList.map((cc) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      <Typography16_400 text={cc.name} color="#031A30" />
                    </MenuItem>
                  ))}
                </DropdownWithBorder>
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Time zone" color="#737F8C" required />
                <DropdownWithBorder
                  value={locationDetail.timezone}
                  handleChange={(e: any) => {
                    setLocationDetail({
                      ...locationDetail,
                      timezone: e.target.value,
                    });
                  }}
                  handleClear={handleClearTimezone}
                  errorMessage={getErrorDetail("timezone", props.errorMessage)}
                >
                  <MenuItem value={""} selected disabled>
                    {" "}
                    Select time zone
                  </MenuItem>
                  {timeZoneList.map((cc) => (
                    <MenuItem value={cc.originalName} key={cc.originalName}>
                      <Typography16_400
                        text={cc.originalName}
                        color="#031A30"
                      />
                    </MenuItem>
                  ))}
                </DropdownWithBorder>
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Address" color="#17191C" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FormCheckbox
              sx={{
                paddingLeft: "0px !important",
              }}
              onChange={handleSameOrgAddressChecked}
              checked={sameAsOrgAddress}
            />

            <Typography16_400
              text="Same as organization address"
              color="#17191C"
            />
          </Box>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600
                  text="Home address 1"
                  color="#737F8C"
                  required
                />
                <AutoCompleteTextInput
                  value={locationDetail.address_1}
                  onChange={handleAutocompleteChange}
                  onInputChange={(event: any, newInputValue: any) => {
                    handleAddressAutopopulate(event, newInputValue);
                    setLocationDetail({
                      ...locationDetail,
                      address_1: event.target.value,
                    });
                    setSameAsOrgAddress(false);
                  }}
                  options={addressValue}
                  getOptionLabel={(option: any) =>
                    typeof option === "string" ? option : option.freeformAddress
                  }
                  filterOptions={(x: any) => x}
                  noOptionsText="No locations"
                  label={""}
                  placeholder={"Enter home address 1"}
                  errorMessage={getErrorDetail("address_1", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Home address 2" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter home address 2"}
                  value={locationDetail.address_2}
                  handleChange={(e: any) => {
                    setLocationDetail({
                      ...locationDetail,
                      address_2: !!e.target.value ? e.target.value : "",
                    });
                  }}
                  errorMessage={getErrorDetail("address_2", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="City" color="#737F8C" required />
                <PlatformTextField
                  placeholder={"Enter city"}
                  value={locationDetail.city}
                  handleChange={(e: any) => {
                    setLocationDetail({
                      ...locationDetail,
                      city: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("city", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "25%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="State" color="#737F8C" required />
                <PlatformTextField
                  placeholder={"Enter state"}
                  value={locationDetail.state}
                  handleChange={(e: any) => {
                    setLocationDetail({
                      ...locationDetail,
                      state: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("state", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "25%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Postal code" color="#737F8C" required />
                <PlatformTextField
                  placeholder={"Enter postal code"}
                  value={locationDetail.postal_code}
                  handleChange={handlePostalcodeChange}
                  errorMessage={getErrorDetail(
                    "postal_code",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Location work week start" color="#17191C" />
          </Box>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {weekDayStartList.map((cc) => (
              <FormControlLabel
                sx={{
                  alignContent: "center",
                  gap: "8px",
                }}
                value={cc.id}
                key={cc.id}
                onChange={(e: any) => {
                  setLocationDetail({
                    ...locationDetail,
                    week_start_day: parseInt(e.target.value),
                  });
                }}
                checked={locationDetail.week_start_day == cc.id}
                control={
                  <Radio
                    size="medium"
                    sx={{
                      color: "#0D80F2",
                      "&.Mui-checked": {
                        color: "#0D80F2",
                      },
                      "&:hover": {
                        color: "#0A66C2",
                      },
                    }}
                  />
                }
                label={<Typography16_400 text={cc.name} color="#17191C" />}
              />
            ))}
          </RadioGroup>
        </FormContainerBox>
      </FormPlacementBox>
    </>
  );
};
