import { axiosInstance, emptySignUpDetail } from "../common-lib";
import { errorAlertState, errorMessageState } from "../state-management-lib";

const platform_base_url = `${process.env.REACT_APP_PLATFORM_URL}/api/Invitation`;

export const getDecodeInvitationById = async (enCodeValue: string) => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/DecodeInvitationById?encryptedValue=${enCodeValue}`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
     return response.data.data;
    }

    return emptySignUpDetail;
  } catch (error:any) {
    errorAlertState.setState(true);
    errorMessageState.setState({errorMessage: error.response.data.errors[0]?.message})
    errorMessageState.setState({errorMessage: error.response.data.data})
  }
};

export const reInitiateTenantInvitation = async (invitationId: string) => {
  try {
    let response = await axiosInstance.get(
      `${platform_base_url}/ReInitiateTenantInvitation?invitaionId=${invitationId}`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
    }
  } catch (error) {}
};
