import { TextField, Typography } from "@mui/material";

interface ICalendarTextAreaField {
  Label: string;
  handleChange: (e: any) => void;
  value: string;
  disabled?: boolean;

}

export const CalendarTextAreaField = (props: ICalendarTextAreaField) => {
  return (
    <Typography 
    sx={{
      width:"602px",
      ".MuiTypography-root":{
        fontFamily:"Roboto",
      },
    }}>
    <textarea
      style={{
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",        
        boxSizing: "border-box",
        width: "100%",
        background: "#F5FAFE",
        border: "1px solid #CFE6FC",
        borderRadius: "8px",
        outline:0,
        fontFamily: "Roboto !important",        
      }}
      className="textarea"
      placeholder={props.Label}
      rows={4}
      value={props.value}
      onChange={props.handleChange}
      disabled={!!props.disabled && props.disabled ? true : false}
    ></textarea>
    </Typography>
  );
};
