import { Box, MenuItem, Typography } from "@mui/material";
import {
  CloseIcon,
  ContainedButton,
  LableTextFieldBox,
  OutlinedButton,
} from "../../atom";
import { TextFieldLableTypography } from "../../atom/typography/textFieldLableTypography";
import { CalendarDropDown } from "../../atom/dropdown/calendarDropdown";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import { Dayjs } from "dayjs";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

interface ICalendarAdvancedFilterModal {
  handleCancelAdvancedFilterClick: () => void;
}

export const CalendarAdvancedFilterModal = (
  props: ICalendarAdvancedFilterModal
) => {
  const [shiftDate, setShiftDate] = useState<Dayjs | null>(null);

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          width: "26%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          top: "22%",
          left: "40%",
          borderRadius: "8px",
          border: "1px solid #E3E5E8",
          boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.1)",
          background: "#FFFFFF",
          zIndex: "1100",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            width: "calc(100%-32px)",
            height: "calc(10%-32px)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "16px",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              width: "90%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "24px",
                color: "#17191C",
              }}
            >
              Advanced filters
            </Typography>
          </Box>
          <Box
            sx={{
              width: "10%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={props.handleCancelAdvancedFilterClick}
          >
            <CloseIcon sx={{ color: "#0D80F2" }} />
          </Box>
        </Box>
        {/* Body */}
        <Box
          sx={{
            width: "calc(100%-32px)",
            height: "calc(80%-32px)",
            display: "flex",
            flexDirection: "column",
            padding: "16px",
            gap: "32px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              color: "#737F8C",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LableTextFieldBox>
              <TextFieldLableTypography text="Roles" required={false} />
            </LableTextFieldBox>
            <CalendarDropDown
              handleChange={function (e: any): void {}}
              value={1}
              sx={{
                ".MuiInputBase-input": {
                  color: "#6EB2F7  !important",
                },
              }}
            >
              <MenuItem value={1}> Select role </MenuItem>
            </CalendarDropDown>
          </Box>
          <Box
            sx={{
              width: "100%",
              color: "#737F8C",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LableTextFieldBox>
              <TextFieldLableTypography text="Employees" required={false} />
            </LableTextFieldBox>
            <CalendarDropDown
              sx={{
                ".MuiInputBase-input": {
                  color: "#6EB2F7  !important",
                },
              }}
              handleChange={function (e: any): void {}}
              value={1}
            >
              <MenuItem value={1}> Select employee </MenuItem>
            </CalendarDropDown>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                color: "#737F8C",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LableTextFieldBox>
                <TextFieldLableTypography text="Group by" required={false} />
              </LableTextFieldBox>
              <CalendarDropDown
                sx={{
                  ".MuiInputBase-input": {
                    color: "#6EB2F7  !important",
                  },
                }}
                handleChange={function (e: any): void {}}
                value={1}
              >
                <MenuItem value={1}> Select group type </MenuItem>
              </CalendarDropDown>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#737F8C",
                justifyContent: "center",
              }}
            >
              <LableTextFieldBox>
                <TextFieldLableTypography text="Date Range" required={false} />
              </LableTextFieldBox>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slots={{
                    openPickerIcon: CalendarTodayOutlinedIcon,
                  }}
                  sx={{
                    justifyContent: "center",
                    background: "#F5FAFE",
                    width: "100%",
                    height: "40px !important",
                    border: "1px solid #F1F2F4",
                    ".MuiInputBase-input": {
                      padding: "8.5px 14px !important",
                      color: "#6EB2F7  !important",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #CFE5FC",
                      borderRadius: "8px",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #CFE5FC",
                      borderRadius: "8px",
                    },
                    "& .MuiInputLabel-root": { display: "none" },
                    "& .MuiIconButton-root": {
                      color: "#0D80F2 !important",
                    },
                    Input: {
                      "&::placeholder": {
                        color: "#6EB2F7",
                        opacity: "5",
                      },
                    },
                  }}
                  slotProps={{
                    textField: {
                      inputProps: {
                        readOnly: true,
                      },
                    },
                  }}
                  format="MM.DD.YYYY"
                  onChange={(newValue) => setShiftDate(newValue)}
                  value={shiftDate}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#737F8C",
                justifyContent: "center",
              }}
            >
              <LableTextFieldBox>
                <TextFieldLableTypography text="Time Period" required={false} />
              </LableTextFieldBox>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  sx={{
                    justifyContent: "center",
                    background: "#F5FAFE",
                    width: "100%",
                    height: "40px !important",
                    border: "1px solid #F1F2F4",
                    ".MuiInputBase-input": {
                      padding: "8.5px 14px !important",
                      color: "#6EB2F7  !important",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #CFE5FC",
                      borderRadius: "8px",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #CFE5FC",
                      borderRadius: "8px",
                    },
                    "& .MuiInputLabel-root": { display: "none" },
                    "& .MuiIconButton-root": {
                      color: "#0D80F2 !important",
                    },
                    Input: {
                      "&::placeholder": {
                        color: "#6EB2F7",
                        opacity: "5",
                      },
                    },
                  }}
                  format="hh:MM A"
                  onChange={(newValue) => setShiftDate(newValue)}
                  value={shiftDate}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
        {/* Footer Button */}
        <Box
          sx={{
            width: "calc(100%-32px)",
            height: "calc(10%-32px)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <Box
            sx={{
              width: "30%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <OutlinedButton
              disabled
              text={"Reset"}
              handleClick={props.handleCancelAdvancedFilterClick}
              sx={{ height: "40px" }}
            />
          </Box>
          <Box
            sx={{
              width: "70%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <OutlinedButton
              text={"Cancel"}
              handleClick={props.handleCancelAdvancedFilterClick}
              sx={{ height: "40px" }}
            />
            <ContainedButton
              disabled
              text={"Apply"}
              handleClick={function (): void {}}
              sx={{ height: "40px" }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
