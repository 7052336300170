import { Avatar, Box } from "@mui/material";
import {
  ContainedButton,
  OutlinedButton,
  PhoneNumberTextField,
  PlatformTextField,
  Typography12_600,
  Typography16_400,
  Typography18_600,
} from "../../atom";

export const ProfileDetailsSetting = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "calc(100%-48px)",
            height: "91% ",
            display: "flex",
            flexDirection: "column",
            padding: "24px 24px 0px 24px",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              width: "42%",
              minWidth:"598px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px",
              padding: "16px",
              gap: "14px",
              border: "1px solid #F1F2F4",
            }}
          >
            <Typography18_600 text={"Personal details"} color="#17191C" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                gap: "16px",
              }}
            >
              <Avatar
                sx={{
                  height: "80px",
                  width: "80px",
                  borderRadius: "16px",
                  border: "1px solid #E3E5E8",
                }}
                variant="square"
              ></Avatar>
              <OutlinedButton
                text={"Replace image"}
                sx={{ height: "40px", padding: "8px 16px", minWidth:"138px" }}
                handleClick={function (): void {
                  throw new Error("Function not implemented.");
                }}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                minWidth:"100%",
                display: "flex",
                flexDirection: "row",
                gap: "12px",
              }}
            >
              <Box sx={{ width: "50%" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                  }}
                >
                  <Typography12_600 text="First name" color="#737F8C" />
                  <PlatformTextField
                    placeholder={"Enter first name"}
                    value={""}
                    handleChange={(e: any) => {}}
                  />
                </Box>
              </Box>
              <Box sx={{ width: "50%" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                  }}
                >
                  <Typography12_600 text="Last name" color="#737F8C" />
                  <PlatformTextField
                    placeholder={"Enter last name"}
                    value={""}
                    handleChange={(e: any) => {}}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <Typography12_600 text="Phone number" color="#737F8C" />
              <PhoneNumberTextField
                placeholder="Enter phone number"
                value={""}
                handleChange={(e: any) => {}}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <Typography12_600 text="Email" color="#737F8C" />
              <PlatformTextField
                placeholder={"Enter email"}
                value={""}
                handleChange={(e: any) => {}}
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: "42%",
              minWidth:"598px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px",
              padding: "16px",
              gap: "14px",
              border: "1px solid #F1F2F4",
            }}
          >
            <Typography18_600 text={"Professional details"} color="#17191C" />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <Typography12_600 text="Job title" color="#737F8C" />
              <Typography16_400 text={"Staffing Coordinator"} color="#031A30" />
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <Typography12_600 text="Roles" color="#737F8C" />
              <Typography16_400 text={"Staffing"} color="#031A30" />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <Typography12_600 text="Locations" color="#737F8C" />
              <Typography16_400
                text={"Hill Valley Gig Harbor"}
                color="#031A30"
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <Typography12_600 text="Departments" color="#737F8C" />
              <Typography16_400
                text={
                  "General medicine, Nursing, Obstetrics and Gynecology     "
                }
                color="#031A30"
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "calc(100%-44px)",
            height: "9%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0px 16px 0px 28px",
            gap: "16px",
            borderTop: "1px solid #CFE5FC",
          }}
        >
          <OutlinedButton
            text={"Cancel"}
            sx={{ height: "40px", width:"82px" }}
            handleClick={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <ContainedButton
            text={"Save changes"}
            sx={{ height: "40px", padding: "0px 16px", minWidth:"132px" }}
            handleClick={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </Box>
      </Box>
    </>
  );
};
