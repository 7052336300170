import { Box, Typography } from "@mui/material";
import { CalendarDot } from "../../icons/icon";
import { orgRoleListState } from "../../../../libs/state-management-lib";
import { Shift } from "../../../../libs/model-lib";
import { format } from "date-fns";
import { AssignedShiftChip, CancelledShiftChip, OpenShiftChip, PendingShiftChip } from "../../chip/calendar/ShiftStatusChip";
import { convertMinutestoHour } from "../../../../libs/common-lib";

interface ICalendarShiftBox {
  shiftDetails: Shift;
  handleShiftClick: (shiftDetails: Shift) => void;
}

export const CalendarShiftBox = (props: ICalendarShiftBox) => {
  const getShiftBoxColor = (shiftType: number) => {
    if (shiftType === 1) {
      return "#A7CAF2";
    }

    if (shiftType === 2) {
      return "#F9B176";
    }

    if (shiftType === 3) {
      return "#C7A7EF";
    }
  };

  const getShiftBoxHoverColor = (shiftType: number) => {
    if (shiftType === 1) {
      return "#CDE5FF";
    }

    if (shiftType === 2) {
      return "#f9be76";
    }

    if (shiftType === 3) {
      return "#e4d5f7";
    }
  };

  const getRoleName = (roleIds: number[]) => {
    let roleName = "";
    orgRoleListState.getState().orgRoleList.forEach((element) => {
      if (roleIds.find((cc) => cc === element.id)) {
        roleName = !!roleName ? roleName + ", " : "";
        roleName = roleName + element.abbreviation;
      }
    });

    return roleName;
  };

  const getShiftBoxStatusChip = (shiftStatus: number) => {
    if(shiftStatus === 1) {
      return <AssignedShiftChip />
    }

    if(shiftStatus === 2) {
      return <OpenShiftChip />
    }

    if(shiftStatus === 3) {
      return <OpenShiftChip />
    }

    if(shiftStatus === 4) {
      return <CancelledShiftChip />
    }

    if(shiftStatus === 5) {
      return <PendingShiftChip />
    }
  }

  const handleShiftClick = () => {
    props.handleShiftClick(props.shiftDetails);
  }

  return (
    <>
      <Box
        sx={{
          width: "calc(100% - 32px)",
          fontSize: "12px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#CFE5FC",
          cursor: "pointer",
          height: "100%",
          borderBottom: "1px solid #E7F2FE",
        }}
        onClick={handleShiftClick}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            borderLeft: `4px solid ${getShiftBoxColor(
              props.shiftDetails.shift_type
            )}`,
            background: "#FFFFFF",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.05)",
            borderRadius: "4px",
            minHeight:"64px",
            justifyContent: "space-between",
            "&:hover": {
              background: `${getShiftBoxHoverColor(
                props.shiftDetails.shift_type
              )}`,
            },
          }}
        >
          <Box
            sx={{
              width: "calc(100% - 8px)",
              display: "flex",
              flexDirection: "row",
              borderBottom: "1px solid #F1F2F4",
              padding: "4px",
              justifyContent: "space-between",
              alignItems: "center",
              height: "50%"
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
              <Typography
                sx={{ fontSize: "10px", fontWeight: "600", color: "#737F8C" }}
              >
                {getRoleName(props.shiftDetails.role_ids)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "4px",
                alignItems: "center",
                justifyContent: "center",
                width: "50%"
              }}
            >
              {getShiftBoxStatusChip(props.shiftDetails.shift_status)}
              {/* <CalendarDot
                sx={{ width: "16px", height: "16px", color: "#0D80F2" }}
              /> */}
            </Box>
          </Box>
          <Box
            sx={{
              width: "calc(100% - 8px)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "4px",
              height: "50%"
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{ fontSize: "12px", fontWeight: "400", color: "#737F8C" }}
              >
                {`${format(props.shiftDetails.start_date, "h")}:${format(
                  props.shiftDetails.start_date,
                  "mm"
                )} ${format(props.shiftDetails.start_date, "aaa")} - ${format(
                  props.shiftDetails.end_date,
                  "h"
                )}:${format(props.shiftDetails.end_date, "mm")} ${format(
                  props.shiftDetails.end_date,
                  "aaa"
                )}`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{ fontSize: "12px", fontWeight: "400", color: "#737F8C" }}
              >
                {convertMinutestoHour(props.shiftDetails.shift_duration)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
