import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Typography18_600 } from "../typography";

interface IActiveConfirmationPopup {
  title: string;
  content: string;
  open: boolean;
  children: ReactNode;
  handleClick?: () => void;
}
export const ActiveConfirmationPopup = (props: IActiveConfirmationPopup) => {
  return (
    <>
      <Dialog
        maxWidth={"sm"}
        open={props.open}
        sx={{
          maxHeight: "100%",
          alignItems: "flex-start",
          border: "1px solid #E3E5E8",
          filter: "drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.1))",

          "& .MuiDialog-paper": {
            width: "28%",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            color: "#17191C",
          }}
          id="alert-dialog-title"
        >
          {props.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.handleClick}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#0D80F2",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#17191C",
              }}
            >
              {props.content}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          {props.children}
        </DialogActions>
      </Dialog>
    </>
  );
};

interface IInActiveConfirmationPopup {
  title: string;
  content: string;
  open: boolean;
  children: ReactNode;
  handleClick?: () => void;
}
export const InActiveConfirmationPopup = (
  props: IInActiveConfirmationPopup
) => {
  return (
    <>
      <Dialog
        maxWidth={"sm"}
        open={props.open}
        sx={{
          maxHeight: "100%",
          alignItems: "flex-start",
          border: "1px solid #E3E5E8",
          filter: "drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.1))",

          "& .MuiDialog-paper": {
            width: "28%",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            color: "#17191C",
          }}
          id="alert-dialog-title"
        >
          {props.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.handleClick}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#0D80F2",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#17191C",
              }}
            >
              {props.content}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          {props.children}
        </DialogActions>
      </Dialog>
    </>
  );
};

interface IDeleteConfirmationPopup {
  title: string;
  content: string;
  open: boolean;
  children: ReactNode;
  handleClick?: () => void;
}
export const DeleteConfirmationPopup = (props: IDeleteConfirmationPopup) => {
  return (
    <>
      <Dialog
        maxWidth={"sm"}
        open={props.open}
        sx={{
          maxHeight: "100%",
          alignItems: "flex-start",
          border: "1px solid #E3E5E8",
          filter: "drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.1))",

          "& .MuiDialog-paper": {
            width: "28%",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            color: "#17191C",
          }}
          id="alert-dialog-title"
        >
          {props.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.handleClick}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#0D80F2",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#17191C",
              }}
            >
              {props.content}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>{props.children}</DialogActions>
      </Dialog>
    </>
  );
};
