import { Box } from "@mui/material";
import { CostsIcon, PlusIcon } from "../../icons/icon";

interface ICalendarCostBox {
  handleAddShiftClick:() => void;
}

export const CalendarCostBox = (props: ICalendarCostBox) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          background: "#FFFFFF",
          gap: "8px",
          padding: "16px 8px",
          borderTop:"1px solid #CFE5FC",
        }}
      >
        {/* <Box>
        <CostsIcon sx={{color:"#0D80F2"}} />
        </Box> */}
        
        <Box sx={{
          cursor:"pointer"
        }} onClick={props.handleAddShiftClick}>
        <PlusIcon sx={{color:"#0D80F2"}}  />
        </Box>
        
      </Box>
    </>
  );
};
