import {
  AgencyMapping,
  CustomTag,
  DepartmentDetail,
  LocationDepartmentDetail,
  LocationDetail,
  OrgRole,
  OrganizationDetail,
  OrganizationOnboarding,
  OrganizationRole,
  ShareOpenShifts,
  ShareShift,
  Shift,
  ShiftDetail,
  ShiftRotation,
  ShiftSeries,
  SignUpDetail,
  StaffDetail,
  UnitDetail,
} from "../model-lib";
import { AgencyDetail } from "../model-lib/agencyDetail";
import { EmptyGuid } from "./constant";

export const EmptyOrganizationDetail: OrganizationDetail = {
  id: "",
  name: "",
  npi: "",
  definitive_id: "",
  medicare_provider_number: "",
  number_of_staffed_beds: 0,
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  postal_code: "",
  contact_name: "",
  contact_phone_number: "",
  contact_email: "",
  contact_title: "",
  tenant_id: "",
  number_of_employee: 0,
  primary_phone_number: "",
  website: "",
  is_active: false,
  staff_types: [],
  invitation_id: "",
  contact_person_email: "",
  facility_type: 0,
};

export const emptyDepartmentDetail: DepartmentDetail = {
  id: "",
  name: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  zip_code: "",
  number_of_beds: 0,
  department_location: "",
  location_Id: "",
  description: "",
  department_type: 0,
  org_role_ids: [],
  is_active: false,
  staff_type_ids: [],
  departmentContactDetails: {
    id: "",
    name: "",
    title: "",
    phone_number: "",
    email: "",
    department_id: "",
  },
};

export const EmptyLocationDetail: LocationDetail = {
  id: "",
  name: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  postal_code: "",
  location_type: 0,
  org_id: "",
  is_active: true,
  timezone: "",
  week_start_day: 0,
  locationContactDetails: {
    id: "",
    email: "",
    is_active: true,
    location_id: "",
    name: "",
    phone_number: "",
    title: "",
  },
};

export const emptyOrgRole: OrgRole = {
  id: 0,
  role_name: "",
  org_id: "",
  role_type: 0,
  primary_qualification: 0,
  abbreviation: "",
  location_id: [],
  department_id: [],
  sub_department_id: [],
  is_active: false,
};

export const emptyShiftDetail: ShiftDetail = {
  id: EmptyGuid,
  shift_id: EmptyGuid,
  description: "",
  additional_requirements: "",
  internal_notes: "",
  vendor_notes: "",
};

export const emptyShift: Shift = {
  id: EmptyGuid,
  shift_type: 0,
  start_date: new Date(),
  end_date: new Date(),
  start_date_string: "",
  end_date_string: "",
  shift_status: 2,
  shift_duration: 0,
  department_id: EmptyGuid,
  sub_department_id: null,
  is_active: false,
  shift_break_types: [],
  type_of_shift: 0,
  role_ids: [],
  staff_type_ids: [],
  shiftDetails: emptyShiftDetail,
  assigned_staff_detail: null,
  staff_detail_list: [],
  custom_tag_id: null,
  custom_tag_name: '',
  number_of_shifts: 1,
  orgId: EmptyGuid,
  is_publish:false
};

export const emptyUnitDetail: UnitDetail = {
  id: EmptyGuid,
  name: "",
  department_id: "",
  is_active: false,
};

export const emptyStaffDetail: StaffDetail = {
  id: EmptyGuid,
  org_id: EmptyGuid,
  agency_id: EmptyGuid,
  first_name: "",
  last_name: "",
  mobile: "",
  email: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  zipcode: "",
  ssn: "",
  npi: "",
  date_of_birth: "",
  hourly_pay_rate: 0,
  overtime_pay_rate: 0,
  contracted_hours: 0,
  staff_type: 0,
  role_ids: [],
  is_active: true,
  created_on: new Date(),
  staffLocationMappings: [],
  staffWorkingHours: null,
  agencyDetails: null,
};

export const emptyLocationDepartmentDetail: LocationDepartmentDetail = {
  department_id: EmptyGuid,
  department_name: "",
  location_id: EmptyGuid,
  location_name: "",
};

export const emptyAgencyMappingDetail: AgencyMapping = {
  id: EmptyGuid,
  agency_id: EmptyGuid,
  agency_name: "",
  org_id: EmptyGuid,
  is_active: true,
  location_ids: [],
  department_ids: [],
};

export const emptyShiftSeries: ShiftSeries = {
  id: EmptyGuid,
  department_ids: [],
  series_start_date: "",
  series_end_date: "",
  recurrence_period: 0,
  is_same_staff_member: false,
  no_of_shifts: 0,
  role_ids: [],
  staff_types: [],
  break_types: [],
  staff_id: EmptyGuid,
  shiftSeriesDays: [],
  orgId: EmptyGuid,
  sub_department_ids:[],
  notes:"",
};

export const emptyRoleDetail: OrganizationRole = {
  id: 0,
  role_name: "",
  org_id: "",
  role_type: 0,
  qualifications: [],
  abbreviation: "",
  is_active: true,
  location_id: [],
  department_id: [],
  sub_department_id: [],
};

export const emptyAgencyDetail: AgencyDetail = {
  id: EmptyGuid,
  name: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  postal_code: "",
  type: 0,
  line_of_business: [],
  job_qualifications: [],
  tenant_id: EmptyGuid,
  is_active: true,
  invitation_id: EmptyGuid,
  agency_mapping_detail: null,
  contact_person_details: {
    agency_id: EmptyGuid,
    email: "",
    job_title: "",
    primary_contact_full_name: "",
    primary_phone_number: "",
    secondary_number: "",
  },
  agency_phone_number: "",
  dba: "",
  employer_identification_number: "",
  registration_date: "",
  website: "",
  working_week_start: 0,
  logo_url: "",
};

export const emptySignUpDetail: SignUpDetail = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  send_date: "",
  tenant_type: 0,
  role_id: "",
  status: "",
  tenant_id: null,
};

export const emptyOrgOnboardingDetail: OrganizationOnboarding = {
  organizationDetails: {
    id: EmptyGuid,
    name: "",
    npi: "",
    definitive_id: "",
    medicare_provider_number: "",
    number_of_staffed_beds: 0,
    contact_name: "",
    contact_phone_number: "",
    contact_email: "",
    tenant_id: null,
    number_of_employee: 0,
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postal_code: "",
    website: "",
    contact_title: "",
    primary_phone_number: "",
    staff_types: [],
    is_active: true,
    invitation_id: null,
    contact_person_email: "",
    facility_type: 0,
  },
  tenantDetails: {
    id: EmptyGuid,
    type: 0,
    is_active: true,
  },
};

export const emptyShareShift: ShareShift = {
  from_date: "",
  to_date: "",
  location_id: "",
  department_ids: [],
  sub_department_ids: [],
  role_ids: [],
};

export const emptyShareOpenShift: ShareOpenShifts = {
  org_id: "",
  location_id:"",
  shift_ids: [],
  agency_ids: [],
};

export const emptyShiftRotation: ShiftRotation = {
  id: EmptyGuid,
  rotation_name: "",
  orgId: "",
  department_ids: [],
  rotation_start_date: "",
  rotation_end_date: "",
  is_same_staff_member: true,
  no_of_shift: 0,
  shift_start_time: "",
  shift_end_time: "",
  role_ids: [],
  staff_types: [],
  notes: "",
  save_as_preset: true,
  rotation_sequence: [],
  staff_id: "",
  sub_department_ids:[],
};

export const emptyCustomTag: CustomTag = {
  id: 0,
  org_id: EmptyGuid,
  name: "",
};