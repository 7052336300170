import { Box, Checkbox, MenuItem, Typography } from "@mui/material";
import {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { SignupTextLableTypography } from "../../atom/typography/signupTextLabelTypography";
import { SignUpTextField } from "../../atom/textField/signupTextField";
import { SignUpContainedButton } from "../../atom/buttons/signupContainedButton";
import { SignUpDropDown } from "../../atom/dropdown/signUpDropdown";
import { useNavigate } from "react-router-dom";
import {
  AgencySuccessAlert,
  ErrorAlert,
  PlatformAutoCompleteTextInput,
  PlatformLoginTextField,
  PlatformPhoneNumberTextField,
  Typography16_400,
} from "../../atom";
import {
  getDecodeInvitationById,
  reInitiateTenantInvitation,
} from "../../../libs/services-lib/signUpService";
import {
  EmptyGuid,
  emptySignUpDetail,
  getErrorDetail,
} from "../../../libs/common-lib";
import {
  GenericMasterData,
  OrganizationOnboarding,
  orgDetail,
  SignUpDetail,
  SignUpOTPDetail,
  tenantDetail,
} from "../../../libs/model-lib";
import {
  addressListState,
  errorAlertState,
  errorMessageState,
  facilityTypeListState,
  loadingIconState,
  numberOfEmployeeListState,
  organizationOnboardingState,
  successAlertState,
  userDetailstate,
} from "../../../libs/state-management-lib";
import Joi from "joi";
import {
  createUser,
  generateLoginToken,
  generateOTP,
  getAddressValue,
  getFacilityTypeeList,
  getNumberOfEmployee,
  organizationOnboarding,
} from "../../../libs/services-lib";
import { FlashOnOutlined, Style } from "@mui/icons-material";
import { FormCheckbox } from "../../atom/checkbox/formCheckbox";
import { styled } from "@material-ui/core";
import { SignupPhoneNumberTextField } from "../../atom/textField/signUpPhoneNumberTextField";

export const FacilitySignUpForms = () => {
  const navigate = useNavigate();
  const [openScreen, setOpenScreen] = useState("signUp");
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const seconds = String(countDown % 60).padStart(2, "0");
  const minutes = String(Math.floor(countDown / 60)).padStart(2, "0");
  const [signUpDetail, setSignUpDetail] = useState<SignUpDetail>({
    id: EmptyGuid,
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    send_date: "",
    tenant_type: 0,
    role_id: "",
    status: "",
    tenant_id: null,
  });
  const [errorMessage, setErrorMessage] = useState<any[]>([]);
  const timerId = useRef<any>();
  const [signUpOTP, setSignUpOTP] = useState<SignUpOTPDetail>({
    userName: "",
    password: "",
    mobileNumber: "",
    otp: "",
  });
  const [facilityDetail, setFacilityDetail] = useState<OrganizationOnboarding>(
    organizationOnboardingState.getState()
  );
  const [numberOfEmployeeList, setNumberOfEmployeeList] = useState<
    GenericMasterData[]
  >(numberOfEmployeeListState.getState().numberOfEmployeeList);
  const [addressValue, setAddressValue] = useState<readonly any[]>([]);
  const [facilityTypeList, setFacilityTypeList] = useState<GenericMasterData[]>(
    facilityTypeListState.getState().facilityTypeList
  );
  const [invitationId, setInvitationId] = useState("");
  const [facilityDirectSignup, setFacilityDirectSignup] = useState(false);
  const [facilityName, setFacilityName] = useState("");
  const [facilityNumber, setFacilityNumber] = useState("");

  useLayoutEffect(() => {
    const organizationOnboardingStateSub =
      organizationOnboardingState.subscribe(async (state) => {
        setFacilityDetail(state);
      });

    const numberOfEmployeeListStateSub = numberOfEmployeeListState.subscribe(
      async (state) => {
        setNumberOfEmployeeList(state.numberOfEmployeeList);
      }
    );

    const addressListStateSub = addressListState.subscribe(async (state) => {
      setAddressValue(state.addressList);
    });

    const facilityTypeListStateSub = facilityTypeListState.subscribe(
      async (state) => {
        setFacilityTypeList(state.facilityTypeList);
      }
    );

    return () => {
      organizationOnboardingStateSub();
      numberOfEmployeeListStateSub();
      addressListStateSub();
      facilityTypeListStateSub();
    };
  }, []);

  useEffect(() => {
    (async function () {
      loadingIconState.setState(true);
      let URL = window.location.href;
      const enCodeValue: string[] = URL?.split(/[=]/);
      if (!!enCodeValue[1]) {
        let invitationData = await getDecodeInvitationById(
          enCodeValue[1]?.replaceAll("+", "%2B")
        );
        setSignUpDetail(invitationData);
        setFacilityDirectSignup(true);
        setFacilityName(signUpDetail.first_name);
        setFacilityNumber(signUpDetail.phone_number);
      }
      await Promise.all([getNumberOfEmployee(), getFacilityTypeeList()]);
      loadingIconState.setState(false);
      decodeErrorResponse();
    })();
  }, []);

  useEffect(() => {
    organizationOnboardingState.setState(facilityDetail);
  }, [facilityDetail]);

  useEffect(() => {
    setSignUpOTP({ ...signUpOTP, userName: signUpDetail.email });
  }, [signUpDetail]);

  useEffect(() => {
    let timerId: string | number | NodeJS.Timer | undefined;

    if (runTimer) {
      setCountDown(60 * 2);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const decodeErrorResponse = () => {
    var errorMessage = errorMessageState.getState().errorMessage;
    var str = errorMessage;
    setInvitationId(str.split(":")[1]);
    if (!!invitationId) {
      setOpenScreen("linkExpiredScreen");
    }
  };

  const getResendOTPTextColor = () => {
    if (minutes == "00" && seconds == "00") {
      return "#075CDA";
    } else {
      return "#00000099";
    }
  };

  const handleTimer = () => {
    if (minutes == "00" && seconds == "00") {
      setRunTimer((t) => !t);
    }
  };

  const schema = Joi.object<SignUpDetail>({
    id: Joi.string().allow(""),
    first_name: Joi.string().max(60).required().messages({
      "string.empty": `First name cannot be an empty field`,
      "string.max": `The maximum character limit should be 60`,
    }),
    last_name: Joi.string().max(60).required().messages({
      "string.empty": `Last name cannot be an empty field`,
      "string.max": `The maximum character limit should be 60`,
    }),
    email: Joi.string().max(255).required().email({ tlds: false }).messages({
      "string.email": `Email is not valid`,
      "string.max": `The maximum character limit should be 255`,
      "string.empty": `Email cannot be an empty field`,
    }),
    phone_number: Joi.string().allow("").allow(null),
    send_date: Joi.string().allow(""),
    tenant_type: Joi.number().allow(0),
    role_id: Joi.string().allow(""),
    status: Joi.string().allow(""),
    tenant_id: Joi.string().allow(null),
  }).options({ abortEarly: false });

  const codeValidateschema = Joi.object<SignUpOTPDetail>({
    userName: Joi.string().allow(""),
    password: Joi.string().allow(""),
    mobileNumber: Joi.string().allow(""),
    otp: Joi.string().min(6).max(6).required().messages({
      "string.empty": `code cannot be an empty field`,
      "string.min": `The code is not valid. Try again.`,
      "string.max": `The code is not valid. Try again.`,
    }),
  }).options({ abortEarly: false });

  const detailFormValidateschema = Joi.object<OrganizationOnboarding>({
    organizationDetails: Joi.object<orgDetail>({
      id: Joi.string().allow(""),
      name: Joi.string().required().max(255).messages({
        "string.empty": `Facility name cannot be an empty field`,
        "string.max": `The maximum character limit should be 255`,
      }),
      number_of_employee: Joi.number()
        .positive()
        .greater(0)
        .required()
        .messages({
          "number.positive": `Number of employee cannot be an empty field`,
          "any.required": `Number of employee cannot be an empty field`,
        }),
      npi: Joi.string().min(10).max(10).allow("").messages({
        "string.min": `NPI should be 10 digit`,
        "string.max": `NPI should be 10 digit`,
      }),
      definitive_id: Joi.string().allow(""),
      medicare_provider_number: Joi.string().allow(""),
      number_of_staffed_beds: Joi.number().allow(0),
      contact_name: Joi.string().allow("").max(255).messages({
        "string.max": `The maximum character limit should be 255`,
      }),
      contact_phone_number: Joi.string().allow(""),
      contact_email: Joi.string().allow("").max(255).messages({
        "string.max": `The maximum character limit should be 255`,
      }),
      tenant_id: Joi.string().allow(null),
      address_1: Joi.string().allow(""),
      address_2: Joi.string().allow(""),
      city: Joi.string().allow("").max(255).messages({
        "string.max": `The maximum character limit should be 255`,
      }),
      state: Joi.string().allow("").max(255).messages({
        "string.max": `The maximum character limit should be 255`,
      }),
      postal_code: Joi.string().allow(""),
      website: Joi.string().allow("").max(255).messages({
        "string.max": `The maximum character limit should be 255`,
      }),
      contact_title: Joi.string().allow("").max(255).messages({
        "string.max": `The maximum character limit should be 255`,
      }),
      primary_phone_number: Joi.string().allow(""),
      staff_types: Joi.array().empty(Joi.array().length(0)),
      is_active: Joi.boolean(),
      invitation_id: Joi.string().allow(null),
      contact_person_email: Joi.string().allow("").max(255).messages({
        "string.max": `The maximum character limit should be 255`,
      }),
      facility_type: Joi.number().allow(0),
    }),
    tenantDetails: Joi.object<tenantDetail>({
      id: Joi.string().allow(""),
      type: Joi.number().allow(0),
      is_active: Joi.boolean(),
    }),
  }).options({ abortEarly: false });

  const handleCreateAccount = async () => {
    let userDetails = userDetailstate.getState();
    if (signUpDetail.phone_number == "+" || signUpDetail.phone_number == "+1") {
      signUpDetail.phone_number = "";
      userDetails.mobile_Number = "";
    } else {
      userDetails.mobile_Number = !!signUpDetail.phone_number
        ? signUpDetail.phone_number
        : "";
    }

    const result = schema.validate(signUpDetail);
    let errorMessage: any[] = [];
    let URL = window.location.href;
    const enCodeValue: string[] = URL?.split(/[=]/);

    if (!!result.error && !!result.error.details) {
      result.error.details.forEach((cc) => {
        let field = cc.path[0];
        if (cc.path.length > 1) {
          field = cc.path.join("$");
        }
        errorMessage.push({ field: field, errorMessage: cc.message });
      });

      setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage([]);
    loadingIconState.setState(true);
    let userDetail = userDetailstate.getState();
    userDetail.id = EmptyGuid;
    userDetail.first_Name = signUpDetail.first_name;
    userDetail.last_Name = signUpDetail.last_name;
    userDetail.email = signUpDetail.email;
    userDetail.tenant_Id = null;
    userDetail.tenant_type = 1;
    if (enCodeValue[1] == undefined) {
      userDetail.invitation_id = null;
      userDetail.role_Id = "9ab5ac75-afcf-4c0b-8795-84aa022b0a77";
    } else {
      userDetail.invitation_id = signUpDetail.id;
      userDetail.role_Id = signUpDetail.role_id;
    }
    try {
      await createUser(userDetail);
      handleOTPScreenNavigate();
    } catch (error) {
      setOpenScreen("signUp");
    }
    loadingIconState.setState(false);
  };

  const handleOTPScreenNavigate = () => {
    signUpOTP.otp = "";
    setOpenScreen("otpConfirmScreen");
    handleTimer();
  };

  const handleConfirmOtp = async () => {
    const result = codeValidateschema.validate(signUpOTP);
    let errorMessage: any[] = [];

    if (!!result.error && !!result.error.details) {
      result.error.details.forEach((cc) => {
        let field = cc.path[0];
        if (cc.path.length > 1) {
          field = cc.path.join("$");
        }
        errorMessage.push({ field: field, errorMessage: cc.message });
      });

      setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage([]);
    loadingIconState.setState(true);
    try {
      await generateLoginToken({
        userName: signUpDetail.email,
        otp: signUpOTP.otp,
      });
      handleDetailFormNavifate();
    } catch (error) {
      setOpenScreen("otpConfirmScreen");
    }
    loadingIconState.setState(false);
  };

  const handleResendCodeClick = async () => {
    if (minutes == "00" && seconds == "00") {
      loadingIconState.setState(true);
      await generateOTP({
        userName: signUpDetail.email,
      });
      loadingIconState.setState(false);
      handleTimer();
    }
  };

  const handleDetailFormNavifate = () => {
    facilityDetail.organizationDetails.name = "";
    facilityDetail.organizationDetails.facility_type = 0;
    facilityDetail.organizationDetails.number_of_employee = 0;
    setOpenScreen("detailFormScreen");
  };

  const handledetailFormClick = () => {
    const result = detailFormValidateschema.validate(
      organizationOnboardingState.getState()
    );
    let errorMessage: any[] = [];
    if (!!result.error && !!result.error.details) {
      result.error.details.forEach((cc) => {
        let field = cc.path[0];
        if (cc.path.length > 1) {
          field = cc.path.join("$");
        }
        errorMessage.push({ field: field, errorMessage: cc.message });
      });

      setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage([]);
    facilityDetail.organizationDetails.address_1 = "";
    facilityDetail.organizationDetails.address_2 = "";
    facilityDetail.organizationDetails.city = "";
    facilityDetail.organizationDetails.state = "";
    facilityDetail.organizationDetails.postal_code = "";
    setOpenScreen("addressFormScreen");
  };

  const addressFormValidateschema = Joi.object<OrganizationOnboarding>({
    organizationDetails: Joi.object<orgDetail>({
      id: Joi.string().allow(""),
      name: Joi.string().allow(""),
      number_of_employee: Joi.number().allow(0),
      npi: Joi.string().min(10).max(10).allow("").messages({
        "string.min": `NPI should be 10 digit`,
        "string.max": `NPI should be 10 digit`,
      }),
      definitive_id: Joi.string().allow(""),
      medicare_provider_number: Joi.string().allow(""),
      number_of_staffed_beds: Joi.number().allow(0),
      contact_name: Joi.string().allow(""),
      contact_phone_number: Joi.string().allow(""),
      contact_email: Joi.string().allow(""),
      tenant_id: Joi.string().allow(null),
      address_1: Joi.string().required().max(255).messages({
        "string.empty": `Street address 1 cannot be an empty field`,
        "string.max": `The maximum character limit should be 255`,
      }),
      address_2: Joi.string().allow("").max(255).messages({
        "string.max": `The maximum character limit should be 255`,
      }),
      city: Joi.string().required().max(255).messages({
        "string.empty": `City & state cannot be an empty field`,
        "string.max": `The maximum character limit should be 255`,
      }),
      state: Joi.string().allow("").max(255).messages({
        "string.max": `The maximum character limit should be 255`,
      }),
      postal_code: Joi.string().min(5).max(5).required().messages({
        "string.empty": `Zip code cannot be an empty field`,
        "string.min": `Zip code is invalid`,
        "string.max": `Zip code is invalid`,
      }),
      website: Joi.string().allow(""),
      contact_title: Joi.string().allow(""),
      primary_phone_number: Joi.string().allow(""),
      staff_types: Joi.array().empty(Joi.array().length(0)),
      is_active: Joi.boolean(),
      invitation_id: Joi.string().allow(null),
      facility_type: Joi.number().allow(0),
      contact_person_email: Joi.string().allow(""),
    }),
    tenantDetails: Joi.object<tenantDetail>({
      id: Joi.string().allow(""),
      type: Joi.number().allow(0),
      is_active: Joi.boolean(),
    }),
  }).options({ abortEarly: false });

  const handleAddressFormClick = async () => {
    const result = addressFormValidateschema.validate(
      organizationOnboardingState.getState()
    );
    let errorMessage: any[] = [];
    if (!!result.error && !!result.error.details) {
      result.error.details.forEach((cc) => {
        let field = cc.path[0];
        if (cc.path.length > 1) {
          field = cc.path.join("$");
        }
        errorMessage.push({ field: field, errorMessage: cc.message });
      });
      setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage([]);
    loadingIconState.setState(true);
    let orgOnboardingDetail = organizationOnboardingState.getState();
    if (orgOnboardingDetail.organizationDetails.state == "") {
      let stateValue =
        orgOnboardingDetail.organizationDetails.city.split(/[\s,]+/);
      orgOnboardingDetail.organizationDetails.city = stateValue[0];
      orgOnboardingDetail.organizationDetails.state = stateValue[1];
    }
    orgOnboardingDetail.organizationDetails.id = EmptyGuid;
    orgOnboardingDetail.organizationDetails.tenant_id = EmptyGuid;
    orgOnboardingDetail.organizationDetails.invitation_id = null;
    orgOnboardingDetail.organizationDetails.contact_person_email =
      signUpDetail.email;
    orgOnboardingDetail.organizationDetails.number_of_staffed_beds = 51;
    orgOnboardingDetail.tenantDetails.id = EmptyGuid;
    orgOnboardingDetail.tenantDetails.type = 1;
    try {
      await organizationOnboarding(orgOnboardingDetail);
      navigate("/");
    } catch (error) {}
    loadingIconState.setState(false);
  };

  const handleLinkExpiredClick = async () => {
    await reInitiateTenantInvitation(invitationId);
    setOpenScreen("signUp");
  };

  const handleSignInClick = () => {
    navigate("/");
  };

  const handleAddressAutopopulate = async (event: any, value: any) => {
    if (value.length >= 3) {
      await getAddressValue(value);
    }
  };

  const handleAutocompleteChange = (event: any, value: any) => {
    if (!!value) {
      setFacilityDetail((prevState) => ({
        ...prevState,
        organizationDetails: {
          ...prevState.organizationDetails,
          address_1: `${!!value.streetNumber ? value.streetNumber : ""} ${
            !!value.streetName ? value.streetName : ""
          }`,
          address_2: "",
          city: `${!!value.municipality ? value.municipality : ""} ${
            !!value.countrySubdivision ? value.countrySubdivision : ""
          }`,
          postal_code: `${!!value.postalCode ? value.postalCode : ""}`,
        },
      }));
    }
  };

  const handlePostalcodeChange = (event: any) => {
    const inputValue = event.target.value;
    const postalCode = inputValue.replace(/\D/g, "");
    if (postalCode.length <= 5) {
      setFacilityDetail((prevState) => ({
        ...prevState,
        organizationDetails: {
          ...prevState.organizationDetails,
          postal_code: postalCode,
        },
      }));
    }
  };

  const handelStateChange = (event: any) => {
    const stateVal = event.target.value.split(/[\s,]+/).filter(Boolean);
    setFacilityDetail((prevState) => ({
      ...prevState,
      organizationDetails: {
        ...prevState.organizationDetails,
        city: event.target.value,
      },
    }));
    if (stateVal.length > 1) {
      setFacilityDetail((prevState) => ({
        ...prevState,
        organizationDetails: {
          ...prevState.organizationDetails,
          state: stateVal[1].toString(),
        },
      }));
    }
  };

  const handleCodeChange = (event: any) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue)) {
      setSignUpOTP({
        ...signUpOTP,
        otp: inputValue,
      });
    }
  };

  return (
    <>
      {openScreen == "signUp" && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "calc(100% - 20px)",
              padding: "10px",
              gap: "8px",
            }}
          >
            {/* CA box */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                gap: "8px",
              }}
            >
              {signUpDetail.role_id == "" && (
                <>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: "500",
                      lineHeight: "32px",
                      color: "#000000DE",
                    }}
                  >
                    Create account
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "32px",
                      letterSpacing: "0.15px",
                      inlineHight: "24px",
                      color: "#00000099",
                      paddingBottom: "20px",
                    }}
                  >
                    Please fill in your Details.
                  </Typography>
                </>
              )}
              {signUpDetail.role_id != "" && (
                <>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: "500",
                      lineHeight: "32px",
                      color: "#000000DE",
                    }}
                  >
                    Dear {facilityName}, welcome to ShiftNex!
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography16_400
                      text="Join the ShiftNex Healthcare Platform and enjoy the"
                      color="#00000099"
                    />
                    <Typography16_400
                      text="way we manage healthcare staffing operations"
                      color="#00000099"
                    />
                  </Box>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <SignupTextLableTypography
                  text="First name"
                  sx={{
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                  required={true}
                />
                <PlatformLoginTextField
                  sx={{
                    "&:hover fieldset": {
                      border: "1px solid #0000003B !important",
                      borderRadius: "8px",
                    },
                  }}
                  placeholder={"e.g. Jhon"}
                  value={signUpDetail.first_name}
                  handleChange={(e: any) => {
                    setSignUpDetail({
                      ...signUpDetail,
                      first_name: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("first_name", errorMessage)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <SignupTextLableTypography
                  text="Last name"
                  sx={{
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                  required={true}
                />
                <PlatformLoginTextField
                  sx={{
                    "&:hover fieldset": {
                      border: "1px solid #0000003B !important",
                      borderRadius: "8px",
                    },
                  }}
                  placeholder={"e.g. Doe"}
                  value={signUpDetail.last_name}
                  handleChange={(e: any) => {
                    setSignUpDetail({
                      ...signUpDetail,
                      last_name: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("last_name", errorMessage)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <SignupTextLableTypography
                  text="Email"
                  sx={{
                    color:
                      !!facilityDirectSignup && facilityNumber.length > 0
                        ? "rgba(0, 0, 0, 0.38)"
                        : "rgba(0, 0, 0, 0.87)",
                  }}
                  required={true}
                />
                <PlatformLoginTextField
                  sx={{
                    "&:hover fieldset": {
                      border: "1px solid #0000003B !important",
                      borderRadius: "8px",
                    },
                  }}
                  placeholder={"e.g. jhondoe@gmail.com"}
                  disabled={facilityDirectSignup}
                  value={signUpDetail.email}
                  handleChange={(e: any) => {
                    setSignUpDetail({
                      ...signUpDetail,
                      email: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("email", errorMessage)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "24px",
                  paddingTop: "2%",
                }}
              >
                <SignUpContainedButton
                  disabled={!signUpDetail.first_name}
                  text="Create account"
                  handleClick={handleCreateAccount}
                />

                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    letterSpacing: "0.15px",
                    inlineHight: "24px",
                    textAlign: "center",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  Already have a Shiftnex account?{" "}
                  <span
                    style={{
                      fontWeight: "500",
                      color: "#1F5DA5",
                      cursor: "pointer",
                    }}
                    onClick={handleSignInClick}
                  >
                    Sign In
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {openScreen == "otpConfirmScreen" && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
              width: "calc(100% - 20px)",
              padding: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <svg
                width="80"
                height="81"
                viewBox="0 0 80 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M52.5 10C47.9688 10 43.9062 12.5 41.5625 16.25C39.375 20.1562 39.375 25 41.5625 28.75C43.9062 32.6562 47.9688 35 52.5 35C56.875 35 60.9375 32.6562 63.2812 28.75C65.4688 25 65.4688 20.1562 63.2812 16.25C60.9375 12.5 56.875 10 52.5 10ZM52.5 45C44.375 45 37.0312 40.7812 32.9688 33.75C28.9062 26.875 28.9062 18.2812 32.9688 11.25C37.0312 4.375 44.375 0 52.5 0C60.4688 0 67.8125 4.375 71.875 11.25C75.9375 18.2812 75.9375 26.875 71.875 33.75C67.8125 40.7812 60.4688 45 52.5 45Z"
                  fill="#1FA7F8"
                />
                <path
                  opacity="0.4"
                  d="M33.4375 34.5312C35.1562 37.3438 37.6562 39.8438 40.4688 41.5625L27.0312 55L33.4375 61.5625C35.4688 63.4375 35.4688 66.7188 33.4375 68.5938C31.5625 70.625 28.2812 70.625 26.4062 68.5938L20 62.1875L17.0312 65L23.4375 71.5625C25.4688 73.4375 25.4688 76.7188 23.4375 78.5938C21.5625 80.625 18.2812 80.625 16.4062 78.5938L6.40625 68.5938C4.375 66.7188 4.375 63.4375 6.40625 61.5625L33.4375 34.5312Z"
                  fill="#1FA7F8"
                />
              </svg>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "24px",
                  lineHeight: "32px",
                  color: "#000000DE",
                }}
              >
                Secure Verification
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                  color: "#00000099",
                  textAlign: "center",
                  alignSelf: "stretch",
                  justifyContent: "center",
                  flex: "none",
                  flexGrow: "0",
                }}
              >
                We’ve just sent you a temporary 6-digit code verification.
                Please check your email.
              </Typography>
            </Box>
            {/* button box */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                gap: "10px",
              }}
            >
              <PlatformLoginTextField
                sx={{
                  "&:hover fieldset": {
                    border: "1px solid #0000003B !important",
                    borderRadius: "8px",
                  },
                }}
                placeholder={"Enter the code"}
                value={signUpOTP.otp}
                handleChange={handleCodeChange}
                errorMessage={getErrorDetail("otp", errorMessage)}
              />
              <SignUpContainedButton
                disabled={!!!signUpOTP.otp}
                text="Confirm"
                handleClick={handleConfirmOtp}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "15px",
                  lineHeight: "26px",
                  letterSpacing: "0.46px",
                  color: getResendOTPTextColor(),
                  cursor: "pointer",
                }}
                onClick={handleResendCodeClick}
              >
                Resend the code{" "}
              </Typography>
              {runTimer && (
                <span
                  style={{
                    color: getResendOTPTextColor(),
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      lineHeight: "22px",
                      letterSpacing: "0.1px",
                    }}
                  >{`in ${minutes}:${seconds}`}</Typography>
                </span>
              )}
            </Box>
          </Box>
        </>
      )}
      {openScreen == "detailFormScreen" && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "calc(100% - 20px)",
              padding: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "32px",
                  color: "#000000DE",
                }}
              >
                Facility
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#00000099",
                }}
              >
                Please fill your facility details.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <SignupTextLableTypography
                text="Facility name"
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                }}
                required={true}
              />
              <PlatformLoginTextField
                sx={{
                  "&:hover fieldset": {
                    border: "1px solid #0000003B !important",
                    borderRadius: "8px",
                  },
                }}
                placeholder={"Enter facility name"}
                value={facilityDetail.organizationDetails.name}
                handleChange={(e: any) => {
                  setFacilityDetail((prevState) => ({
                    ...prevState,
                    organizationDetails: {
                      ...prevState.organizationDetails,
                      name: e.target.value,
                    },
                  }));
                }}
                errorMessage={getErrorDetail(
                  "organizationDetails$name",
                  errorMessage
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <SignupTextLableTypography
                text="Facility type"
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                }}
              />
              <SignUpDropDown
                sx={{
                  "& .MuiPaper-root": {
                    borderRadius: "20px !important",
                  },
                }}
                handleChange={(e: any) => {
                  setFacilityDetail((prevState) => ({
                    ...prevState,
                    organizationDetails: {
                      ...prevState.organizationDetails,
                      facility_type: e.target.value,
                    },
                  }));
                }}
                value={facilityDetail.organizationDetails.facility_type}
                errorMessage={getErrorDetail(
                  "organizationDetails$facility_type",
                  errorMessage
                )}
              >
                <MenuItem value={0}>Choose facility type</MenuItem>
                {facilityTypeList.map((cc) => (
                  <MenuItem value={cc.id} key={cc.id}>
                    <Typography16_400 text={cc.name} color="#031A30" />
                  </MenuItem>
                ))}
              </SignUpDropDown>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <SignupTextLableTypography
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                }}
                text="Number of employees"
                required={true}
              />

              <SignUpDropDown
                sx={{
                  "& .MuiPaper-root": {
                    borderRadius: "20px !important",
                  },
                }}
                handleChange={(e: any) => {
                  setFacilityDetail((prevState) => ({
                    ...prevState,
                    organizationDetails: {
                      ...prevState.organizationDetails,
                      number_of_employee: e.target.value,
                    },
                  }));
                }}
                value={facilityDetail.organizationDetails.number_of_employee}
                errorMessage={getErrorDetail(
                  "organizationDetails$number_of_employee",
                  errorMessage
                )}
              >
                <MenuItem value={0}>Select the number of employees</MenuItem>
                {numberOfEmployeeList.map((cc) => (
                  <MenuItem value={cc.id} key={cc.id}>
                    <Typography16_400 text={cc.name} color="#031A30" />
                  </MenuItem>
                ))}
              </SignUpDropDown>
            </Box>
            <SignUpContainedButton
              text="Next"
              handleClick={handledetailFormClick}
              disabled={!facilityDetail.organizationDetails.name}
            />
          </Box>
        </>
      )}
      {openScreen == "addressFormScreen" && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "calc(100% - 20px)",
              padding: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "32px",
                  color: "#000000DE",
                }}
              >
                Address
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#00000099",
                }}
              >
                Please provide your facilities address.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <SignupTextLableTypography
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                }}
                text="Street address 1"
                required={true}
              />
              <PlatformAutoCompleteTextInput
                sx={{
                  "&:hover fieldset": {
                    border: "1px solid #0000003B !important",
                    borderRadius: "8px",
                  },
                }}
                value={facilityDetail.organizationDetails.address_1}
                onChange={handleAutocompleteChange}
                onInputChange={(event: any, newInputValue: any) => {
                  handleAddressAutopopulate(event, newInputValue);
                  setFacilityDetail((prevState) => ({
                    ...prevState,
                    organizationDetails: {
                      ...prevState.organizationDetails,
                      address_1: newInputValue,
                    },
                  }));
                }}
                options={addressValue}
                getOptionLabel={(option: any) =>
                  typeof option === "string" ? option : option.freeformAddress
                }
                filterOptions={(x: any) => x}
                noOptionsText="No locations"
                label={""}
                placeholder={"Enter street name, number"}
                errorMessage={getErrorDetail(
                  "organizationDetails$address_1",
                  errorMessage
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <SignupTextLableTypography text="Street address 2" />
              <PlatformLoginTextField
                sx={{
                  "&:hover fieldset": {
                    border: "1px solid #0000003B !important",
                    borderRadius: "8px",
                  },
                }}
                placeholder={"Enter street name, number"}
                value={facilityDetail.organizationDetails.address_2}
                handleChange={(e: any) => {
                  setFacilityDetail((prevState) => ({
                    ...prevState,
                    organizationDetails: {
                      ...prevState.organizationDetails,
                      address_2: e.target.value,
                    },
                  }));
                }}
                errorMessage={getErrorDetail(
                  "organizationDetails$address_2",
                  errorMessage
                )}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <SignupTextLableTypography
                text="City & state"
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                }}
                required={true}
              />
              <PlatformLoginTextField
                sx={{
                  "&:hover fieldset": {
                    border: "1px solid #0000003B !important",
                    borderRadius: "8px",
                  },
                }}
                placeholder={"Enter city & state"}
                value={facilityDetail.organizationDetails.city}
                handleChange={handelStateChange}
                errorMessage={getErrorDetail(
                  "organizationDetails$city",
                  errorMessage
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <SignupTextLableTypography
                text="Zip code"
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                }}
                required={true}
              />
              <PlatformLoginTextField
                sx={{
                  "&:hover fieldset": {
                    border: "1px solid #0000003B !important",
                    borderRadius: "8px",
                  },
                }}
                placeholder={"Enter zip code"}
                value={facilityDetail.organizationDetails.postal_code}
                handleChange={handlePostalcodeChange}
                errorMessage={getErrorDetail(
                  "organizationDetails$postal_code",
                  errorMessage
                )}
              />
            </Box>
            <SignUpContainedButton
              text="Complete profile"
              handleClick={handleAddressFormClick}
              disabled={!facilityDetail.organizationDetails.address_1}
            />
          </Box>
        </>
      )}
      {openScreen == "linkExpiredScreen" && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <svg
                width="80"
                height="64"
                viewBox="0 0 80 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M72.475 7.90044C79.5375 14.9629 79.5375 26.4004 72.475 33.4629L58.4375 47.5004C51.375 54.5629 39.9375 54.5629 32.875 47.5004C26.625 41.2504 25.8125 31.4004 30.95 24.2129L31.0875 24.0129C32.375 22.2129 34.875 21.8004 36.6625 23.0879C38.45 24.3754 38.875 26.8754 37.5875 28.6629L37.45 28.8629C34.5875 32.8754 35.0375 38.3629 38.525 41.8379C42.4625 45.7754 48.8375 45.7754 52.775 41.8379L66.8125 27.8129C70.75 23.8754 70.75 17.5004 66.8125 13.5629C63.3375 10.0754 57.8375 9.62544 53.8375 12.4879L53.6375 12.6254C51.8375 13.9129 49.3375 13.4879 48.0625 11.7004C46.7875 9.91294 47.2 7.40044 48.9875 6.12544L49.1875 5.98794C56.375 0.837941 66.225 1.66294 72.475 7.90044Z"
                  fill="#F2460D"
                />
                <path
                  d="M7.5249 56.1C0.462402 49.0375 0.462402 37.6 7.5249 30.5375L21.5624 16.5C28.6249 9.4375 40.0624 9.4375 47.1249 16.5C53.3749 22.75 54.1874 32.6 49.0499 39.7875L48.9124 39.9875C47.6249 41.7875 45.1249 42.2 43.3374 40.9125C41.5499 39.625 41.1249 37.125 42.4124 35.3375L42.5499 35.1375C45.4124 31.125 44.9624 25.6375 41.4749 22.15C37.5374 18.2125 31.1624 18.2125 27.2249 22.15L13.1874 36.1875C9.2499 40.125 9.2499 46.5 13.1874 50.4375C16.6749 53.925 22.1624 54.375 26.1624 51.5125L26.3624 51.375C28.1624 50.0875 30.6624 50.5125 31.9374 52.3C33.2124 54.0875 32.7999 56.6 31.0124 57.875L30.8124 58.0125C23.6249 63.1625 13.7749 62.3375 7.5249 56.1Z"
                  fill="#F2460D"
                />
              </svg>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "32px",
                    color: "#000000DE",
                  }}
                >
                  Link expired
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    width: "65%",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    color: "#000000DE",
                    textAlign: "center",
                  }}
                >
                  The link is not valid anymore. Please request a new one
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleLinkExpiredClick}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "500",
                  lineHeight: "26px",
                  color: "#1F5DA5",
                  letterSpacing: "0.46px",
                  cursor: "pointer",
                }}
              >
                Request new invitation link
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
