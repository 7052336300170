import { createContext, useContext } from "react";
import { LocationDetail } from "../model-lib";

interface ILocationDetailContext {
  locationDetail: LocationDetail;
  setLocationDetail: React.Dispatch<React.SetStateAction<LocationDetail>>;
}

export const LocationDetailContext = createContext<
  ILocationDetailContext | undefined
>(undefined);

export const useLocationDetailContext = (): ILocationDetailContext => {
  const context = useContext(LocationDetailContext);
  if (context === undefined) {
    throw new Error("useMyContext must be used within a MyProvider");
  }
  return context;
};
