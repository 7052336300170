import { useEffect } from "react";
import { currentActiveScreenState } from "../../../libs/state-management-lib";
import DepartmentDetailTemplate from "../../template/settings/departmentDetailTemplate";

const DepartmentDetail = () => {
  useEffect(() => {
    currentActiveScreenState.setState("/settings/organization");
  }, []);

  return <DepartmentDetailTemplate />;
};

export default DepartmentDetail;
