import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { SxProps } from "@mui/material";
import { Theme } from "@material-ui/core";

interface ISplitButton {
  handleSplitButtonClick: (index: number) => void;
  options: string[];
  name: string;
  sx?: SxProps<Theme>;
}

export const SplitButton = (props: ISplitButton) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    props.handleSplitButtonClick(index);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        sx={{
          height: "40px",
          borderRadius: "8px",
          textTransform: "none",
          boxShadow: "none",
          ".MuiButtonGroup-grouped:not(:last-of-type)": {
            borderColor: "#084C91",
          },
          ".MuiButtonGroup-groupedContained": {
            "&:hover": {
              background: "#0A66C2 !important",
              boxShadow: "none !important",
            },
            "&:focus": {
              background: "#0A66C2 !important",
              boxShadow: "none !important",
            },
          },
        }}
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <Button
          sx={{
            background: "#0D80F2",
            color: "#FFFFFF",
            borderRadius: "8px",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "24px",
          }}
          onClick={handleToggle}
        >
          {props.name}
        </Button>
        <Button
          sx={{
            width: "48px",
            background: "#0D80F2",
            color: "#FFFFFF",
            borderRadius: "8px",
            textTransform: "none",
          }}
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : "false"}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
          boxShadow:
            "0px 3px 14px 2px #0000001F, 0px 8px 10px 1px #00000024, 0px 5px 5px -3px #00000033",
          borderRadius: "8px",
        }}
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              borderRadius: "8px",
            }}
          >
            <Paper
              sx={{
                width: "240px",
                borderRadius: "8px !important",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {props.options.map((option, index) => (
                    <MenuItem
                      sx={{
                        color: "#000000DE",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "24px",
                      }}
                      key={option}
                      disabled={index === 0}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};