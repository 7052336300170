import { devtools, persist } from "zustand/middleware";
import { OrganizationRole } from "../model-lib";
import { create } from "zustand";
import { emptyRoleDetail } from "../common-lib";

export const roleDetailState = create<OrganizationRole>()(
  devtools(
    persist((set, get) => emptyRoleDetail, {
      name: "roleDetailState",
    })
  )
);

interface IRoleListState {
  roleList: OrganizationRole[];
}

export const roleListState = create<IRoleListState>()(
  devtools(
    persist((set, get) => ({ roleList: [] }), {
      name: "roleListState",
    })
  )
);
