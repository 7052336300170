import {
  Autocomplete,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";

interface IAutoCompleteTextInput {
  options: any;
  label: string;
  sx?: SxProps<Theme>;
  placeholder: string;
  onInputChange: any;
  onChange: any;
  getOptionLabel?: any;
  value?: any;
  filterOptions?: any;
  errorMessage?: string;
  noOptionsText?: string;
  freeSolo?: boolean;
  disabled?: boolean;
}

export const AutoCompleteTextInput = (props: IAutoCompleteTextInput) => {
  return (
    <>
      <Autocomplete
        size="small"
        sx={{
          ...props.sx,
          background: "#F5FAFE",
          borderRadius: "8px",
          border: !!props.errorMessage ? "red" : "#CFE5FC",
          "& .MuiSvgIcon-root": { display: "none" },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: !!props.errorMessage
                ? "2px solid red !important"
                : "2px solid #0D80F2 !important",
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: !!props.errorMessage
              ? "1px solid red !important"
              : "1px solid #CFE5FC",
            borderRadius: "8px",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: !!props.errorMessage ? "red" : "#0D80F2 !important",
          },
          "& .MuiInputLabel-root": { display: "none" },
          "& .Mui-disabled": {
            background: "#F1F2F4 !important",
            "&:hover fieldset": {
              border: "1px solid #ABB2BA !important",
            },
          },
          input: {
            "&::placeholder": {
              textOverflow: "ellipsis !important",
              color: "#6EB2F7",
              opacity: "5",
            },
          },
        }}
        disablePortal
        id="combo-box-demo"
        freeSolo={!!props.freeSolo && props.freeSolo ? true : false}
        options={props.options}
        //onInputChange={props.onInputChange}
        onChange={props.onChange}
        getOptionLabel={props.getOptionLabel}
        value={props.value}
        filterOptions={props.filterOptions}
        noOptionsText={props.noOptionsText}
        ListboxProps={
          {
            style:{
                maxHeight: '150px',
            }
          }
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.placeholder}
            label={props.label}
            onChange={props.onInputChange}
          />
        )}
        disabled={!!props.disabled ? props.disabled : false}
      />
      {!!props.errorMessage && (
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22.4px",
            color: "red",
            alignItems: "flex-start",
          }}
          className="red-text"
        >
          {props.errorMessage}
        </Typography>
      )}
    </>
  );
};

export const PlatformAutoCompleteTextInput = (
  props: IAutoCompleteTextInput
) => {
  return (
    <>
      <Autocomplete
        size="small"
        sx={{
          borderRadius: 8,
          width: "100%",
          border: !!props.errorMessage ? "red" : "#F0F0F0",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: !!props.errorMessage ? "red" : "#F0F0F0",
              backgroundColor: " rgba(0, 0, 0, 0.06)",
            },
            "&:hover fieldset": {
              border: "1px solid #0000003B",
              borderRadius: "8px",
            },
          },
          ".Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #1F5DA5 !important",
            borderRadius: "8px",
          },
          input: {
            "&::placeholder": {
              color: !!props.errorMessage ? "#F2460D" : " #00000099",
              opacity: "5",
            },
          },
        }}
        disablePortal
        freeSolo
        disableClearable
        id="combo-box-demo"
        options={props.options}
        onInputChange={props.onInputChange}
        onChange={props.onChange}
        getOptionLabel={props.getOptionLabel}
        value={props.value}
        filterOptions={props.filterOptions}
        noOptionsText={props.noOptionsText}
        renderInput={(params) => (
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "24px",
              color: "#031A30",
            }}
          >
            <TextField
              {...params}
              placeholder={props.placeholder}
              label={props.label}
            />
          </Typography>
        )}
      />
      {!!props.errorMessage && (
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "20px",
            color: "#F2460D",
            alignItems: "flex-start",
          }}
          className="red-text"
        >
          {props.errorMessage}
        </Typography>
      )}
    </>
  );
};
