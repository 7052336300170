import { useEffect } from "react";
import { currentActiveScreenState } from "../../libs/state-management-lib";
import { FacilitySignUpTemplate } from "../template";

export const FacilitySignUp = () => {
  useEffect(() => {
    currentActiveScreenState.setState("/SignUp");
  }, []);

  return (
    <>
      <FacilitySignUpTemplate />
    </>
  );
};
