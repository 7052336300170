import { Box, FormControlLabel, styled, Switch } from "@mui/material";
import { PlusIcon } from "../../atom";
import { NumberInputField } from "../../atom/textField/numberInputTextField";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
  numberInputClasses,
} from "@mui/base/Unstable_NumberInput";
import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { RotationSequence, ShiftRotation } from "../../../libs/model-lib";
import { EmptyGuid, emptyShiftRotation } from "../../../libs/common-lib";
import { shiftRotationState } from "../../../libs/state-management-lib";
import { useShiftRotationContext } from "../../../libs/context-lib/shiftDetailContext";

export const CalendarRotationShiftDaySequence = () => {
  const [textFields, setTextFields] = useState([
    { id: 1, value: 0, switchValue: false },
  ]);

  const { shiftRotation, setShiftRotation } = useShiftRotationContext();

  useEffect(() => {
    let rotationSequence: RotationSequence[] = [];
    let roationName = "";

    textFields.forEach((field) => {
      rotationSequence.push({
        id: EmptyGuid,
        day: field.value,
        is_working_day: field.switchValue,
        break_types: [93],
      });

      let switchName = field.switchValue
        ? field.value + " on"
        : field.value + " off";

      roationName = !!roationName ? roationName + " " + switchName : switchName;
    });

    setShiftRotation({
      ...shiftRotation,
      rotation_name: roationName,
      rotation_sequence: rotationSequence,
    });
  }, [textFields]);

  const StyledInputRoot = styled("div")(
    ({ theme }) => `
    width:100%;
    height:40px;
    font-family: inherit;
    font-weight: 400;
    border-radius: 8px;
    color: black;
    background: #F5FAFE;
    border: 1px solid #CFE5FC;
    box-shadow: #F5FAFE;
    display: grid;
    grid-template-columns: 1fr 19px;
    grid-template-rows: 1fr 1fr;
    overflow: hidden;
    column-gap: 8px; 
  `
  );

  const StyledInputElement = styled("input")(
    ({ theme }) => `
    font-size: 16px;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5;
    grid-column: 1/2;
    grid-row: 1/3;
    color: black;
    background: inherit;
    border: none;
    border-radius: inherit;
    padding: 8px 12px;
    outline: 0;
  `
  );

  const StyledButton = styled("button")(
    ({ theme }) => `
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    appearance: none;
    padding: 0;
    width: 19px;
    height: 19px;
    font-family: Roboto;
    font-size: 0.875rem;
    line-height: 1;
    box-sizing: border-box;
    border: 0;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
      &:hover {
      cursor: pointer;  
    }
  
    &.${numberInputClasses.incrementButton} {
      grid-column: 2/3;
      grid-row: 1/2;
      background:none;
      color:#0D80F2;
      padding-right:15px;
    }
  
    &.${numberInputClasses.decrementButton} {
      grid-column: 2/3;
      grid-row: 2/3;
      background:none;
      color:#0D80F2;
      padding-right:15px;
  `
  );

  const NumberInput = React.forwardRef(function CustomNumberInput(
    props: NumberInputProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) {
    return (
      <BaseNumberInput
        slots={{
          root: StyledInputRoot,
          input: StyledInputElement,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        slotProps={{
          incrementButton: {
            children: <KeyboardArrowUpIcon />,
          },
          decrementButton: {
            children: <KeyboardArrowDownIcon />,
          },
        }}
        {...props}
        ref={ref}
      />
    );
  });

  const addTextField = (id: any) => {
    let newTextFields = textFields.slice();

    let index = newTextFields.findIndex(function (item, i) {
      return item.id === id;
    });

    let sortArray = newTextFields.slice();

    sortArray.sort(function (a, b) {
      return a.id - b.id;
    });

    newTextFields.splice(index + 1, 0, {
      id: sortArray[sortArray.length - 1].id + 1,
      value: 0,
      switchValue: false,
    });
    setTextFields(newTextFields);
  };

  const copyTextField = (id: any) => {
    let newTextFields = textFields.slice();

    let index = newTextFields.findIndex(function (item, i) {
      return item.id === id;
    });

    let sortArray = newTextFields.slice();

    sortArray.sort(function (a, b) {
      return a.id - b.id;
    });

    newTextFields.push({
      id: sortArray[sortArray.length - 1].id + 1,
      value: newTextFields[index].value,
      switchValue: newTextFields[index].switchValue,
    });

    setTextFields(newTextFields);
  };

  const removeTextField = (id: any) => {
    if (textFields.length > 1) {
      setTextFields(textFields.filter((field) => field.id !== id));
    }
  };

  const handleToggleClick = (e: any, id: any) => {
    let newTextFields = textFields.slice();

    let index = newTextFields.findIndex(function (item, i) {
      return item.id === id;
    });

    if (e.target.checked) {
      newTextFields[index].switchValue = true;
    } else {
      newTextFields[index].switchValue = false;
    }

    setTextFields(newTextFields);
  };

  const handelWorkingDaysChange = (e: any, id: any) => {
    let newTextFields = textFields.slice();

    let index = newTextFields.findIndex(function (item, i) {
      return item.id === id;
    });

    newTextFields[index].value = e;

    setTextFields(newTextFields);

    // setShiftRotation({
    //   ...shiftRotation,
    //   rotation_sequence: rotationSeries,
    // });
  };

  return (
    <>
      {textFields.map((field: any) => (
        <>
          <Box
            sx={{
              width: "100%",
              height: "calc(56px-16px)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              background: "#FFFFFF",
              gap: "8px",
              padding: "8px",
            }}
          >
            <Box
              sx={{
                width: "30%",
              }}
            >
              <FormControlLabel
                sx={{
                  paddingLeft: "2%",
                  gap: "10px",
                  ".MuiFormControlLabel-label": {
                    fontSize: "16px !important",
                    color: "#000000DE",
                    fontWeight: "400",
                  },
                }}
                control={
                  <Switch
                    sx={{
                      width: "58px",
                      ".Mui-checked": {
                        color: "#0D80F2 !important",
                      },
                      ".Mui-checked+.MuiSwitch-track": {
                        backgroundColor: "#0D80F2 !important",
                      },
                    }}
                    key={field.id}
                    checked={field.switchValue}
                    onClick={(e: any) => {
                      handleToggleClick(e, field.id);
                    }}
                  />
                }
                label={"Working day"}
              />
            </Box>

            <Box
              sx={{
                width: "50%",
              }}
            >
              {/* <NumberInputFiled key={field.id} /> */}
              <NumberInput
                placeholder="Sequence duration"
                min={0}
                aria-label="number input"
                value={field.value}
                onChange={(event, val: any) => {
                  handelWorkingDaysChange(val, field.id);
                }}
              />
            </Box>

            <Box
              sx={{
                width: "15%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  addTextField(field.id);
                }}
              >
                <PlusIcon sx={{ color: "#0000008A" }} />
              </Box>

              <Box
                sx={{ cursor: "pointer", paddingTop: "3px" }}
                onClick={() => {
                  copyTextField(field.id);
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 14.5H11C10.725 14.5 10.5 14.275 10.5 14V6C10.5 5.725 10.725 5.5 11 5.5H15.3781L17.5 7.62188V14C17.5 14.275 17.275 14.5 17 14.5ZM11 16H17C18.1031 16 19 15.1031 19 14V7.62188C19 7.225 18.8406 6.84375 18.5594 6.5625L16.4406 4.44063C16.1594 4.15938 15.7781 4 15.3813 4H11C9.89687 4 9 4.89688 9 6V14C9 15.1031 9.89687 16 11 16ZM7 8C5.89688 8 5 8.89687 5 10V18C5 19.1031 5.89688 20 7 20H13C14.1031 20 15 19.1031 15 18V17H13.5V18C13.5 18.275 13.275 18.5 13 18.5H7C6.725 18.5 6.5 18.275 6.5 18V10C6.5 9.725 6.725 9.5 7 9.5H8V8H7Z"
                    fill="black"
                    fillOpacity="0.54"
                  />
                </svg>
              </Box>
              <Box
                sx={{ cursor: "pointer", paddingTop: "3px" }}
                onClick={() => {
                  removeTextField(field.id);
                }}
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.3281 9.6125L13.7344 10.5H18.2656L17.6719 9.6125C17.625 9.54375 17.5469 9.5 17.4625 9.5H14.5344C14.45 9.5 14.3719 9.54062 14.325 9.6125H14.3281ZM18.9219 8.78125L20.0688 10.5H20.5H22H22.25C22.6656 10.5 23 10.8344 23 11.25C23 11.6656 22.6656 12 22.25 12H22V21.5C22 22.8813 20.8813 24 19.5 24H12.5C11.1188 24 10 22.8813 10 21.5V12H9.75C9.33437 12 9 11.6656 9 11.25C9 10.8344 9.33437 10.5 9.75 10.5H10H11.5H11.9313L13.0781 8.77812C13.4031 8.29375 13.95 8 14.5344 8H17.4625C18.0469 8 18.5938 8.29375 18.9187 8.77812L18.9219 8.78125ZM11.5 12V21.5C11.5 22.0531 11.9469 22.5 12.5 22.5H19.5C20.0531 22.5 20.5 22.0531 20.5 21.5V12H11.5ZM14 14V20.5C14 20.775 13.775 21 13.5 21C13.225 21 13 20.775 13 20.5V14C13 13.725 13.225 13.5 13.5 13.5C13.775 13.5 14 13.725 14 14ZM16.5 14V20.5C16.5 20.775 16.275 21 16 21C15.725 21 15.5 20.775 15.5 20.5V14C15.5 13.725 15.725 13.5 16 13.5C16.275 13.5 16.5 13.725 16.5 14ZM19 14V20.5C19 20.775 18.775 21 18.5 21C18.225 21 18 20.775 18 20.5V14C18 13.725 18.225 13.5 18.5 13.5C18.775 13.5 19 13.725 19 14Z"
                    fill="black"
                    fillOpacity="0.54"
                  />
                </svg>
              </Box>
            </Box>
          </Box>
        </>
      ))}
    </>
  );
};
