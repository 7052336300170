import { Box } from "@mui/material";
import { ProfileSettingNavigation } from "../../molecule/profileSettingNavigation";
import { PrivacyAndSecurity } from "../../page/profileSettings/privacyAndSecurity";
import { PrivacySecuritySetting } from "../../organism/profileSettings/privacySecuritySetting";

export const PrivacyAndSecurityTemplate = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ProfileSettingNavigation selectedMenu="PrivacyAndSecurity" />
        <Box
          sx={{
            width: "85%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            background: "#ffffff",
            borderRight: "1px solid #CFE6FC",
          }}
        >
          <PrivacySecuritySetting />
        </Box>
      </Box>
    </>
  );
};
