import { useEffect } from "react";
import { PrivacyAndSecurityTemplate } from "../../template/profileSettings/privacyAndSecurityTemplate";
import { currentActiveScreenState } from "../../../libs/state-management-lib";

export const PrivacyAndSecurity = () => {
  useEffect(() => {
    if (window.location.pathname.includes("/profilesettings")) {
      currentActiveScreenState.setState(
        "/profilesettings/privacy-and-security"
      );
    } else {
      currentActiveScreenState.setState("/settings/organization");
    }
  }, []);

  return (
    <>
      <PrivacyAndSecurityTemplate />
    </>
  );
};
