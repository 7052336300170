
import { Switch, SwitchProps, styled } from "@mui/material";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 22,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 18,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#FFFFFF',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#0D80F2',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 18,
    height: 18,
    borderRadius: 10,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
    opacity: 1,
    backgroundColor:"#CFE5FC",
    boxSizing: 'border-box',
  },
}));

interface IiosSwitch {
  checked: any;
}

export const IosSwitch = (props: IiosSwitch) => {
  return (
    <>
    <AntSwitch checked={props.checked}  inputProps={{ "aria-label": "controlled" }} />
    </>
  );
};
