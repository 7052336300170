import { IconButton, SxProps, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import MuiPhoneNumber from "material-ui-phone-number";
import { Theme } from "@material-ui/core";

interface IPhoneNumbertextField {
  label?: string;
  placeholder?: string;
  value: string;
  handleChange: (e: any) => void;
  errorMessage?: string;
  disabled?: boolean;
}

export const PhoneNumberTextField = (props: IPhoneNumbertextField) => {
  return (
    <>
      <MuiPhoneNumber
        sx={{
          borderRadius: "8px",
          fontSize: "12px",
          fontWeight: "400",
          backgroundColor: "#F5FAFE",
          color: (theme: { palette: { secondary: { contrastText: any } } }) =>
            theme.palette.secondary.contrastText,
          "& .MuiSvgIcon-root": { color: "#0D80F2" },
          "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: !!props.errorMessage
                ? "1px solid red !important"
                : "1px solid #0D80F2 !important",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: !!props.errorMessage
                ? "2px solid red !important"
                : "2px solid #0D80F2 !important",
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: !!props.errorMessage
              ? "red"
              : "#CFE5FC",
            borderRadius: "8px !important",
          },
          "& .MuiInputLabel-root": { display: "none" },
          "& .MuiPhoneNumber-flagButton": {
            padding: "0px !important",
          },
          "& .Mui-disabled": {
            background: "#F1F2F4 !important",
            borderColor: "#ABB2BA !important",
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #ABB2BA !important",
            },
            borderRadius: "8px",
          },
        }}
        fullWidth
        size="small"
        variant="outlined"
        defaultCountry={"us"}
        onlyCountries={["us"]}
        dropdownClass="phoneNumberDropdown"
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.handleChange}
        disabled={!!props.disabled && props.disabled ? true : false}
        disableDropdown={!!props.disabled && props.disabled ? true : false}
      />
      {!!props.errorMessage && (
        <span style={{ fontSize: "10px", fontWeight: "400", color: "red" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "22.4px",
            }}
            className="red-text"
          >
            {props.errorMessage}
          </Typography>
        </span>
      )}
    </>
  );
};
