import { Box, MenuItem, Typography } from "@mui/material";
import { TextFieldLableTypography } from "../../atom/typography/textFieldLableTypography";
import { CalendarDropDown } from "../../atom/dropdown/calendarDropdown";
import {
  AImicIcon,
  AutoAssignIcon,
  CalendarIcon,
  CalendarMonthIcon,
  CalendarViewMonthlyWeekIcon,
  CalendarWeekIcon,
  ListIcon,
  LogIcon,
  PrintIcon,
} from "../../atom";
import { OutlinedButton } from "../../atom/buttons/outlinedButton";
import { ContainedButton } from "../../atom/buttons/containedButton";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  LocationDetail,
  DepartmentDetail,
  UnitDetail,
} from "../../../libs/model-lib";
import { locationDetailListState } from "../../../libs/state-management-lib/locationState";
import {
  departmentListState,
  loadingIconState,
  shiftListState,
} from "../../../libs/state-management-lib";
import {
  getAllDepartmentsByLocationId,
  getSubDepartmentByDepartmentId,
  getSubDepartmentByLocationId,
} from "../../../libs/services-lib";
import { EmptyGuid } from "../../../libs/common-lib";
import { SplitButton } from "../../atom/buttons/splitButton";
import { useSearchShiftContext } from "../../../libs/context-lib/shiftDetailContext";
import { format } from "date-fns";

interface ICalendarHeaderContainer {
  options:any;
  locationList: LocationDetail[];
  departmentList: DepartmentDetail[];
  subDepartmentList: UnitDetail[];
  handleShowAdvancedFilterModal: () => void;
  handleShowShareShiftDrawer: (
    selectedLocationId: string,
    selectedDepartment: string
  ) => void;

  handleLocationChange: (selectedLocation: LocationDetail) => void;
  handleDepartmentChange: (selectedDepartmentList: DepartmentDetail[]) => void;
  handleShowCreateShiftDrawer: (index: number) => void;
}

export const CalendarHeaderContainer = (props: ICalendarHeaderContainer) => {
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [departmentList, setDepartmentList] = useState<DepartmentDetail[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [selectedSubDepartment, setSelectedSubDepartment] =
    useState<string>("");
  const [subDepartmentList, setSubDepartmentList] = useState<UnitDetail[]>([]);
  const [shiftType, setShiftType] = useState<number>(0);
  const { searchShift, setSearchShift, dateRange } = useSearchShiftContext();

  useEffect(() => {
    if (!!props.locationList && props.locationList.length > 0) {
      let departIdList: string[] = [];

      if (!!!selectedLocation) {
        setSelectedLocation(props.locationList[0].id);
        let deptList: DepartmentDetail[] = [];

        if (!!props.departmentList && props.departmentList.length > 0) {
          deptList = props.departmentList.filter(
            (cc: DepartmentDetail) => cc.location_Id == props.locationList[0].id
          );

          setDepartmentList(deptList);
          setSelectedDepartment(EmptyGuid);

          let subDeptList: UnitDetail[] = [];

          deptList.forEach((cc: DepartmentDetail) => {
            departIdList.push(cc.id);

            if (
              !!props.subDepartmentList &&
              props.subDepartmentList.length > 0
            ) {
              subDeptList = subDeptList.concat(
                props.subDepartmentList.filter(
                  (ee: UnitDetail) => ee.department_id == cc.id
                )
              );
            }
          });

          setSubDepartmentList(subDeptList);
          setSelectedSubDepartment(EmptyGuid);
        } else {
          setSelectedDepartment(EmptyGuid);
          setSelectedSubDepartment(EmptyGuid);
        }

        let locDetail = props.locationList.filter(
          (loc: LocationDetail) => loc.id == props.locationList[0].id
        );

        if (!!locDetail && locDetail.length > 0) {
          props.handleLocationChange(locDetail[0]);
          props.handleDepartmentChange(deptList);
        }
      }

      if (!!selectedLocation && (!!!selectedDepartment || selectedDepartment == EmptyGuid)) {
        let deptList: DepartmentDetail[] = [];

        if (!!props.departmentList && props.departmentList.length > 0) {
          deptList = props.departmentList.filter(
            (cc: DepartmentDetail) => cc.location_Id == selectedLocation
          );

          setDepartmentList(deptList);
          setSelectedDepartment(EmptyGuid);

          let subDeptList: UnitDetail[] = [];

          deptList.forEach((cc: DepartmentDetail) => {
            departIdList.push(cc.id);

            if (
              !!props.subDepartmentList &&
              props.subDepartmentList.length > 0
            ) {
              subDeptList = subDeptList.concat(
                props.subDepartmentList.filter(
                  (ee: UnitDetail) => ee.department_id == cc.id
                )
              );
            }
          });

          setSubDepartmentList(subDeptList);
          setSelectedSubDepartment(EmptyGuid);
        }

        if (!!deptList && deptList.length > 0) {
          props.handleDepartmentChange(deptList);
        } else {
          setSelectedDepartment(EmptyGuid);
          setSelectedSubDepartment(EmptyGuid);
        }
      }

      if(!!selectedDepartment && selectedDepartment != EmptyGuid) {
        departIdList = [selectedDepartment];
      }

      let fromDate: string = "";
      let toDate: string = "";

      if (!!dateRange && dateRange.length > 0) {
        fromDate = format(dateRange[0], "MM-dd-yyyy");
        toDate = format(dateRange[dateRange.length - 1], "MM-dd-yyyy");
      }

      setSearchShift({
        ...searchShift,
        department_ids: departIdList,
        location_id: props.locationList[0].id,
        sub_department_ids: [],
        from_date: fromDate,
        to_date: toDate,
      });
    }
  }, [props.locationList, props.departmentList, dateRange]);

  const handleLocationChange = async (e: any) => {
    loadingIconState.setState(true);
    shiftListState.setState({ shiftList: [] });
    setSelectedLocation(e.target.value);
    setDepartmentList([]);
    setSelectedDepartment(EmptyGuid);
    setSelectedSubDepartment(EmptyGuid);

    if (!!props.departmentList && props.departmentList.length > 0) {
      let deptList = props.departmentList.filter(
        (dd: DepartmentDetail) => dd.location_Id == e.target.value
      );

      if (!!deptList && deptList.length > 0) {
        setDepartmentList(deptList);
        setSubDepartmentList([]);
        setSelectedDepartment(EmptyGuid);
        setSelectedSubDepartment(EmptyGuid);

        let subDeptList: UnitDetail[] = [];
        let departIdList: string[] = [];

        deptList.forEach((cc: DepartmentDetail) => {
          departIdList.push(cc.id);

          if (!!props.subDepartmentList && props.subDepartmentList.length > 0) {
            subDeptList = subDeptList.concat(
              props.subDepartmentList.filter(
                (ee: UnitDetail) => ee.department_id == cc.id
              )
            );
          }
        });

        setSubDepartmentList(subDeptList);

        setSearchShift({
          ...searchShift,
          department_ids: departIdList,
          location_id: e.target.value,
          sub_department_ids: [],
        });

        props.handleDepartmentChange(deptList);
      } else {
        setSearchShift({
          ...searchShift,
          department_ids: [],
          location_id: e.target.value,
          sub_department_ids: [],
        });

        props.handleDepartmentChange([]);
      }
    } else {
      setSearchShift({
        ...searchShift,
        department_ids: [],
        location_id: e.target.value,
        sub_department_ids: [],
      });

      props.handleDepartmentChange([]);
    }

    let locDetail = props.locationList.filter(
      (loc: LocationDetail) => loc.id == e.target.value
    );

    if(!!locDetail && locDetail.length > 0) {
      props.handleLocationChange(locDetail[0])
    }

    
    loadingIconState.setState(false);
  };

  const handleDepartmentChange = async (e: any) => {
    setSelectedDepartment(e.target.value);
    setSubDepartmentList([]);
    setSelectedSubDepartment(EmptyGuid);

    if (e.target.value === EmptyGuid) {
      if (!!departmentList && departmentList.length > 0) {
        let subDeptList: UnitDetail[] = [];
        let departIdList: string[] = [];

        departmentList.forEach((cc: DepartmentDetail) => {
          departIdList.push(cc.id);

          if (!!props.subDepartmentList && props.subDepartmentList.length > 0) {
            subDeptList = subDeptList.concat(
              props.subDepartmentList.filter(
                (ee: UnitDetail) => ee.department_id == cc.id
              )
            );
          }
        });

        setSubDepartmentList(subDeptList);

        setSearchShift({
          ...searchShift,
          department_ids: departIdList,
          sub_department_ids: [],
        });

        props.handleDepartmentChange(departmentList);
      }
    } else {
      let deptSelected = props.departmentList.filter(
        (dd: DepartmentDetail) => dd.id == e.target.value
      );

      if (!!deptSelected && deptSelected.length > 0) {
        let subDeptList: UnitDetail[] = [];
        let departIdList: string[] = [];

        deptSelected.forEach((cc: DepartmentDetail) => {
          departIdList.push(cc.id);

          if (!!props.subDepartmentList && props.subDepartmentList.length > 0) {
            subDeptList = subDeptList.concat(
              props.subDepartmentList.filter(
                (ee: UnitDetail) => ee.department_id == cc.id
              )
            );
          }
        });

        setSubDepartmentList(subDeptList);

        setSearchShift({
          ...searchShift,
          department_ids: departIdList,
          sub_department_ids: [],
        });

        props.handleDepartmentChange(deptSelected);
      }
    }
  };

  const handleShiftTypeChange = (e: any) => {
    setShiftType(e.target.value);

    setSearchShift({
      ...searchShift,
      shift_type: parseInt(e.target.value),
    });
  };

  const handleSubDepartmentChange = (e: any) => {
    setSelectedSubDepartment(e.target.value);

    if (e.target.value === EmptyGuid) {
      setSearchShift({
        ...searchShift,
        sub_department_ids: [],
      });
    } else {
      let subDepartmentIdList = [e.target.value];
      setSearchShift({
        ...searchShift,
        sub_department_ids: subDepartmentIdList,
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          border: "1px solid #C4D9EF",
          padding: "16px",
          gap: "24px",
          background: "#FFFFFF",
          height: "calc(12% - 16px)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            height: "100%",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "70%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "14%",
                }}
              >
                <TextFieldLableTypography text={"Location"} />
                <CalendarDropDown
                  sx={null}
                  handleChange={handleLocationChange}
                  value={selectedLocation}
                >
                  {props.locationList.map((cc: LocationDetail) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      {cc.name}
                    </MenuItem>
                  ))}
                </CalendarDropDown>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "16%",
                }}
              >
                <TextFieldLableTypography text={"Department"} />
                <CalendarDropDown
                  sx={null}
                  handleChange={handleDepartmentChange}
                  value={selectedDepartment}
                >
                  <MenuItem
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "400",
                    }}
                    value={EmptyGuid}
                  >
                    All department
                  </MenuItem>
                  {departmentList.map((cc: DepartmentDetail) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      {cc.name}
                    </MenuItem>
                  ))}
                </CalendarDropDown>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "14%",
                }}
              >
                <TextFieldLableTypography text={"Sub-department"} />
                <CalendarDropDown
                  sx={null}
                  handleChange={handleSubDepartmentChange}
                  value={selectedSubDepartment}
                >
                  <MenuItem
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "400",
                    }}
                    value={EmptyGuid}
                  >
                    None
                  </MenuItem>
                  {subDepartmentList.map((cc: UnitDetail) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      {cc.name}
                    </MenuItem>
                  ))}
                </CalendarDropDown>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "14%",
                }}
              >
                <TextFieldLableTypography text={"Shift type"} />
                <CalendarDropDown
                  sx={null}
                  handleChange={handleShiftTypeChange}
                  value={shiftType}
                >
                  <MenuItem value={0}>All shift type</MenuItem>
                  <MenuItem value={1}>Day</MenuItem>
                  <MenuItem value={2}>Evening</MenuItem>
                  <MenuItem value={3}>Night</MenuItem>
                </CalendarDropDown>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  height: "100%",
                  gap: "10px",
                  paddingTop: "20px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    height: "40px",
                    border: "1px solid #E3E5E8",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0 1px 0 1px",
                    gap: "2px",
                  }}
                >
                  <Box
                    sx={{
                      width: "36px",
                      height: "36px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#0D80F2",
                      borderRadius: "8px",
                      color: "#FFFFFF",
                    }}
                  >
                    <CalendarIcon sx={{ cursor: "pointer" }} />
                  </Box>
                  <Box
                    sx={{
                      width: "36px",
                      height: "36px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#FFFFFF",
                      borderRadius: "8px",
                      color: "rgba(0, 0, 0, 0.38)",
                    }}
                  >
                    <ListIcon sx={{ 
                      // color: "#0D80F2", 
                      // cursor: "pointer", 
                      color:"#ABB2BA" }} />
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "40px",
                    border: "1px solid #E3E5E8",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0 1px 0 1px",
                    gap: "2px",
                  }}
                >
                  <Box
                    sx={{
                      width: "36px",
                      height: "36px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#0D80F2",
                      borderRadius: "8px",
                      color: "#FFFFFF",
                    }}
                  >
                    <CalendarWeekIcon sx={{ cursor: "pointer" }} />
                  </Box>

                  <Box
                    sx={{
                      width: "36px",
                      height: "36px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#FFFFFF",
                      borderRadius: "8px",
                      color: "#0D80F2",
                    }}
                  >
                    <CalendarViewMonthlyWeekIcon sx={{ 
                      //cursor: "pointer" ,
                      color:"#ABB2BA" }} />
                  </Box>

                  <Box
                    sx={{
                      width: "36px",
                      height: "36px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#FFFFFF",
                      borderRadius: "8px",
                      color: "#0D80F2",
                    }}
                  >
                    <CalendarMonthIcon sx={{ 
                      //cursor: "pointer" ,
                      color:"#ABB2BA" }} />
                  </Box>
                </Box>
              </Box>

              {/* <Box
                sx={{
                  width: "149px",
                  height: "40px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",

                  padding: "8x 16px 8px 16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "600",
                    color: "#0D80F2",
                    cursor: "pointer",
                  }}
                  onClick={props.handleShowAdvancedFilterModal}
                >
                  Advanced filters
                </Typography>
              </Box> */}
            </Box>
          </Box>

          <Box
            sx={{
              height: "100%",
              width: "30%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "16px",
                paddingTop: "20px",
              }}
            >
              {/* <Box
                sx={{
                  height: "40px",
                  width: "40px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #0D80F2",
                  borderRadius: "8px",
                  color: "#0D80F2",
                }}
              >
                <AutoAssignIcon sx={null} />
              </Box>
              <Box
                sx={{
                  height: "40px",
                  width: "40px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #0D80F2",
                  borderRadius: "8px",
                  color: "#0D80F2",
                }}
              >
                <AImicIcon sx={null} />
              </Box>
              <Box
                sx={{
                  height: "40px",
                  width: "40px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #0D80F2",
                  borderRadius: "8px",
                  color: "#0D80F2",
                }}
              >
                <LogIcon sx={null} />
              </Box>
              <Box
                sx={{
                  height: "40px",
                  width: "40px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #0D80F2",
                  borderRadius: "8px",
                  color: "#0D80F2",
                }}
              >
                <PrintIcon sx={null} />
              </Box> */}
              <OutlinedButton
                sx={{ height: "40px",}}
                text={"Share open shifts"}
                handleClick={() => {
                  props.handleShowShareShiftDrawer(
                    selectedLocation,
                    selectedDepartment
                  );
                }}
              />
              {/* <ContainedButton
                sx={{ height: "40px" }}
                text={"Publish"}
                handleClick={() => {}}
              /> */}
              <SplitButton options={props.options} name={"New shift"} handleSplitButtonClick={props.handleShowCreateShiftDrawer}/>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
