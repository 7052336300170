import { Box, Chip, Divider, IconButton, ListItemButton, Menu } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  activeConfirmationPopupState,
  confirmationPopupTitleState,
  deleteConfirmationPopupState,
  departmentListState,
  inActiveConfirmationPopupState,
  locationDetailListState,
  primaryQualificationListState,
  roleListState,
  unitDetailState,
  unitListState,
} from "../../../libs/state-management-lib";
import {
  ActiveConfirmationPopup,
  ActiveIcon,
  ContainedButton,
  ContainedDeleteButton,
  DeleteConfirmationPopup,
  DeleteIcon,
  InActiveConfirmationPopup,
  OutlinedButton,
  SettingBox,
  Typography14_400,
  Typography14_600,
  Typography16_400,
  Typography16_600,
  Typography32_600,
} from "../../atom";
import { IosSwitch } from "../../atom/Switch/iosSwitch";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  DepartmentDetail,
  LocationDetail,
  UnitDetailList,
} from "../../../libs/model-lib";
import {
  getSubDepartmentById,
  getSubDepartmentList,
} from "../../../libs/services-lib";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { transform } from "typescript";

interface IRolesDashboard {
  handleActiveRole: (activeRoleId: string) => void;
  handleInActiveRole: (inActiveRoleId: string) => void;
  handleRoleDashboardCallBack: (roleId: string) => void;
  handleDleteRole: (activeRoleId: string) => void;
  handleAddRolesClick: () => void;
}

export const RolesDashboard = (props: IRolesDashboard) => {
  const [roleList, setRolelList] = useState<any[]>(
    roleListState.getState().roleList
  );
  const [qualificationList, setQualificationList] = useState<any[]>([]);
  const [rowData, setRowData] = useState<any>([]);
  const [activeChecked, setActiveChecked] = useState<boolean>(false);
  const [menuState, setMenuState] = useState<{
    anchorEl: HTMLElement | null;
    rowId: number | null;
  }>({ anchorEl: null, rowId: null });
  const [locDetailList, setLocDetailList] = useState<LocationDetail[]>(
    locationDetailListState.getState().locationDetailList
  );
  const [departmentList, setDepartmentList] = useState<DepartmentDetail[]>(
    departmentListState.getState().departmentDetailList
  );
  const [unitList, setunitList] = useState<UnitDetailList[]>(
    unitListState.getState().unitDetailList
  );
  const [roleActionState, setRoleActionState] = useState<{
    anchorEl: HTMLElement | null;
    rowId: number | null;
  }>({ anchorEl: null, rowId: null });
  const [activeRoleId, setActiveRoleId] = useState("");
  const [showActiveConfirmationPopup, setshowActiveConfirmationPopup] =
    useState(false);
  const [showInActiveConfirmationPopup, setshowInActiveConfirmationPopup] =
    useState(false);
  const [showDeleteConfirmationPopup, setshowDeleteConfirmationPopup] =
    useState(false);

  useLayoutEffect(() => {
    const roleDetailListStateSub = roleListState.subscribe(async (state) => {
      setRolelList(state.roleList);
    });

    const locationDetailListStateSub = locationDetailListState.subscribe(
      async (state) => {
        setLocDetailList(state.locationDetailList);
      }
    );

    const departmentListStateSub = departmentListState.subscribe(
      async (state) => {
        setDepartmentList(state.departmentDetailList);
      }
    );

    const unitListStateSub = unitListState.subscribe(async (state) => {
      setunitList(state.unitDetailList);
    });

    const primaryQualificationListStateSub =
      primaryQualificationListState.subscribe(async (state) => {
        setQualificationList(state.primaryQualificationList);
      });

    const inActiveConfirmationPopupStateSub =
      inActiveConfirmationPopupState.subscribe(async (state) => {
        setshowInActiveConfirmationPopup(state);
      });

    const activeConfirmationPopupStateSub =
      activeConfirmationPopupState.subscribe(async (state) => {
        setshowActiveConfirmationPopup(state);
      });

    const deleteConfirmationPopupStateSub =
      deleteConfirmationPopupState.subscribe(async (state) => {
        setshowDeleteConfirmationPopup(state);
      });

    return () => {
      roleDetailListStateSub();
      primaryQualificationListStateSub();
      locationDetailListStateSub();
      departmentListStateSub();
      unitListStateSub();
      inActiveConfirmationPopupStateSub();
      activeConfirmationPopupStateSub();
      deleteConfirmationPopupStateSub();
    };
  }, []);

  useEffect(() => {
    handleGetRoleListData();
  }, []);

  const handleGetRoleListData = () => {
    const modifiedData = roleListState.getState().roleList.map((cc) => ({
      id: cc.id,
      name: cc.role_name,
      abbreviation: cc.abbreviation,
      primaryQualification: cc.qualifications[0]?.qualification_id ?? [],
      locations: cc.location_id,
      department: cc.department_id,
      unit: cc.sub_department_id,
      status: cc.is_active,
    }));
    setRowData(modifiedData);
  };

  const rows = roleList.map((cc) => ({
    id: cc.id,
    name: cc.role_name,
    abbreviation: cc.abbreviation,
    primaryQualification: cc.qualifications[0]?.qualification_id ?? [],
    locations: cc.location_id,
    department: cc.department_id,
    unit: cc.sub_department_id,
    status: cc.is_active,
  }));

  const handleClick = (event: React.MouseEvent<HTMLElement>, params: any) => {
    setMenuState({ anchorEl: event.currentTarget, rowId: params.id });
    setActiveChecked(params.row.status);
  };

  const handleClose = () => {
    setMenuState({ anchorEl: null, rowId: null });
  };

  const getQualification = (qualificationId: number) => {
    if (!!!qualificationId) {
      return "";
    }
    let qualificationIdName = "";
    primaryQualificationListState
      .getState()
      .primaryQualificationList.forEach((element) => {
        if (qualificationId == element?.id) {
          qualificationIdName = !!qualificationIdName
            ? qualificationIdName + ", "
            : "";
          qualificationIdName = qualificationIdName + element?.name;
        }
      });

    return qualificationIdName;
  };

  const getLocationName = (locationId: string[]) => {
    if (!!!locationId) {
      return "";
    }
    let locationName = "";
    locationDetailListState.getState().locationDetailList.forEach((element) => {
      if (locationId.find((cc) => cc === element.id)) {
        locationName = !!locationName ? locationName + ", " : "";
        locationName = locationName + element.name;
      }
    });

    return locationName;
  };

  const getDepartmentName = (departmentId: string[]) => {
    if (!!!departmentId) {
      return "";
    }
    let departmentName = "";
    departmentListState.getState().departmentDetailList.forEach((element) => {
      if (departmentId.find((cc) => cc === element.id)) {
        departmentName = !!departmentName ? departmentName + ", " : "";
        departmentName = departmentName + element.name;
      }
    });

    return departmentName;
  };

  const getSubDepartmentName = (subDepId: string[]) => {
    if (!!!subDepId) {
      return "";
    }
    let subDepName = "";

    unitListState.getState().unitDetailList.forEach((element) => {
      if (subDepId.find((cc) => cc === element.id)) {
        subDepName = !!subDepName ? subDepName + ", " : "";
        subDepName = subDepName + element.subdepartment_name;
      }
    });

    return subDepName;
  };

  const handleActiveClick = (params: any) => {
    if (activeChecked) {
      activeConfirmationPopupState.setState(true);
    } else {
      inActiveConfirmationPopupState.setState(true);
    }
    handleClose();
    let roleId = params.id.toString();
    setActiveRoleId(roleId);
    let roleName = params.row.name.toString();
    confirmationPopupTitleState.setState(roleName);
    setRoleActionState({ anchorEl: null, rowId: null });
  };

  const handleDeleteClick = (params: any) => {
    let roleId = params.id.toString();
    setActiveRoleId(roleId);
    if (activeChecked) {
      let roleName = params.row.name.toString();
      confirmationPopupTitleState.setState(roleName);
      deleteConfirmationPopupState.setState(true);
    }
    handleClose();
  };

  const handleDleteRole = () => {
    props.handleDleteRole(activeRoleId);
  };

  const handleActiveRole = () => {
    props.handleActiveRole(activeRoleId);
  };

  const handleInActiveRole = () => {
    props.handleInActiveRole(activeRoleId);
  };

  const handleInactivePopupClick = () => {
    inActiveConfirmationPopupState.setState(false);
  };

  const handleCloseClick = () => {
    activeConfirmationPopupState.setState(false);
  };

  const handleCloseDeletePopupClick = () => {
    deleteConfirmationPopupState.setState(false);
  };

  const handleEditRoleClick = async (roleId: any) => {
    props.handleRoleDashboardCallBack(roleId.id);
  };

  const handleAddRole = () => {
    props.handleAddRolesClick();
  };

  return (
    <>
      {roleList.length <= 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "24px",
              width: "100%",
              marginTop: "6%",
            }}
          >
            <SearchIcon fontSize="large"  sx={{ color: "rgba(61, 153, 245, 0.5)" }} />
            <Typography32_600 text="No active roles" color="rgba(61, 153, 245, 0.5)" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "24px",
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "8px 16px",
                    gap: "8px",
                    border: "1px solid #0D80F2",
                    borderRadius: "8px",
                    height: "124px",
                    width: "370px",
                    cursor: "pointer",
                  }}
                  onClick={handleAddRole}
                >
                  <AddIcon fontSize="medium" sx={{ color: "#0D80F2" }} />
                  <Typography16_600
                    text="Create from scratch"
                    color="#0D80F2"
                  />
                </Box>
                <Box
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginLeft: "5%",
                  }}
                >
                  <Typography16_400
                    text="Create roles from scratch, providing"
                    color="#8F98A3"
                  />
                  <Typography16_400 text="mandatory details." color="#8F98A3" />
                </Box>
              </Box>
              <Divider
              sx={{
                borderColor: "#CFE5FC",
              }}
              orientation="vertical"
              flexItem
            />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "8px 16px",
                    gap: "8px",
                    border: "1px solid #ABB2BA",
                    borderRadius: "8px",
                    height: "124px",
                    width: "370px",
                    backgroundColor:"#F1F2F4"
                  }}
                >
                  <AddIcon fontSize="medium" sx={{ color: "#8F98A3" }} />
                  <Typography16_600
                    text="Start with template"
                    color="#8F98A3"
                  />
                </Box>
                <Box
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginLeft: "5%",
                  }}
                >
                  <Typography16_400
                    text="Create roles using a template with placeholder"
                    color="#8F98A3"
                  />
                  <Typography16_400
                    text="details and edit the letter as needed."
                    color="#8F98A3"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {roleList.length > 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "6px",
              zIndex: 1,
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                borderRadius: "8px",
                width: "100%",
                height: "90%",
              }}
            >
              <DataGrid
                sx={{
                  width: "100%",
                  height: "100%",
                  "& .theme--header": {
                    backgroundColor: "#F9FAFA",
                  },
                  "& .MuiDataGrid-row": {
                    borderBottom: "1px solid #E3E5E8",
                  },
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "#F5FAFE",
                  },
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    background: "#F9FAFA",
                  },
                  "& .MuiDataGrid-columnHeaderTitle" : {
                    fontWeight:"600",
                    color:"rgba(115, 127, 140, 1)"
                  },
                  "& .MuiCheckbox-root": {
                    color: "#ABB2BA !important",
                  },
                  "& .Mui-checked": {
                    color: "#0D80F2 !important",
                  },
                  "& .MuiDataGrid-scrollbarFiller": {
                    backgroundColor: "#F9FAFA",
                  },
                  "& .MuiDataGrid-root .MuiDataGrid-filler": {
                    backgroundColor: "#F9FAFA",
                  },
                  border: "1px solid #CFE5FC",
                }}
                key={rowData.id}
                onRowClick={handleEditRoleClick}
                rows={rows}
                columns={[
                  {
                    field: "name",
                    headerName: "Role name",
                    headerClassName: "theme--header",
                    flex: 1,
                    renderCell: (params: any) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "8px 4px",
                            height: "32px",
                            justifyContent: "flex-start",
                            cursor: "pointer",
                          }}
                        >
                          <Typography14_600
                            text={params.row.name}
                            color="#0D80F2"
                          />
                        </Box>
                      );
                    },
                  },
                  {
                    field: "abbreviation",
                    headerName: "Abbreviation",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: false,
                  },
                  {
                    field: "primaryQualification",
                    headerName: "Primary qualification",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: false,
                    valueGetter: (value, row) =>
                      `${getQualification(row.primaryQualification)} `,
                  },
                  {
                    field: "locations",
                    headerName: "Locations",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: false,
                    valueGetter: (value, row) =>
                      `${getLocationName(row.locations)} `,
                  },
                  {
                    field: "department",
                    headerName: "Department",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: false,
                    valueGetter: (value, row) =>
                      `${getDepartmentName(row.department)} `,
                  },
                  {
                    field: "unit",
                    headerName: "Sub-department",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: false,
                    valueGetter: (value, row) =>
                      `${getSubDepartmentName(row.unit)} `,
                  },
                  {
                    field: "status",
                    headerName: "Status",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: false,
                    renderCell: (params: any) => {
                      return (
                        <Chip
                          label={params.row.status ? "Active" : "Inactive"}
                          sx={{
                            height: "22px",
                            background: params.row.status
                              ? "#F7FDF8"
                              : "#ffece5",
                            border: "1px solid #EBFAEE",
                            borderRadius: "4px",
                            color: params.row.status ? "#29A347" : "#ff5722",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        />
                      );
                    },
                  },
                  {
                    field: "actions",
                    type: "actions",
                    headerClassName: "theme--header",
                    cellClassName: "actions",
                    align: "right",
                    width: 60,
                    renderCell: (params: any) => {
                      const isOpen = menuState.rowId === params.id;
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                          }}
                          key={`action-${params.id}`}
                        >
                          <Box
                            sx={{ color: "#0D80F2" }}
                            onClick={(event) => handleClick(event, params)}
                          >
                            <IconButton
                              sx={{
                                background: "#FFFFFF",
                                color: "#0D80F2",
                                borderRadius: "10px !important",
                              }}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </Box>
                          <Menu
                            anchorEl={menuState.anchorEl}
                            id="account-menu"
                            open={isOpen}
                            onClose={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: "visible",
                                width: "228px",
                                borderRadius: "8px",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&::before": {
                                  content: '""',
                                  display: "bstaffk",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            {/* <ListItemButton
                              sx={{ gap: "7px" }}
                              key={`menu-item-1-${params.id}`}
                              onClick={() => handleDeleteClick(params)}
                            >
                              <DeleteIcon sx={{ color: "#0D80F2" }} />{" "}
                              <Typography16_400 text="Delete" color="#031A30" />
                            </ListItemButton> */}
                            <ListItemButton
                              sx={{ gap: "7px" }}
                              key={`menu-item-2-${params.id}`}
                              onClick={() => handleActiveClick(params)}
                            >
                              <ActiveIcon sx={{ color: "#0D80F2" }} />
                              <Typography16_400 text="Active" color="#031A30" />
                              <Box sx={{ marginLeft: "35%" }}>
                                <IosSwitch checked={activeChecked} />
                              </Box>
                            </ListItemButton>
                          </Menu>
                        </Box>
                      );
                    },
                  },
                ]}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [],
                    },
                  },
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 20, 30]}
                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                checkboxSelection
              />
            </Box>
          </Box>
          <ActiveConfirmationPopup
            title={`Deactivate ${confirmationPopupTitleState.getState()}`}
            content={`Are you sure you want to deactivate “${confirmationPopupTitleState.getState()}” role?`}
            open={showActiveConfirmationPopup}
            handleClick={handleCloseClick}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
                gap: "16px",
              }}
            >
              <OutlinedButton
                sx={{ height: "40px" }}
                text="Cancel"
                handleClick={handleCloseClick}
              />
              <ContainedDeleteButton
                sx={{ height: "40px" }}
                text="Deactivate role"
                handleClick={handleActiveRole}
              />
            </Box>
          </ActiveConfirmationPopup>
          <InActiveConfirmationPopup
            title={`Activate ${confirmationPopupTitleState.getState()}`}
            content={`Are you sure you want to activate “${confirmationPopupTitleState.getState()}” role?`}
            open={showInActiveConfirmationPopup}
            handleClick={handleInactivePopupClick}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
                gap: "16px",
              }}
            >
              <OutlinedButton
                sx={{ height: "40px" }}
                text="Cancel"
                handleClick={handleInactivePopupClick}
              />
              <ContainedButton
                sx={{ height: "40px" }}
                text="Activate role"
                handleClick={handleInActiveRole}
              />
            </Box>
          </InActiveConfirmationPopup>
          <DeleteConfirmationPopup
            title={`Delete ${confirmationPopupTitleState.getState()} Role`}
            content={`Are you sure you want to delete “${confirmationPopupTitleState.getState()}” role?`}
            open={showDeleteConfirmationPopup}
            handleClick={handleCloseDeletePopupClick}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
                gap: "16px",
              }}
            >
              <OutlinedButton
                sx={{ height: "40px" }}
                text="Cancel"
                handleClick={handleCloseDeletePopupClick}
              />
              <ContainedDeleteButton
                sx={{ height: "40px" }}
                text="Delete role"
                handleClick={handleDleteRole}
              />
            </Box>
          </DeleteConfirmationPopup>
        </>
      )}
    </>
  );
};
