import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ReactNode } from "react";

interface ISettingBox {
  children: ReactNode;
}

export const SettingBox = (props: ISettingBox) => {
  return (
    <Box
      sx={{
        width: "97%",
        height: "90%",
        overflowY: "scroll",
        overflowX: "hidden",
        display: "flex",
        padding: "10px",
        gap: "16px",
        flexDirection: "column",
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          width: "6px",
          height: "6px",
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          borderRadius: "32px",
          backgroundColor: grey["400"],
        },
        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
          {
            backgroundColor: grey["500"],
          },
        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
          {
            backgroundColor: grey["500"],
          },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
          {
            backgroundColor: grey["500"],
          },
        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
          backgroundColor: "#0000000",
        },
      }}
    >
        {props.children}
    </Box>
  );
};
