import { Box, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

interface IFormPlacementBox {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export const FormPlacementBox = (props: IFormPlacementBox) => {
  return (
    <Box
      sx={{
        ...props.sx,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        background:"#FFFFFF"
      }}
    >
      {props.children}
    </Box>
  );
};

interface IFormContainerBox {
  children: ReactNode;
}
export const FormContainerBox = (props: IFormContainerBox) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "16px",
        border: "1px solid #F1F2F4",
        borderRadius: "8px",
        width: "95%",
      }}
    >
      {props.children}
    </Box>
  );
};
