import { Box } from "@mui/material";
import { ProfileSettingNavigation } from "../../molecule/profileSettingNavigation";
import { ProfileDetailsTemplate } from "./profileDetailsTemplate";

export const ProfileSettingsTemplate = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <ProfileSettingNavigation selectedMenu="ProfileDetails" />
      <Box
        sx={{
          width: "85%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          background: "#ffffff",
          borderRight: "1px solid #CFE6FC",
        }}
      >
        <ProfileDetailsTemplate />
      </Box>
    </Box>
  );
};
