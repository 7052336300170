import { useEffect } from "react";
import { currentActiveScreenState } from "../../../libs/state-management-lib";
import UnitDetailTemplate from "../../template/settings/unitDetailTemplate";

const UnitDetail = () => {
  useEffect(() => {
    currentActiveScreenState.setState("/settings/organization");
  }, []);

  return <UnitDetailTemplate />;
};

export default UnitDetail;
