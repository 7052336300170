import { useEffect } from "react";
import { currentActiveScreenState } from "../../libs/state-management-lib";
import { SignInTemplate } from "../template";

export const SignIn = () => {
  useEffect(() => {
    currentActiveScreenState.setState("/signin");
  }, []);

  return <SignInTemplate />;
};
