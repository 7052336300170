import { Box, Checkbox, MenuItem, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { SignupTextLableTypography } from "../../atom/typography/signupTextLabelTypography";
import { SignUpTextField } from "../../atom/textField/signupTextField";
import { SignupPhoneNumberTextField } from "../../atom/textField/signUpPhoneNumberTextField";
import { SignUpContainedButton } from "../../atom/buttons/signupContainedButton";
import { SignUpDropDown } from "../../atom/dropdown/signUpDropdown";
import { useNavigate } from "react-router-dom";
import { PlatformLoginTextField } from "../../atom";
export const AgencySignUpForms = () => {
  const navigate = useNavigate();
  const [openScreen, setOpenScreen] = useState("signUp");

  const handleCreateAccount = () => {
    setOpenScreen("otpConfirmScreen");
  };

  const handleConfirmOtp = () => {
    setOpenScreen("detailFormScreen");
  };

  const handledetailFormClick = () => {
    setOpenScreen("addressFormScreen");
  };

  const handleAddressFormClick = () => {
    setOpenScreen("signUp");
  };

  const handleSignInClick = () => {
    navigate("/agencySignin");
  };

  return (
    <>
      {openScreen == "signUp" && (
        <>
          <Box
            sx={{
              minWidth: "400px",
              maxWidth: "400px",
              minHeight: "608px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "32px",
            }}
          >
            {/* CA box */}
            <Box
              sx={{
                minWidth: "100%",
                minHeight: "64px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "32px",
                  color: "#000000DE",
                }}
              >
                Create account
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "32px",
                  letterSpacing: "0.15px",
                  inlineHight: "24px",
                  color: "#00000099",
                }}
              >
                Please fill in your Details.
              </Typography>
            </Box>
            {/* form container */}
            <Box
              sx={{
                minWidth: "100%",
                minHeight: "512px",
                gap: "24px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {/* inputs container*/}
              <Box
                sx={{
                  minWidth: "100%",
                  minHeight: "398px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    minWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                  }}
                >
                  <SignupTextLableTypography
                    text="First name"
                    required={true}
                  />
                  <SignUpTextField
                    placeholder={"e.g. jhon"}
                    value={""}
                    handleChange={function (
                      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    minWidth: "100%",
                    minHeight: "66px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                  }}
                >
                  <SignupTextLableTypography text="Last name" required={true} />
                  <SignUpTextField
                    placeholder={"e.g. Doe"}
                    value={""}
                    handleChange={function (
                      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    minWidth: "100%",
                    minHeight: "66px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                  }}
                >
                  <SignupTextLableTypography text="Email" required={true} />
                  <SignUpTextField
                    placeholder={"e.g. jhondoe@gmail.com"}
                    value={""}
                    handleChange={function (
                      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </Box>
                {/* <Box
                  sx={{
                    minWidth: "100%",
                    minHeight: "152px",
                    gap: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      minWidth: "100%",
                      minHeight: "112px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: "100%",
                        minHeight: "66px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "4px",
                      }}
                    >
                      <SignupTextLableTypography
                        text="Phone"
                        required={false}
                      />
                      <PlatformLoginTextField
                        placeholder="+1 (XXX) XXX-XXXX"
                        value=""
                        handleChange={(e: any) => {}}
                      />
                    </Box>
                    <Box
                      sx={{
                        minHeight: "38px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        sx={{
                          color: "#00000099",
                          "&.Mui-checked": {
                            color: "#1F5DA5",
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#000000DE",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Receive OTP by sms
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      minWidth: "100%",
                      minHeight: "40px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        minWidth: "95%",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        //letterSpacing: "0.4px",
                        color: "rgba(0, 0, 0, 0.6)",
                      }}
                    >
                      Please,fill in your phone number if you want receive the
                      one-time password by SMS
                    </Typography>
                  </Box>
                </Box> */}
              </Box>
              {/* button container */}
              <Box
                sx={{
                  minWidth: "100%",
                  minHeight: "90px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "24px",
                }}
              >
                <SignUpContainedButton
                  text="Create account"
                  handleClick={handleCreateAccount}
                />

                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    letterSpacing: "0.15px",
                    inlineHight: "24px",
                    textAlign: "center",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  Already have a Shiftnex account?{" "}
                  <span
                    style={{
                      fontWeight: "500",
                      color: "#1F5DA5",
                      cursor: "pointer",
                    }}
                    onClick={handleSignInClick}
                  >
                    Sign In
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {openScreen == "otpConfirmScreen" && (
        <>
          <Box
            sx={{
              width: "400px",
              minWidth: "400px",
              height: "386px",
              minHeight: "386px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <Box
              sx={{
                minWidth: "80px",
                minHeight: "80px",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <svg
                width="80"
                height="81"
                viewBox="0 0 80 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M52.5 10C47.9688 10 43.9062 12.5 41.5625 16.25C39.375 20.1562 39.375 25 41.5625 28.75C43.9062 32.6562 47.9688 35 52.5 35C56.875 35 60.9375 32.6562 63.2812 28.75C65.4688 25 65.4688 20.1562 63.2812 16.25C60.9375 12.5 56.875 10 52.5 10ZM52.5 45C44.375 45 37.0312 40.7812 32.9688 33.75C28.9062 26.875 28.9062 18.2812 32.9688 11.25C37.0312 4.375 44.375 0 52.5 0C60.4688 0 67.8125 4.375 71.875 11.25C75.9375 18.2812 75.9375 26.875 71.875 33.75C67.8125 40.7812 60.4688 45 52.5 45Z"
                  fill="#1FA7F8"
                />
                <path
                  opacity="0.4"
                  d="M33.4375 34.5312C35.1562 37.3438 37.6562 39.8438 40.4688 41.5625L27.0312 55L33.4375 61.5625C35.4688 63.4375 35.4688 66.7188 33.4375 68.5938C31.5625 70.625 28.2812 70.625 26.4062 68.5938L20 62.1875L17.0312 65L23.4375 71.5625C25.4688 73.4375 25.4688 76.7188 23.4375 78.5938C21.5625 80.625 18.2812 80.625 16.4062 78.5938L6.40625 68.5938C4.375 66.7188 4.375 63.4375 6.40625 61.5625L33.4375 34.5312Z"
                  fill="#1FA7F8"
                />
              </svg>
            </Box>

            <Box
              sx={{
                minWidth: "100%",
                minHeight: "88px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
                // border: "1px solid"
              }}
            >
              <Box
                sx={{
                  minWidth: "400px",
                  minHeight: "32px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "24px",
                    lineHeight: "32px",
                    color: "#000000DE",
                  }}
                >
                  Secure Verification
                </Typography>
              </Box>
              <Box
                sx={{
                  minWidth: "400px",
                  minHeight: "48px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    color: "#00000099",
                    textAlign: "center",
                    alignSelf: "stretch",
                    justifyContent: "center",
                    flex: "none",
                    flexGrow: "0",
                  }}
                >
                  We’ve just sent you a temporary 6-digit code verification.
                  Please check email and/or phone.
                </Typography>
              </Box>
            </Box>
            {/* button box */}
            <Box
              sx={{
                minWidth: "400px",
                minHeight: "170px",
                gap: "32px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <SignUpTextField
                placeholder={"Enter the code"}
                value={""}
                handleChange={function (
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ): void {
                  throw new Error("Function not implemented.");
                }}
              />

              <Box
                sx={{
                  minWidth: "400px",
                  minHeight: "98px",
                  gap: "16px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    minWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SignUpContainedButton
                    text="Confirm"
                    handleClick={handleConfirmOtp}
                    disabled={false}
                  />
                </Box>

                <Box
                  sx={{
                    minWidth: "100%",
                    minHeight: "42px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "8px 11px",
                    gap: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "15px",
                      lineHeight: "26px",
                      letterSpacing: "0.46px",
                      color: "#1F5DA5",
                    }}
                  >
                    Resend the code in
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "15px",
                      lineHeight: "26px",
                      letterSpacing: "0.46px",
                      color: "#00000099",
                    }}
                  >
                    02:00
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {openScreen == "detailFormScreen" && (
        <>
          <Box
            sx={{
              width: "400px",
              minWidth: "400px",
              height: "472px",
              minHeight: "472px",
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "64px",
                minWidth: "100%",
                minHeight: "64px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "32px",
                  color: "#000000DE",
                }}
              >
                Agency
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#00000099",
                }}
              >
                Please fill in your agency details.
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "376px",
                minWidth: "100%",
                minHeight: "376px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "312px",
                  minWidth: "100%",
                  minHeight: "312px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    minWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                  }}
                >
                  <SignupTextLableTypography
                    text="Agency name"
                    required={true}
                  />
                  <SignUpTextField
                    placeholder={"Enter agency name"}
                    value={""}
                    handleChange={function (
                      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    minWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                  }}
                >
                  <SignupTextLableTypography
                    text="Agency type"
                    required={true}
                  />

                  <SignUpDropDown
                    handleChange={function (e: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    value={1}
                    sx={null}
                  >
                    <MenuItem value={1}>Choose agency type</MenuItem>
                  </SignUpDropDown>
                </Box>
                <Box
                  sx={{
                    minWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                  }}
                >
                  <SignupTextLableTypography
                    text="Lines of business"
                    required={true}
                  />

                  <SignUpDropDown
                    handleChange={function (e: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    value={1}
                    sx={null}
                  >
                    <MenuItem value={1}>List the business lines</MenuItem>
                  </SignUpDropDown>
                </Box>
                <Box
                  sx={{
                    minWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                  }}
                >
                  <SignupTextLableTypography
                    text="Talent Job Qualifications"
                    required={false}
                  />

                  <SignUpDropDown
                    handleChange={function (e: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    value={1}
                    sx={null}
                  >
                    <MenuItem value={1}>
                      Choose qualifications that apply
                    </MenuItem>
                  </SignUpDropDown>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "40px",
                  minWidth: "100%",
                  minHeight: "40px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "24px",
                }}
              >
                <SignUpContainedButton
                  text="Next"
                  handleClick={handledetailFormClick}
                  disabled={false}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
      {openScreen == "addressFormScreen" && (
        <>
          <Box
            sx={{
              width: "400px",
              minWidth: "400px",
              height: "472px",
              minHeight: "472px",
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "64px",
                minWidth: "100%",
                minHeight: "64px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "32px",
                  color: "#000000DE",
                }}
              >
                Address
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#00000099",
                }}
              >
                Please provide agency address.
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "376px",
                minWidth: "100%",
                minHeight: "376px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "312px",
                  minWidth: "100%",
                  minHeight: "312px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    minWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                  }}
                >
                  <SignupTextLableTypography
                    text="Street address 1"
                    required={true}
                  />
                  <SignUpTextField
                    placeholder={"Enter street name, number"}
                    value={""}
                    handleChange={function (
                      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    minWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                  }}
                >
                  <SignupTextLableTypography
                    text="Street address 2"
                  />
                  <SignUpTextField
                    placeholder={"Enter street name, number"}
                    value={""}
                    handleChange={function (
                      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    minWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                  }}
                >
                  <SignupTextLableTypography
                    text="City & state"
                    required={true}
                  />

                  <SignUpDropDown
                    handleChange={function (e: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    value={1}
                    sx={null}
                  >
                    <MenuItem value={1}>Choose your city</MenuItem>
                  </SignUpDropDown>
                </Box>
                <Box
                  sx={{
                    minWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                  }}
                >
                  <SignupTextLableTypography text="Zip code" required={true} />
                  <SignUpTextField
                    placeholder={"Enter zip code"}
                    value={""}
                    handleChange={function (
                      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "40px",
                  minWidth: "100%",
                  minHeight: "40px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "24px",
                }}
              >
                <SignUpContainedButton
                  text="Complete profile"
                  handleClick={handleAddressFormClick}
                  disabled={false}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
