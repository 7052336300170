import { Box, Typography } from "@mui/material";
import { StaffingPartnerDashboard } from "./staffingPartnerDashboard";
import {
  ContainedButton,
  EmojiIcon,
  ErrorAlert,
  FilterIcon,
  MarketPlaceIcon,
  OutlinedButton,
  OverFlowBox,
  PlayCircleIcon,
  SearchTextField,
  SettingBox,
  SuccessAlert,
  Typography12_400,
  Typography16_400,
  Typography18_600,
  Typography24_600,
  UpdateAlert,
} from "../../atom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";
import { StaffingPartnerAgencyNameForm } from "./staffingPartnerAgencyNameForm";
import { StaffingPartnerContactForm } from "./staffingPartmentContactForm";
import { StaffingPartnerLocationForm } from "./staffingPartnerLocationForm";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  activeConfirmationPopupState,
  activeStatusAlertState,
  agencyDetailListState,
  agencyMappedDetailListState,
  agencyMappingState,
  errorAlertState,
  errorMessageState,
  inActiveConfirmationPopupState,
  loadingIconState,
  organizationDetailState,
  successAlertState,
  updateAlertState,
} from "../../../libs/state-management-lib";
import {
  createAgencyOrgMapping,
  getAllDepartmentsByLocationId,
  getAllDepartmentsByOrgId,
  getAllLocationsByOrgId,
  getLineOfBusinessList,
  getMappedAgency,
  getTalentJobQualification,
  updateStaffingPartner,
} from "../../../libs/services-lib";
import { AgencyMapping } from "../../../libs/model-lib";
import {
  emptyAgencyDetail,
  emptyAgencyMappingDetail,
  EmptyGuid,
  handleValidationErrorAlert,
} from "../../../libs/common-lib";
import {
  AgencyDetail,
  ContactPersonDetails,
} from "../../../libs/model-lib/agencyDetail";
import { agencyDetailState } from "../../../libs/state-management-lib/agencyState";
import { agencyOnboarding } from "../../../libs/services-lib/agencyService";
import Joi from "joi";
import { useNavigate } from "react-router-dom";
import { AgencyMappingDetailContext } from "../../../libs/context-lib/staffingPartnerContext";

export const StaffingPartnerSetting = () => {
  const [staffMenu, setStaffMenu] = useState<string>("staffSetting");
  const [agencyMappingDetail, setAgencyMappingDetail] = useState<AgencyMapping>(
    emptyAgencyMappingDetail
  );
  const [showLocationForm, setShowLocationForm] = useState<boolean>(false);
  const [showContactForm, setShowContactForm] = useState<boolean>(false);
  const [disableName, setDisableName] = useState<boolean>(false);
  const [agencyDetail, setAgencyDetail] =
    useState<AgencyDetail>(emptyAgencyDetail);
  const [agencyDetailList, setAgencyDetailList] = useState<AgencyDetail[]>([]);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<any[]>([]);

  useLayoutEffect(() => {
    const agencyDetailStateSub = agencyDetailState.subscribe(async (state) => {
      setAgencyDetail(state);
    });

    const agencyMappedDetailListStateSub =
      agencyMappedDetailListState.subscribe(async (state) => {
        setAgencyDetailList(state.agencyMappedDetailList);
      });

    return () => {
      agencyDetailStateSub();
      agencyMappedDetailListStateSub();
    };
  }, []);

  useEffect(() => {
    refreshEvent();
  }, []);

  useEffect(() => {
    if (!!errorMessage && errorMessage.length > 0) {
      let selectedPara = document.querySelectorAll("p.red-text");
      if (!!selectedPara && selectedPara.length > 0 && !!selectedPara[0]) {
        selectedPara[0].closest("div")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [errorMessage]);

  const agencySchema = Joi.object<AgencyDetail>({
    id: Joi.string().allow(""),
    name: Joi.string().allow(""),
    dba: Joi.string().allow(""),
    address1: Joi.string().allow("").max(255).messages({    
      "string.max": `The maximum character limit should be 255`,
    }),
    address2: Joi.string().allow("").max(255).messages({    
      "string.max": `The maximum character limit should be 255`,
    }),
    city: Joi.string().allow("").max(255).messages({    
      "string.max": `The maximum character limit should be 255`,
    }),
    state: Joi.string().allow("").max(255).messages({    
      "string.max": `The maximum character limit should be 255`,
    }),
    postal_code: Joi.string().allow(""),
    type: Joi.number().allow(0),
    registration_date: Joi.string().allow(""),
    website: Joi.string().allow(""),
    agency_phone_number: Joi.string().allow(""),
    employer_identification_number: Joi.string().allow(""),
    line_of_business: Joi.array().empty(Joi.array().length(0)),
    job_qualifications: Joi.array().empty(Joi.array().length(0)),
    tenant_id: Joi.string().allow(""),
    is_active: Joi.boolean(),
    invitation_id: Joi.string().allow(""),
    working_week_start: Joi.number().allow(0),
    logo_url: Joi.string().allow("").allow(null),
    agency_mapping_detail: Joi.object<AgencyMapping>({
      id: Joi.string().allow(""),
      agency_id: Joi.string().allow(""),
      agency_name: Joi.string().allow(""),
      org_id: Joi.string().allow(""),
      location_ids: Joi.array().empty(Joi.array().length(0)),
      department_ids: Joi.array().empty(Joi.array().length(0)),
      is_active: Joi.boolean(),
    }).allow(null),
    contact_person_details: Joi.object<ContactPersonDetails>({
      agency_id: Joi.string().allow(""),
      email: Joi.string().required().max(255).email({ tlds: false }).messages({
        "string.email": `Contact person email address is invalid`,
        "string.empty": `Contact person email cannot be an empty field`,
        "string.max": `The maximum character limit should be 255`,
      }),
      job_title: Joi.string().allow(""),
      primary_contact_full_name: Joi.string().allow(""),
      primary_phone_number: Joi.string().allow(""),
      secondary_number: Joi.string().allow(""),
    }),
  }).options({ abortEarly: false });

  const refreshEvent = () => {
    (async function () {
      loadingIconState.setState(true);
      await Promise.all([
        getMappedAgency(organizationDetailState.getState().id),
        getAllLocationsByOrgId(organizationDetailState.getState().id),
        getAllDepartmentsByOrgId(organizationDetailState.getState().id),
        getLineOfBusinessList(),
        getTalentJobQualification(),
      ]);
      loadingIconState.setState(false);
    })();
  };

  const handleMapStaffingPartner = () => {
    setStaffMenu("staffMappingForm");
  };

  const handleDashboardNavigate = () => {
    setStaffMenu("staffSetting");
    setShowLocationForm(false);
    setShowContactForm(false);
    setDisableName(false);
    setAgencyMappingDetail(emptyAgencyMappingDetail);
    refreshEvent();
  };

  const handleContinueClick = () => {
    setErrorMessage([]);
    if (!!agencyDetailList && agencyDetailList.length > 0) {
      let existsAgency = agencyDetailList.find(
        (cc: AgencyDetail) =>
          cc.name.toLocaleLowerCase() ==
          agencyMappingDetail.agency_name.toLocaleLowerCase()
      );

      if (!!existsAgency) {
        handleValidationErrorAlert("Agency already Mapped");
        return;
      }
    }

    setDisableName(true);

    if (!showContactForm && agencyMappingDetail.agency_id == EmptyGuid) {
      setShowContactForm(true);
      setShowLocationForm(true);
      return;
    }

    setShowContactForm(false);
    setShowLocationForm(true);
  };

  const handleSendInviteClick = async () => {
    agencyMappingDetail.org_id = organizationDetailState.getState().id;
    agencyDetail.name = agencyMappingDetail.agency_name;
    if (agencyMappingDetail.agency_id == EmptyGuid) {
      const result = agencySchema.validate(agencyDetail);
      let errorMessage: any[] = [];

      if (!!result.error && !!result.error.details) {
        result.error.details.forEach((cc) => {
          let field = cc.path[0];
          if (cc.path.length > 1) {
            field = cc.path.join("$");
          }
          errorMessage.push({ field: field, errorMessage: cc.message });
        });

        setErrorMessage(errorMessage);
        return;
      }
      setErrorMessage([]);
    }

    loadingIconState.setState(true);
    if (agencyMappingDetail.agency_id == EmptyGuid) {
      let agencyDetail = agencyDetailState.getState();
      agencyDetail.working_week_start = 1;
      try {
        let agencyInvitedDetail = await agencyOnboarding(agencyDetail);
        agencyMappingDetail.agency_id = agencyInvitedDetail.id;
      } catch (error) {}
      loadingIconState.setState(false);
    }
    if (agencyMappingDetail.agency_id != EmptyGuid) {
      try {
        await createAgencyOrgMapping(agencyMappingDetail);
        setStaffMenu("staffSetting");
        setShowLocationForm(false);
        setShowContactForm(false);
        setDisableName(false);
        refreshEvent();
        setAgencyMappingDetail(emptyAgencyMappingDetail);
      } catch (error) {}
    }
  };

  const handleGoToMarketplaceClick = () => {
    navigate("/marketplace");
  };

  const handleActiveStaffingPartner = async (
    activeStaffingPartnerId: string
  ) => {
    let staffingPartnerDetail = {
      id: activeStaffingPartnerId,
      is_active: false,
    };
    loadingIconState.setState(true);
    try {
      await updateStaffingPartner(staffingPartnerDetail);
      refreshEvent();
    } catch (error) {}
    loadingIconState.setState(false);
    activeConfirmationPopupState.setState(false);
  };

  const handleInActiveStaffingPartner = async (
    inActiveStaffingPartnerId: string
  ) => {
    let staffingPartnerDetail = {
      id: inActiveStaffingPartnerId,
      is_active: true,
    };
    loadingIconState.setState(true);
    try {
      await updateStaffingPartner(staffingPartnerDetail);
      refreshEvent();
    } catch (error) {}
    loadingIconState.setState(false);
    inActiveConfirmationPopupState.setState(false);
  };

  return (
    <>
      {/* Header Play Video Container */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            width: "42%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "16px 24px 16px 24px",
            gap: "10px",
            borderRadius: "8px",
            backgroundColor: "#FEF8F5",
            marginTop: "20px",
            marginLeft: "20px",          
          }}
        >
          <PlayCircleIcon
            sx={{
              color: "#F7906E",
            }}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                alignItems: "center",
                color: "#F7906E",
              }}
            >
              Setting up staffing partners
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "12px",
                alignItems: "center",
                color: "#F7906E",
                lineHeight: "29px",
                paddingLeft: "5px",
              }}
            >
              1 min.
            </Typography>
          </Box>
          <CloseOutlinedIcon
            sx={{
              color: "#F7906E",
              top: "5.2px",
              left: "5.2px",
              alignItems: "flex-end",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {staffMenu == "staffSetting" && (
          <>
            {/* header container */}
            <Box
              sx={{
                width: "calc(100% - 20px)",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottom: "1px solid #CFE5FC",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  width: "20%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                <Typography18_600 text="Staffing partners" color="#17191C" />
              </Box>
              <Box
                sx={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <SearchTextField
                  placeholder={"Search"}
                  value={""}
                  handleChange={(e: any) => {}}
                />
                <FilterIcon
                  sx={{
                    // color: "#0D80F2",
                    // cursor: "pointer",
                    marginTop: "1%",
                    paddingLeft: "2%",
                    color:"#ABB2BA"
                  }}
                />
                <Box
                  sx={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    padding: "8px 16px",
                    gap: "8px",
                    borderRadius: "8px",
                    display: "flex",
                  }}
                >
                  <OutlinedButton
                    text="Go to marketplace"
                    startIcon={<MarketPlaceIcon sx={null} />}
                    sx={{ height: "40px" }}
                    handleClick={handleGoToMarketplaceClick}
                  />
                  <ContainedButton
                    text="Invite staffing partner"
                    startIcon={
                      <AddIcon
                        sx={{ color: "#FFFFFF", width: "24px", height: "24px" }}
                      />
                    }
                    sx={{ height: "40px" }}
                    handleClick={handleMapStaffingPartner}
                  />
                </Box>
              </Box>
            </Box>

            {/* body container */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "24px 18px 34px 10px",
                gap: "10px",
                height: "calc(100% - 56px)",
                width: "calc(100% - 36px)",
              }}
            >
              {agencyDetailList.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "10px",
                    gap: "10px",
                    width: "calc(100% - 20px)",
                    height: "calc(100% - 20px)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        gap: "6px",
                        zIndex: 1,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <StaffingPartnerDashboard
                          handleActiveStaffingPartner={
                            handleActiveStaffingPartner
                          }
                          handleInActiveStaffingPartner={
                            handleInActiveStaffingPartner
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}

              {agencyDetailList.length <= 0 && (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "24px",
                  }}
                >
                  <EmojiIcon sx={{ width: "80px", height: "80px" }} />
                  <Box
                    sx={{
                      width: "198px",
                      height: "95px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                      alignItems: "center",
                      justifyContent: "center",
                      "& p": {
                        color: "#252526 !important",
                      },
                    }}
                  >
                    <Typography24_600 text={"No partners yet"} />
                    <Typography16_400
                      sx={{
                        textAlign: "center",
                      }}
                      text={"Invite a staffing partner to work with you"}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </>
        )}

        {/* Form screens */}
        {staffMenu == "staffMappingForm" && (
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottom: "1px solid #CFE5FC",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box sx={{ padding: "8px 16px" }}>
                  <Typography18_600
                    text="Invite staffing partners"
                    color="#17191C"
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "24px 18px 34px 18px",
                gap: "10px",
                height: "calc(100% - 56px)",
                width: "calc(100% - 36px)",
              }}
            >
              <Box
                sx={{
                  width: "calc(100% - 20px)",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  height: "calc(100% - 20px)",
                  padding: "10px",
                }}
              >
                <AgencyMappingDetailContext.Provider
                  value={{ agencyMappingDetail, setAgencyMappingDetail }}
                >
                  <OverFlowBox
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      gap: "5px",
                      height: "100%",
                      paddingBottom: "60px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "calc(99% - 32px)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        border: "1px solid #F1F2F4",
                        borderRadius: "8px",
                        padding: "16px",
                        gap: "16px",
                      }}
                    >
                      <StaffingPartnerAgencyNameForm
                        disableName={disableName}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          gap: "16px",
                        }}
                      >
                        {showContactForm && (
                          <StaffingPartnerContactForm
                            errorMessage={errorMessage}
                          />
                        )}
                      </Box>
                    </Box>
                    {showLocationForm && <StaffingPartnerLocationForm />}
                  </OverFlowBox>
                </AgencyMappingDetailContext.Provider>
              </Box>
            </Box>
          </>
        )}
      </Box>
      {staffMenu == "staffMappingForm" && (
        <Box
          sx={{
            width: "100%",
            height: "10%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            borderTop: "1px solid #CFE5FC",
            gap: "16px",
          }}
        >
          <OutlinedButton
            text={"Cancel"}
            sx={{ height: "60%", padding: "8px 16px" }}
            handleClick={handleDashboardNavigate}
          />

          {!showLocationForm && (
            <ContainedButton
              text={"Continue"}
              sx={{ height: "60%", padding: "8px 16px" }}
              disabled={
                !!!agencyMappingDetail || agencyMappingDetail.agency_name == ""
                  ? true
                  : false
              }
              handleClick={handleContinueClick}
            />
          )}

          {showLocationForm && (
            <ContainedButton
              text={"Send invite"}
              sx={{ height: "60%", padding: "8px 16px" }}
              handleClick={handleSendInviteClick}
            />
          )}
        </Box>
      )}
    </>
  );
};
