import { create } from "zustand";
import {
  CustomTag,
  ShareOpenShifts,
  ShareShift,
  Shift,
  ShiftRotation,
  ShiftSeries,
} from "../model-lib";
import { devtools, persist } from "zustand/middleware";
import {
  emptyShareOpenShift,
  emptyShareShift,
  emptyShift,
  emptyShiftRotation,
  emptyShiftSeries,
} from "../common-lib";

export const shiftDetailState = create<Shift>()(
  devtools(persist((set, get) => emptyShift, { name: "ShiftDetailState" }))
);

interface IShiftListState {
  shiftList: Shift[];
}

export const shiftListState = create<IShiftListState>()(
  devtools(
    persist((set, get) => ({ shiftList: [] }), {
      name: "shiftListState",
    })
  )
);

export const shiftSeriesState = create<ShiftSeries>()(
  devtools(
    persist((set, get) => emptyShiftSeries, { name: "shiftSeriesState" })
  )
);

export const shareShiftState = create<ShareShift>()(
  devtools(persist((set, get) => emptyShareShift, { name: "shareShiftState" }))
);

interface IOpenShiftListState {
  openShiftList: Shift[];
}

export const openShiftListState = create<IOpenShiftListState>()(
  devtools(
    persist((set, get) => ({ openShiftList: [] }), {
      name: "openShiftListState",
    })
  )
);

export const shareOpenShiftState = create<ShareOpenShifts>()(
  devtools(
    persist((set, get) => emptyShareOpenShift, { name: "shareOpenShiftState" })
  )
);

export const shiftRotationState = create<ShiftRotation>()(
  devtools(
    persist((set, get) => emptyShiftRotation, { name: "shiftRotationState" })
  )
);


interface ICustomTagListState {
  customTagList: CustomTag[];
}

export const customTagListState = create<ICustomTagListState>()(
  devtools(
    persist((set, get) => ({ customTagList: [] }), {
      name: "customTagListState",
    })
  )
);