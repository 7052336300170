import { SxProps, Theme, Typography } from "@mui/material";

interface ITextFieldLableTypography {
    text: string;
    required?: boolean;
    sx?: SxProps<Theme>;
}

export const TextFieldLableTypography = (props: ITextFieldLableTypography) => {
  return (
    <Typography
      sx={{
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "20.4px",
        color: "#737F8C",
        ...props.sx,
      }}
    >
      {props.text} {!!props.required && (<span style={{color: "red"}}>*</span>)}
    </Typography>
  );
}