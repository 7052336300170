import { createContext, useContext } from "react";
import { DepartmentDetail } from "../model-lib";

interface IDepartmentDetailContext {
  departmentDetail: DepartmentDetail;
  setDepartmentDetail: React.Dispatch<React.SetStateAction<DepartmentDetail>>;
}

export const DepartmentDetailContext = createContext<
  IDepartmentDetailContext | undefined
>(undefined);

export const useDepartmentDetailContext = (): IDepartmentDetailContext => {
  const context = useContext(DepartmentDetailContext);
  if (context === undefined) {
    throw new Error("useDepartmentDetailContext must be used within a MyProvider");
  }
  return context;
};
