import { useEffect } from "react";
import { currentActiveScreenState } from "../../../libs/state-management-lib";
import RoleDetailTemplate from "../../template/settings/roleDetailTemplate";

const RoleDetail = () => {
  useEffect(() => {
    currentActiveScreenState.setState("/settings/organization");
  }, []);
  
  return <RoleDetailTemplate />;
};

export default RoleDetail;
