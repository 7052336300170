import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { SignInDetail } from "../model-lib/master";
import { UserDetail } from "../model-lib";

export const SignInState = create<SignInDetail>()(
  devtools(
    persist(
      (set, get) => ({ userName: "", password: "", mobileNumber: "", otp: "" }),
      { name: "SignInState" }
    )
  )
);

export const accessTokenState = create<any>()(
  devtools(persist((set, get) => ({ token: "" }), { name: "accessTokenState" }))
);

export const userDetailstate = create<UserDetail>()(
  devtools(
    persist(
      (set, get) => ({
        id: "",
        first_Name: "",
        last_Name: "",
        email: "",
        mobile_Number: "",
        role_Id: "",
        tenant_Id: null,
        is_Active: true,
        invitation_id: "",
        tenant_type: 0,
      }),
      { name: "userDetailstate" }
    )
  )
);
