import { Box } from "@mui/material";
import { OverFlowBox } from "../../atom";
import { SettingNavigation } from "../../molecule";
import { LocationSetting } from "../../organism";
import { StaffingPartnerSetting } from "../../organism/staffingPartner/staffingPartnerSetting";

const StaffingPartnerTemplate = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <SettingNavigation selectedMenu="StaffingPartner" />
      <OverFlowBox
        sx={{
          width: "80%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          background: "#ffffff",
          borderRight: "1px solid #CFE6FC",
        }}
      >
        <StaffingPartnerSetting />
      </OverFlowBox>
    </Box>
  );
};

export default StaffingPartnerTemplate;
