import { Chip } from "@mui/material";

const chipStyle = {
  height: "16px",
  borderRadius: "4px",
  fontSize: "10px",
  fontWeight: "600",
};

export const OpenShiftChip = () => {
  return (
    <Chip
      sx={{
        ...chipStyle,
        backgroundColor: "#F5FAFE",
        border: "border: 1px solid #E7F2FE",
        color: "#3D99F5",
      }}
      label="Open"
    />
  );
};

export const InProgressShiftChip = () => {
  return (
    <Chip
      sx={{
        ...chipStyle,
        backgroundColor: "#F7FDF8",
        border: "border: 1px solid #EBFAEE",
        color: "#29A347",
      }}
      label="InProgress"
    />
  );
};

export const CompletedShiftChip = () => {
  return (
    <Chip
      sx={{
        ...chipStyle,
        backgroundColor: "#F9FAFA",
        border: "border: 1px solid #F1F2F4",
        color: "#8F98A3",
      }}
      label="Completed"
    />
  );
};

export const AssignedShiftChip = () => {
  return (
    <Chip
      sx={{
        ...chipStyle,
        backgroundColor: "#F7FDF8",
        border: "border: 1px solid #EBFAEE",
        color: "#29A347",
      }}
      label="Assigned"
    />
  );
};

export const CancelledShiftChip = () => {
  return (
    <Chip
      sx={{
        ...chipStyle,
        backgroundColor: "#FEF8F5",
        border: "border: 1px solid #FEEDE7",
        color: "#F56B3D",
      }}
      label="Cancelled"
    />
  );
};

export const PendingShiftChip = () => {
  return (
    <Chip
      sx={{
        ...chipStyle,
        backgroundColor: "#F5FAFE",
        border: "border: 1px solid #E7F2FE",
        color: "#3D99F5",
      }}
      label="Pending"
    />
  );
};
