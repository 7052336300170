import { useEffect } from "react";
import { currentActiveScreenState } from "../../../libs/state-management-lib";
import RoleTemplate from "../../template/settings/roleTemplate";

const Roles = () => {
  useEffect(() => {
    currentActiveScreenState.setState("/settings/organization");
  }, []);

  return <RoleTemplate />;
};

export default Roles;
