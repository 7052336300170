import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  CalendarTodayOutlinedIcon,
  CloseIcon,
  ContainedButton,
  LableTextFieldBox,
  MultiSelectDropdown,
  OutlinedButton,
  OverFlowBox,
} from "../../atom";
import { TextFieldLableTypography } from "../../atom/typography/textFieldLableTypography";
import { CalendarDropDown } from "../../atom/dropdown/calendarDropdown";
import { useEffect, useLayoutEffect, useState } from "react";
import { Dayjs } from "dayjs";
import { ShareShiftCheckBox } from "../../atom/checkbox/shareShiftCheckbox";
import { CalendarShareShiftOpenShiftsContainer } from "./calendarShareShiftOpenShiftsContainer";
import { CalendarShareshiftAgencyBox } from "../../atom/box/calendarBoxes/calendarShareShiftAgencyBox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import {
  OrgRole,
  ShareOpenShifts,
  ShareShift,
  Shift,
} from "../../../libs/model-lib";
import {
  agencyMappedDetailListState,
  loadingIconState,
  openShiftListState,
  organizationDetailState,
  orgRoleListState,
  shareShiftState,
} from "../../../libs/state-management-lib";
import {
  EmptyGuid,
  emptyShareOpenShift,
  emptyShareShift,
  getDateRange,
} from "../../../libs/common-lib";
import { AgencyDetail } from "../../../libs/model-lib/agencyDetail";
import { format, setHours, setMinutes } from "date-fns";
import {
  createShareShiftLogs,
  getAllShifts,
  getOpenShifts,
} from "../../../libs/services-lib";
import {
  ShareOpenShiftsContext,
  useSearchShiftContext,
  useShareShiftDetailContext,
} from "../../../libs/context-lib/shiftDetailContext";
import { CalendarShareshiftDateBox } from "./calendarShareShiftDateBox";
import { CheckBox } from "@mui/icons-material";
import { FormCheckbox } from "../../atom/checkbox/formCheckbox";

interface ICalendarShareShiftDrawer {
  locationId: string;
  departmentId: string[];
  handleCloseClick: () => void;
}

interface IDateRangeSelection {
  startDate: Date | undefined;
  endDate: Date | undefined;
  key: string;
}

export const CalendarShareShiftDrawer = (props: ICalendarShareShiftDrawer) => {
  const [shiftDate, setShiftDate] = useState<Dayjs | null>(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [orgRoleList, setOrgRoleList] = useState<OrgRole[]>(
    orgRoleListState.getState().orgRoleList
  );
  const [selectedQualification, setSelectedQualification] =
    useState<string>(EmptyGuid);
  const [agencyDetailList, setAgencyDetailList] = useState<AgencyDetail[]>([]);

  const [date, setDate] = useState<IDateRangeSelection>({
    startDate: new Date(),
    endDate: new Date(new Date().setDate(new Date().getDate() + 6)),
    key: "selection",
  });
  const [shareShift, setShareShift] = useState<ShareShift>(emptyShareShift);
  const [shareOpenShifts, setShareOpenShifts] =
    useState<ShareOpenShifts>(emptyShareOpenShift);

  const [selectedLocationId, setSelectedLocationid] = useState(
    shareShiftState.getState().location_id
  );
  const [selectedDepartment, setSelectedDepartment] = useState<any[]>(
    shareShiftState.getState().department_ids
  );
  const [openShiftList, setOpenShiftList] = useState<Shift[]>([]);
  const [shareDateRange, setShareDateRange] = useState<Date[]>();
  const { searchShift, setAllShifts } = useSearchShiftContext();
  const [selectAllAgency, setSelectAllAgency] = useState<boolean>(false);
  const [selectAllShift, setSelectAllShift] = useState<boolean>(false);

  useLayoutEffect(() => {
    const orgRoleListStateSub = orgRoleListState.subscribe(async (state) => {
      setOrgRoleList(state.orgRoleList);
    });

    const agencyMappedDetailListStateSub =
      agencyMappedDetailListState.subscribe(async (state) => {
        setAgencyDetailList(state.agencyMappedDetailList);
      });

    const shiftListStateSub = openShiftListState.subscribe(async (state) => {
      if (!!state && !!state.openShiftList && state.openShiftList.length > 0) {
        setOpenShiftList(state.openShiftList);
      } else {
        setOpenShiftList([]);
      }
    });

    return () => {
      orgRoleListStateSub();
      agencyMappedDetailListStateSub();
      shiftListStateSub();
    };
  }, []);

  useEffect(() => {
    (async function () {
      if (
        (!!shareDateRange && shareDateRange.length > 0) ||
        shareShift.role_ids.length > 0
      ) {
        loadingIconState.setState(true);
        let shareShiftRequest = { ...shareShift };
        shareShiftRequest.location_id = props.locationId;
        shareShiftRequest.department_ids = props.departmentId;
        await getOpenShifts(shareShiftRequest);
        loadingIconState.setState(false);
      } else {
        setOpenShiftList([]);
      }
    })();
  }, [shareShift]);

  useEffect(() => {
    if (
      !!shareOpenShifts &&
      !!shareOpenShifts.agency_ids &&
      !!agencyDetailList &&
      shareOpenShifts.agency_ids.length > 0 &&
      shareOpenShifts.agency_ids.length == agencyDetailList.length
    ) {
      setSelectAllAgency(true);
    } else {
      setSelectAllAgency(false);
    }

    if (
      !!shareOpenShifts &&
      !!shareOpenShifts.shift_ids &&
      !!openShiftList &&
      shareOpenShifts.shift_ids.length > 0 &&
      shareOpenShifts.shift_ids.length == openShiftList.length
    ) {
      setSelectAllShift(true);
    } else {
      setSelectAllShift(false);
    }
  }, [shareOpenShifts]);

  const handleDateChange = (newValue: any) => {
    if (!!newValue && newValue.length > 1 && !!newValue[1]) {
      setShareShift({
        ...shareShift,
        from_date: format(
          getCurrentTimeDate(newValue[0]?.toDate()),
          "MM-dd-yyyy HH:mm:ss"
        ),
        to_date: format(newValue[1]?.toDate(), "MM-dd-yyyy"),
        location_id: selectedLocationId,
        department_ids: [selectedDepartment],
      });
      let dateValues = getDateRange(
        newValue[0]?.toDate(),
        newValue[1]?.toDate(),
        1
      );
      setShareDateRange(dateValues);
    }
  };

  const getCurrentTimeDate = (selectedDate: any) => {
    const currentTime = new Date();
    const dateWithCurrentTime = setHours(
      setMinutes(selectedDate, currentTime.getMinutes()),
      currentTime.getHours()
    );
    return new Date(dateWithCurrentTime.getTime());
  };

  const handleQualificationChange = async (e: any) => {
    setSelectedQualification(e.target.value);
    setShareShift({ ...shareShift, role_ids: e.target.value });
  };

  const handleRoleTypeRemove = (e: any) => {
    let roleList = shareShift.role_ids.filter((cc: any) => cc != e);
    shareShiftState.setState({ role_ids: roleList });
    setShareShift({ ...shareShift, role_ids: roleList });
  };

  const handleclick = () => {
    setOpenDatePicker(!openDatePicker);
  };

  const getOpenShift = (date: Date) => {
    let selectedDate = new Date(date);
    let openShiftDate = format(selectedDate, "yyyy-MM-dd");
    let shiftArray: any[] = [];
    if (!!openShiftList && openShiftList.length > 0) {
      shiftArray = shiftArray.concat(
        openShiftListState.getState().openShiftList.filter((ee) => {
          let startDate = new Date(ee.start_date);
          startDate.setHours(0, 0, 0, 0);
          return (
            format(ee.start_date, "yyyy-MM-dd") == openShiftDate &&
            ee.shift_status == 2
          );
        })
      );
    }
    return shiftArray;
  };

  const getOpenShiftCount = (date: Date) => {
    let selectedDate = new Date(date);
    let openShiftDate = format(selectedDate, "yyyy-MM-dd");
    let shiftArray: any[] = [];
    if (!!openShiftList && openShiftList.length > 0) {
      shiftArray = shiftArray.concat(
        openShiftListState.getState().openShiftList.filter((ee) => {
          let startDate = new Date(ee.start_date);
          startDate.setHours(0, 0, 0, 0);
          return (
            format(ee.start_date, "yyyy-MM-dd") == openShiftDate &&
            ee.shift_status == 2
          );
        })
      );
    }
    return shiftArray.length;
  };

  const handleShareShiftClick = async () => {
    loadingIconState.setState(true);
   
      let shareShiftDetail = { ...shareOpenShifts };
      shareShiftDetail.org_id = organizationDetailState.getState().id;
      shareShiftDetail.location_id = props.locationId;
      let response = await createShareShiftLogs(shareShiftDetail);
      let aShifts = await getAllShifts(searchShift);
      setAllShifts(aShifts);   
      if(!!!response){
        loadingIconState.setState(false); 
        return;        
      }
      props.handleCloseClick();
      loadingIconState.setState(false);     
  };

  const handleSelectAllShift = (event: any) => {
    if (event.target.checked) {
      const openShiftIds = openShiftList.map((item) => item.id);
      setShareOpenShifts({
        ...shareOpenShifts,
        shift_ids: openShiftIds,
      });
    } else {
      setShareOpenShifts({
        ...shareOpenShifts,
        shift_ids: [],
      });
    }
  };

  const handleSelectAgency = (event: any) => {
    if (event.target.checked) {
      const agencyIds = agencyDetailList.map((item) => item.id);
      setShareOpenShifts({
        ...shareOpenShifts,
        agency_ids: agencyIds,
      });
    } else {
      setShareOpenShifts({
        ...shareOpenShifts,
        agency_ids: [],
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "99%",
          height: "99%",
          border: "1px solid #E3E5E8",
          background: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          boxShadow: "-2px 0px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* header container */}
          <Box
            sx={{
              width: "calc(100% - 32px)",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "16px",
              borderBottom: "1px solid #E3E5E8",
            }}
          >
            <Box
              sx={{
                width: "92%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyItem: "center",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "24.3px",
                  color: "#17191C",
                }}
              >
                Share open shifts
              </Typography>

              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#737F8C",
                }}
              >
                {shareOpenShifts.shift_ids.length} selected
              </Typography>
            </Box>
            <Box
              sx={{
                width: "8%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={props.handleCloseClick}
            >
              <CloseIcon
                sx={{ width: "24px", height: "24px", color: "#0D80F2" }}
              />
            </Box>
          </Box>
          {/* body container */}

          <Box
            sx={{
              width: "calc(100%-48px)",
              height: "82%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "16px 24px 16px 24px",
              gap: "24px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "24px",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  color: "#737F8C",
                  justifyContent: "center",
                }}
              >
                <LableTextFieldBox>
                  <TextFieldLableTypography
                    text="Dates"
                    required={false}
                    sx={{
                      fontWeight: "600",
                      color: "#737F8C",
                      marginBottom: "-7px",
                    }}
                  />
                </LableTextFieldBox>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["SingleInputDateRangeField"]}>
                    <DateRangePicker
                      sx={{
                        justifyContent: "center",
                        background: "#F5FAFE",
                        width: "100%",
                        "& .MuiSvgIcon-root": {
                          color: "#0D80F2 !important",
                        },
                        ".MuiInputBase-input": {
                          padding: "8.5px 14px !important",
                          fontSize: "16px",
                          fontWeight: "400",
                        },
                        "&:hover .MuiInputBase": {
                          border: "1px solid #0D80F2 !important",
                          borderRadius: "8px",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #0D80F2 !important",
                          borderRadius: "8px",
                        },
                        ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "2px solid #0D80F2 !important",
                          borderRadius: "8px",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #CFE5FC",
                          borderRadius: "8px",
                        },
                        "& .MuiInputLabel-root": { display: "none" },
                        Input: {
                          "&::placeholder": {
                            color: "#6EB2F7",
                            opacity: "5",
                          },
                        },
                      }}
                      slotProps={{
                        textField: {
                          inputProps: {
                            readOnly: true,
                          },
                          InputProps: {
                            endAdornment: (
                              <CalendarTodayOutlinedIcon
                                sx={{ cursor: "pointer" }}
                              />
                            ),
                          },
                          placeholder: "All dates",
                        },
                      }}
                      slots={{ field: SingleInputDateRangeField }}
                      name="allowedRange"
                      format="MMM DD, YYYY"
                      disablePast={true}
                      onChange={(newValue: any) => {
                        handleDateChange(newValue);
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <LableTextFieldBox>
                  <TextFieldLableTypography
                    text="Qualifications"
                    required={false}
                    sx={{
                      fontWeight: "600",
                      color: "#737F8C",
                    }}
                  />
                </LableTextFieldBox>
                <MultiSelectDropdown
                  handleChange={handleQualificationChange}
                  value={shareShift.role_ids}
                  label={""}
                  listItems={orgRoleList}
                  handleRemove={handleRoleTypeRemove}
                  labelValue="abbreviation"
                  placeholder="All qualifications"
                >
                  <MenuItem value={EmptyGuid} disabled>
                    All qualifications
                  </MenuItem>
                  {orgRoleList.map((cc: OrgRole) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      {cc.abbreviation}
                    </MenuItem>
                  ))}
                </MultiSelectDropdown>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "45%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "8px",
                border: "1px solid #E3E5E8",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "14%",
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: "8px 8px 0px 0px",
                  background: "#F9FAFA",
                  borderBottom: "1px solid #E3E5E8",
                }}
              >
                <Box
                  sx={{
                    width: "33%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <ShareShiftCheckBox
                    checked={selectAllShift}
                    onChange={handleSelectAllShift}
                  />

                  <Typography
                    sx={{
                      fontSize: "14px !important",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.17px",
                      color: "#00000099",
                    }}
                  >
                    Shift Type
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "33%",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px !important",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.17px",
                      color: "#00000099",
                    }}
                  >
                    Range
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "33%",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px !important",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.17px",
                      color: "#00000099",
                    }}
                  >
                    Qualification
                  </Typography>
                </Box>
              </Box>

              <ShareOpenShiftsContext.Provider
                value={{ shareOpenShifts, setShareOpenShifts }}
              >
                <OverFlowBox sx={null}>
                  {!!shareDateRange &&
                    shareDateRange.length > 0 &&
                    !!openShiftList &&
                    openShiftList.length > 0 &&
                    shareDateRange.map((cc: Date, index: number) => (
                      <>
                        {getOpenShiftCount(cc) > 0 && (
                          <>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "flex-start",
                                padding: "9px 8px",
                                background: "#F5FAFE",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  lineHeight: "22px",
                                  color: "#737F8C",
                                }}
                              >
                                {format(cc, "MMMM d, yyyy")}
                              </Typography>
                            </Box>
                            {getOpenShift(cc).map(
                              (ff: Shift, index2: number) => (
                                <>
                                  <CalendarShareShiftOpenShiftsContainer
                                    key={index2}
                                    openShiftDetail={ff}
                                  />
                                </>
                              )
                            )}
                          </>
                        )}
                      </>
                    ))}
                </OverFlowBox>
              </ShareOpenShiftsContext.Provider>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "40%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "8px",
                border: "1px solid #E3E5E8",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "14%",
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: "8px 8px 0px 0px",
                  background: "#F9FAFA",
                  borderBottom: "1px solid #E3E5E8",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <ShareShiftCheckBox
                    checked={selectAllAgency}
                    onChange={handleSelectAgency}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px !important",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.17px",
                      color: "#00000099",
                    }}
                  >
                    Agency
                  </Typography>
                </Box>
              </Box>
              <ShareOpenShiftsContext.Provider
                value={{ shareOpenShifts, setShareOpenShifts }}
              >
                <OverFlowBox sx={{}}>
                  {agencyDetailList.map((agencyDetail: AgencyDetail) => (
                    <CalendarShareshiftAgencyBox
                      key={agencyDetail.id}
                      agencyDetail={agencyDetail}
                      src={agencyDetail.logo_url}
                    />
                  ))}
                </OverFlowBox>
              </ShareOpenShiftsContext.Provider>
            </Box>
          </Box>

          {/* Button Container */}
          <Box
            sx={{
              width: "calc(100% - 32px)",
              display: "flex",
              flexDirection: "row",
              borderTop: "1px solid #E3E5E8",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "16px",
              gap: "16px",
            }}
          >
            <OutlinedButton
              text={"Cancel"}
              handleClick={props.handleCloseClick}
              sx={{ height: "40px", padding: "8px 16px" }}
            />

            <ContainedButton
              disabled={
                shareOpenShifts.shift_ids.length &&
                shareOpenShifts.agency_ids.length
                  ? false
                  : true
              }
              text={"Share"}
              handleClick={handleShareShiftClick}
              sx={{ height: "40px", padding: "8px 16px" }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
