import { useState, useLayoutEffect } from "react";
import { Box } from "@mui/material";
import { CalendarCostBox } from "../../atom/box/calendarBoxes/calendarCostsBox";
import { CalendarDepartNameBox } from "../../atom/box/calendarBoxes/calendarDepartmentNameBox";
import { LocationDepartmentDetail } from "../../../libs/model-lib";
import { useSearchShiftContext } from "../../../libs/context-lib/shiftDetailContext";

interface ICalendarDepartmentContainer {
  locationDepartDetail: LocationDepartmentDetail;
  handleCollapseClick: (expandItem: boolean) => void;
  handleAddShiftOpen: (shiftOpen: boolean, locationDepartmentDetail: LocationDepartmentDetail, shiftDate: Date) => void;
}

export const CalendarDepartmentContainer = (props: ICalendarDepartmentContainer) => {
  const {dateRange, setDateRange} = useSearchShiftContext();

  const handleAddShiftClick = (shiftDate: Date) => {
    props.handleAddShiftOpen(true, props.locationDepartDetail, shiftDate);
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        position: "sticky",
        top: "0",
        zIndex: "1000",
      }}
    >
      <CalendarDepartNameBox
        locationDepartDetail={props.locationDepartDetail}
        handleCollapseClick={props.handleCollapseClick}
      />
      
      <Box
        sx={{
          width: "84%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {!!dateRange &&
          dateRange.length > 0 &&
          dateRange.map((cc: Date, index: number) => (
            <CalendarCostBox
              key={cc.getDate() + "-" + cc.getMonth() + "-" + "cost"}
              handleAddShiftClick={() => {
                handleAddShiftClick(cc);
              }}
            />
          ))}
      </Box>
    </Box>
  );
};
