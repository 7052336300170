import { createContext, useContext } from "react";
import { AgencyMapping, DepartmentDetail } from "../model-lib";

interface IAgencyMappingDetailContext {
  agencyMappingDetail: AgencyMapping;
  setAgencyMappingDetail: React.Dispatch<React.SetStateAction<AgencyMapping>>;
}

export const AgencyMappingDetailContext = createContext<
  IAgencyMappingDetailContext | undefined
>(undefined);

export const useAgencyMappingDetailContext =
  (): IAgencyMappingDetailContext => {
    const context = useContext(AgencyMappingDetailContext);
    if (context === undefined) {
      throw new Error(
        "useAgencyMappingDetailContext must be used within a MyProvider"
      );
    }
    return context;
  };
