import { devtools, persist } from "zustand/middleware";
import { AgencyMapping } from "../model-lib";
import { create } from "zustand";
import { emptyAgencyMappingDetail } from "../common-lib";
import { AgencyDetail } from "../model-lib/agencyDetail";

interface IAgencyDetailListState {
  agencyDetailList: AgencyDetail[];
}

export const agencyDetailListState = create<IAgencyDetailListState>()(
  devtools(
    persist((set, get) => ({ agencyDetailList: [] }), {
      name: "agencyDetailListState",
    })
  )
);

export const agencyMappingState = create<AgencyMapping>()(
  devtools(
    persist(
      (set, get) => (emptyAgencyMappingDetail),
      { name: "agencyMappingState" }
    )
  )
);



interface IAgencyMappedDetailListState {
  agencyMappedDetailList: AgencyDetail[];
}

export const agencyMappedDetailListState = create<IAgencyMappedDetailListState>()(
  devtools(
    persist((set, get) => ({ agencyMappedDetailList: [] }), {
      name: "agencyMappedDetailListState",
    })
  )
);