import { Box } from "@mui/material";
import { useEffect } from "react";
import { currentActiveScreenState } from "../../libs/state-management-lib";

export const NotFoundPage = () => {
  useEffect(() => {
    currentActiveScreenState.setState("/pagenotfound");
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        <img src="/images/common/404_error.jpg" alt="404-Page not found" />
      </Box>
    </>
  );
};
